/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo, useState } from "react";
import {
  Divider,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import clsx from "clsx";
import useStyles from "./style";
import { formatToExponential, preventInputKeyCodes } from "utils";
import { noop } from "shared";

const Grid = ({
  columnConfig = [],
  data = [],
  isEmailSentToCustomer = false,
  isOverPayment = false,
  isPaymentAssociated = false,
  viewOnlyCreditMemo = false,
  selectedRows = {},
  status = false,
  invoiceAmount = 0,
  errors = {},
  prevLocationRef,
  onDeleteRow = noop,
  onDragEnd = noop,
  handleFieldChange = noop,
  handleSelectionChange = noop,
  onUpdateValues = noop,
}) => {
  const classes = useStyles();
  const tableData = data.sort((a, b) => {
    if (a.position === null) return 1;
    if (b.position === null) return -1;
    return a.position - b.position;
  });

  const total = useMemo(
    () =>
      tableData.reduce(
        (sum, row) => sum + parseFloat(row.credit_amount || 0),
        0
      ),
    [tableData]
  );

  const balanceCredit = useMemo(
    () => (total + parseFloat(invoiceAmount || 0)).toFixed(2),
    [total, invoiceAmount]
  );

  useEffect(() => {
    onUpdateValues(total, balanceCredit);
  }, [total, balanceCredit]);

  const isRowDisabled = isEmailSentToCustomer || status;

  const handleFieldChangeWrapper = (event, field, id) => {
    const { value, type } = event.target;
    handleFieldChange(event, field, id, { value, type });
  };

  const renderCell = (column, row) => {
    if (column.render) {
      return column.render(row, handleFieldChangeWrapper, {
        onDeleteRow,
        isDisabled: isRowDisabled,
        errors: errors[row.id] || {},
        isOverPayment,
        handleSelectionChange,
        selectedRows: selectedRows,
        isPaymentAssociated,
      });
    }

    return (
      <Typography variant="body2" noWrap>
        {row[column.id] || "-"}
      </Typography>
    );
  };

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.row}>
            <TableCell className={classes.dragHandle}></TableCell>
            {columnConfig.map((column) => (
              <TableCell
                key={column.id}
                className={clsx(classes.tableCell, column.headerClassName)}
              >
                <Typography variant="subtitle1">{column.label}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            isDropDisabled={
              isRowDisabled ||
              tableData.length === 1 ||
              isOverPayment ||
              viewOnlyCreditMemo
            }
            droppableId="table"
          >
            {(provided) => (
              <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                {tableData.map((row, index) => (
                  <Draggable
                    key={row.id}
                    draggableId={row?.id?.toString()}
                    index={index}
                    isDragDisabled={
                      isRowDisabled ||
                      tableData.length === 1 ||
                      isOverPayment ||
                      viewOnlyCreditMemo
                    }
                  >
                    {(provided, snapshot) => (
                      <TableRow
                        hover
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          ...provided.draggableProps.style,
                          top: "auto !important",
                          left: "auto !important",
                        }}
                        className={clsx(classes.row, {
                          [classes.draggingRow]: snapshot.isDragging,
                        })}
                      >
                        <TableCell
                          {...provided.dragHandleProps}
                          className={classes.dragHandle}
                          style={{
                            cursor:
                              !viewOnlyCreditMemo &&
                              !status &&
                              tableData.length > 1
                                ? "grab"
                                : "not-allowed",
                          }}
                        >
                          <DragHandleIcon
                            style={{
                              color:
                                isRowDisabled ||
                                tableData.length === 1 ||
                                isOverPayment ||
                                viewOnlyCreditMemo
                                  ? "#e0e0e0"
                                  : "inherit",
                            }}
                            color="primary"
                            className="ml-2"
                          />
                        </TableCell>
                        {columnConfig.map((column) => (
                          <TableCell
                            key={`${row.id}-${column.id}`}
                            className={clsx(
                              classes.tableCell,
                              column.headerClassName
                            )}
                          >
                            {renderCell(column, row)}
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>

      <div className={classes.totalAmount}>
        <div
          className="d-flex f-align-center f-justify-between"
          style={{ marginBottom: "8px" }}
        >
          <Typography variant="h6">Total</Typography>
          <Typography variant="h6" className="ml-2">
            {parseFloat(total).toFixed(2)}
          </Typography>
        </div>
        <div className="d-flex f-align-center f-justify-between mb-1">
          <div className="d-flex f-align-center f-justify-between">
            <Typography variant="body1">Invoices:</Typography>

            <TextField
              type="number"
              size="small"
              name="invoiceAmount"
              variant="outlined"
              value={invoiceAmount}
              disabled={
                isEmailSentToCustomer ||
                status ||
                isOverPayment ||
                viewOnlyCreditMemo ||
                isPaymentAssociated
              }
              onKeyDown={preventInputKeyCodes}
              onWheel={(event) => event.target.blur()}
              onChange={handleFieldChange}
              style={{ marginLeft: 48, width: 245 }}
              error={!!errors?.invoiceAmount?.trim()}
              helperText={errors.invoiceAmount}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </div>
          <Typography variant="body1" className="ml-2">
            {formatToExponential(invoiceAmount)}
          </Typography>
        </div>
        <Divider className="mt-4 mb-2" />
        <div className="d-flex f-align-center f-justify-between">
          <Typography variant="h6">Balance Credit ( $ )</Typography>
          <Typography
            variant="h6"
            color={parseFloat(balanceCredit) < 0 ? "error" : "inherit"}
          >
            {balanceCredit || 0}
          </Typography>
        </div>
      </div>
    </TableContainer>
  );
};

export default Grid;
