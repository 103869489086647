const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1024,
  },
  tableCell: {
    padding: "4px 12px",
    minWidth: 72,
  },
  dragHandle: {
    cursor: "move",
    padding: theme.spacing(1),
    width: 24,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.75rem",
  },
  container: {
    minHeight: 200,
    height: "calc(100vh - 340px)",
    overflow: "auto",
  },
  paperSize: {
    width: 316,
  },
  totalAmount: {
    background: "#80deea1c",
    padding: "16px",
    borderRadius: 8,
    width: "520px",
    boxSizing: "border-box",
    marginRight: "170px",
    marginTop: "20px",
    marginBottom: "20px",
    float: "right",
  },
  paperIframe: {
    minWidth: "600px !important",
    maxWidth: "1200px !important",
    minHeight: "600px !important",
    maxHeight: "1200px !important",
  },
  row: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  textField: {
    minWidth: 100,
  },
  draggingRow: {
    display: "table",
    minWidth: 120,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    "& td": {
      borderBottom: "none",
      backgroundColor: theme.palette.background.paper,
    },
  },
  paper: { width: "-webkit-fill-available", overflow: "auto" },
}));

export default useStyles;
