/* eslint-disable react-hooks/exhaustive-deps */

import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import useStyles from "./style";
import clsx from "clsx";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getDateString,
  getUTCDateString,
  LoadingOverlay,
  preventInputKeyCodes,
  validator,
} from "utils";
import {
  Dialog,
  ActionDialog,
  AppContext,
  Datepicker,
  VALIDATIONS,
  noop,
} from "shared";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import GridContainer from "../credit-memo-grid/gridContainer";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { toast } from "react-toastify";
import { SharedService } from "modules/shared";
import Service from "../../service";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

let address = null;

const defaultState = {
  entries: {},
  allBranchLocation: [],
  creditMemoDetails: {},
  creditItemsDetails: [],
  pdxCompanyList: [],
  deletDarftInvoice: null,
  isCreditMemoExist: false,
  isFinalizeLoading: false,
  pdxCompany: null,
  creditMemoDate: null,
  fromDate: null,
  toDate: null,
  isInvoiceDeleteLoading: false,
  isChangesPresent: false,
  isFetchingData: false,
  addBillTo: false,
  isBillToEmpty: false,
  isFetchingList: {
    customers: false,
    pdxCompany: false,
  },
  creditNo: null,
  branchAddress: "",
  customerNotes: "Thank you for your business.",
  isDataSaved: false,
  status: null,
  customer: null,
  anchorE2: false,
  isAddressListLoading: false,
  isPaymentAssociated: false,
  streetAddress: "",
  city: "",
  zipCode: "",
  state: "",
  country: "",
  primary_contact_name: "",
  invoiceAmt: 0,
  totalAmountValidate: false,
  sendCreditMemoEmail: false,
  isEmailSentToCustomer: false,
  errors: {
    customerNotes: " ",
    primary_contact_name: " ",
    streetAddress: " ",
    city: " ",
    zipCode: " ",
    state: " ",
    country: " ",
  },
  pdfUrl: "",
  pdfViewerDialog: false,
  location: null,
};

const CreditMemo = ({
  open = false,
  heading = null,
  isRowBeingEditing = false,
  creditMemoId = null,
  paymentDetails = null,
  isOverPayment = false,
  viewOnlyCreditMemo = false,
  creditDetails = null,
  onClose = noop,
  fetchNewdata = noop,
}) => {
  const { appData, updateContextData } = useContext(AppContext);
  const classes = useStyles({ isTabletView: appData.isTabletView });
  const isFieldChangedRef = useRef(false);
  const prevLocationRef = useRef(false);
  const [state, setState] = useState({
    ...defaultState,
    creditMemoDate: getDateString(new Date()),
    fromDate: getDateString(new Date()),
    toDate: getDateString(new Date()),
  });

  const columnConfig = [
    {
      id: "actions",
      editable: false,
      headerClassName: classes.actionsTableHeaderwidth,
      render: (
        row,
        handleFieldChange,
        {
          onDeleteRow,
          isDisabled,
          isOverPayment,
          handleSelectionChange,
          selectedRows,
          isPaymentAssociated,
        }
      ) => {
        return (
          <div className="d-flex f-align-center f-justify-between">
            <Tooltip title="Delete" placement="top-start">
              <DeleteForeverOutlinedIcon
                disabled={
                  isDisabled ||
                  isOverPayment ||
                  viewOnlyCreditMemo ||
                  isPaymentAssociated
                }
                color={
                  isDisabled ||
                  isOverPayment ||
                  viewOnlyCreditMemo ||
                  isPaymentAssociated
                    ? ""
                    : "error"
                }
                className={clsx("c-pointer mb-2", {
                  disabled:
                    isDisabled ||
                    isOverPayment ||
                    viewOnlyCreditMemo ||
                    isPaymentAssociated,
                })}
                onClick={() => onDeleteRow(row)}
              />
            </Tooltip>
            <Tooltip title="Select Route" placement="top-start">
              <Checkbox
                className="mb-2"
                checked={selectedRows[row.id] || false}
                onChange={() => handleSelectionChange(row.id)}
                disabled={
                  isDisabled ||
                  !state.location ||
                  isOverPayment ||
                  viewOnlyCreditMemo ||
                  isPaymentAssociated
                }
              />
            </Tooltip>
          </div>
        );
      },
    },
    {
      id: "credit_description",
      label: "Description",
      editable: true,
      render: (
        row,
        handleFieldChange,
        { isDisabled, errors, selectedRows, isOverPayment, isPaymentAssociated }
      ) => {
        return (
          <div className="d-flex f-align-center f-justify-between">
            {selectedRows[row.id] && (
              <Autocomplete
                options={routeList}
                className="mr-2"
                disableClearable={true}
                getOptionLabel={(option) => option.name || option}
                style={{ width: "50%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Route"
                  />
                )}
                value={row.route || row.route_name || null}
                onChange={(event, newValue) => {
                  handleFieldChange(
                    { target: { value: newValue } },
                    "route",
                    row.id
                  );
                }}
                disabled={
                  isDisabled ||
                  isOverPayment ||
                  viewOnlyCreditMemo ||
                  isPaymentAssociated
                }
              />
            )}
            <Tooltip title={row.credit_description ?? ""} placement="top-start">
              <TextField
                value={row.credit_description || ""}
                onChange={(e) => {
                  handleFieldChange(e, "credit_description", row.id);
                }}
                variant="outlined"
                size="small"
                fullWidth
                placeholder="Add Description *"
                disabled={isDisabled || viewOnlyCreditMemo}
                error={!!errors?.credit_description?.trim()}
                helperText={errors?.credit_description}
                inputProps={{
                  style: {
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  },
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
    {
      id: "credit_quantity",
      label: "Qty",
      editable: true,
      headerClassName: classes.tableHeaderwidth,
      render: (
        row,
        handleFieldChange,
        { isDisabled, errors, isOverPayment, isPaymentAssociated }
      ) => {
        return (
          <Tooltip title={row.credit_quantity} placement="top-start">
            <TextField
              value={row.credit_quantity}
              onChange={(e) => handleFieldChange(e, "credit_quantity", row.id)}
              variant="outlined"
              size="small"
              type="number"
              disabled={
                isDisabled ||
                isOverPayment ||
                viewOnlyCreditMemo ||
                isPaymentAssociated
              }
              error={!!errors?.credit_quantity?.trim()}
              helperText={errors?.credit_quantity}
            />
          </Tooltip>
        );
      },
    },
    {
      id: "credit_rate",
      label: "Rate",
      editable: true,
      headerClassName: classes.tableHeaderwidth,
      render: (
        row,
        handleFieldChange,
        { isDisabled, errors, isOverPayment, isPaymentAssociated }
      ) => {
        return (
          <Tooltip title={row.credit_rate} placement="top-start">
            <TextField
              value={row.credit_rate}
              onChange={(e) => handleFieldChange(e, "credit_rate", row.id)}
              variant="outlined"
              size="small"
              type="number"
              disabled={
                isDisabled ||
                isOverPayment ||
                viewOnlyCreditMemo ||
                isPaymentAssociated
              }
              onKeyDown={preventInputKeyCodes}
              error={!!errors?.credit_rate?.trim()}
              helperText={errors?.credit_rate}
            />
          </Tooltip>
        );
      },
    },
    {
      id: "credit_amount",
      label: "Amount",
      editable: false,
      render: (row) => {
        return (
          <Tooltip title={row.credit_amount || 0} placement="top-start">
            <Typography variant="body1" noWrap className="text-bold">
              {row.credit_amount || 0}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  const appDataList = useMemo(() => {
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    return {
      customers,
    };
  }, [appData]);

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList:
        data?.rows.filter((pdxCompany) => pdxCompany.is_active) ||
        defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };

  useEffect(() => {
    if (Object.keys(state.creditMemoDetails).length > 0) {
      setState((prevState) => ({
        ...prevState,
        fromDate:
          getUTCDateString(state.creditMemoDetails.from_date) ||
          defaultState.fromDate,
        toDate:
          getUTCDateString(state.creditMemoDetails.to_date) ||
          defaultState.toDate,
        pdxCompany:
          state.creditMemoDetails.customer_branch.pdxCompany ||
          defaultState.pdxCompany,
        customer:
          state.creditMemoDetails?.customer_branch.customer ||
          defaultState.customer,
        location: {
          location:
            state.creditMemoDetails?.customer_branch.location ||
            defaultState.location,
          id: state.creditMemoDetails?.customer_branch.id,
        },
        creditMemoDate:
          getUTCDateString(state.creditMemoDetails.credit_memo_date) ||
          defaultState.creditMemoDate,
        creditNo: state.creditMemoDetails.credit_no || defaultState.creditNo,
        status: state.creditMemoDetails.status || defaultState.creditNo,
        isEmailSentToCustomer:
          state.creditMemoDetails.is_sent_to_customer ||
          defaultState.isEmailSentToCustomer,
        customerNotes:
          state.creditMemoDetails.customer_notes || defaultState.customerNotes,
        streetAddress:
          state.creditMemoDetails.bill_to.street_address ||
          defaultState.streetAddress,
        city: state.creditMemoDetails.bill_to.city || defaultState.city,
        zipCode:
          state.creditMemoDetails.bill_to.zip_code || defaultState.zipCode,
        state: state.creditMemoDetails.bill_to.state || defaultState.state,
        country:
          state.creditMemoDetails.bill_to.country || defaultState.country,
        primary_contact_name:
          state.creditMemoDetails.bill_to.primary_contact ||
          defaultState.primary_contact_name,
        branchAddress: [
          state.creditMemoDetails.bill_to.primary_contact ||
            defaultState.primary_contact_name,
          state.creditMemoDetails.bill_to.street_address ||
            defaultState.streetAddress,
          state.creditMemoDetails.bill_to.city || defaultState.city,
          state.creditMemoDetails.bill_to.state || defaultState.state,
          state.creditMemoDetails.bill_to.zip_code || defaultState.zipCode,
          state.creditMemoDetails.bill_to.country || defaultState.country,
        ]
          .filter((detail) => detail)
          .join(",\n"),
      }));
    }
  }, [state.creditMemoDetails]);

  useEffect(() => {
    if (isOverPayment && paymentDetails) {
      isFieldChangedRef.current = true;
      setState((prevState) => ({
        ...prevState,
        creditMemoDetails: paymentDetails,
        creditItemsDetails: paymentDetails?.CreditMemoItemRecords,
        isCreditMemoExist: true,
      }));
    }
  }, []);

  useEffect(() => {
    if (viewOnlyCreditMemo && creditDetails) {
      isFieldChangedRef.current = false;
      setState((prevState) => ({
        ...prevState,
        creditMemoDetails: creditDetails,
        creditItemsDetails: creditDetails?.CreditMemoItemRecords,
        isCreditMemoExist: true,
      }));
    }
  }, []);

  const handleCreditMemo = async (details, isDeleting = false) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingData: true,
    }));

    let newEntries = [];

    if (isDeleting) {
      details.creditMemoItemRecords = details.creditMemoItemRecords.filter(
        (item) => {
          if (item.isNew === true) {
            newEntries.push({ ...item });
            return false;
          }
          return true;
        }
      );
    } else {
      for (const item of details.creditMemoItemRecords) {
        if (item.isNew === true) {
          delete item.id;
          delete item.isNew;
        }
      }
    }

    const billTo = {
      street_address: state.streetAddress,
      city: state.city,
      zip_code: state.zipCode,
      customerName: state.customer.name,
      branch: state.location.location,
      state: state.state,
      country: state.country,
      primary_contact: state.primary_contact_name,
    };

    const payload = {
      credit_memo_date: state.creditMemoDate,
      customer_branch_id: state.location.id,
      from_date: state.fromDate,
      to_date: state.toDate,
      bill_to: billTo,
      customer_notes: state.customerNotes,
      ...details,
      ...(state.isCreditMemoExist ? { id: state.creditMemoDetails?.id } : {}),
    };

    const serviceMethod = state.isCreditMemoExist
      ? "updateCreditMemo"
      : "postCreditMemo";

    const { data, error } = await Service[serviceMethod](payload);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingData: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    if (newEntries.length === 0) {
      isFieldChangedRef.current = false;
    }
    fetchNewdata(true);
    if (isOverPayment) {
      handleFinalizeCreditMemo(data?.creditMemoDetails.id);
    } else {
      if (isDeleting) {
        toast.success("Deleted successfully.");
      } else {
        toast.success("Credit Memo saved successfully.");
      }
    }
    const updatedCreditItemsDetails = isDeleting
      ? [
          ...(data?.creditMemoDetails?.CreditMemoItemRecords || []),
          ...newEntries,
        ]
      : data?.creditMemoDetails?.CreditMemoItemRecords ||
        defaultState.creditItemsDetails;

    setState((prevState) => ({
      ...prevState,
      isFetchingData: false,
      isCreditMemoExist: true,
      creditItemsDetails: updatedCreditItemsDetails,
      creditMemoDetails:
        data?.creditMemoDetails || defaultState.creditMemoDetails,
    }));
  };

  const handleFinalizeCreditMemo = async (id) => {
    setState((prevState) => ({
      ...prevState,
      isFinalizeLoading: true,
    }));

    const { data, error } = await Service.finalizeCreditMemo(id);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFinalizeLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    toast.success("Credit Memo finalized successfully.");

    setState((prevState) => ({
      ...prevState,
      isFinalizeLoading: false,
      isCreditMemoExist: true,
      creditItemsDetails: data?.creditMemoDetails?.CreditMemoItemRecords,
      creditMemoDetails:
        data?.creditMemoDetails || defaultState.creditMemoDetails,
    }));
    fetchNewdata(true);
  };

  const getCreditMemo = async (id) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingData: true,
    }));

    const query = `?filter[where][id]=${id}`;

    const { data, error } = await Service.getCreditMemo(query);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingData: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingData: false,
      isCreditMemoExist: true,
      creditItemsDetails:
        data.rows[0].CreditMemoItemRecords || defaultState.creditItemsDetails,
      creditMemoDetails: data.rows[0] || defaultState.creditMemoDetails,
      isPaymentAssociated:
        data.rows[0].payment_id || defaultState.isPaymentAssociated,
    }));
  };
  const deleteCreditMemo = async (id) => {
    setState((prevState) => ({
      ...prevState,
      isDeleting: true,
    }));

    const query = `?creditMemoIds=${id}`;

    const { error } = await Service.deleteCreditMemo(query);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isDeleting: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Deleted successfully.");
    if (isRowBeingEditing) {
      fetchNewdata(true, true);
    }
    setState((prevState) => ({
      ...prevState,
      isDeleting: false,
    }));
    onClose();
  };

  const fetchCustomerList = async () => {
    setState((prevState) => ({
      ...prevState,
      isIcFetching: true,
      isFetchingList: {
        ...prevState.isFetchingList,
        customers: !appData.customers?.length,
      },
    }));

    try {
      const { data, error } = await SharedService.getCustomerList();

      if (error) {
        throw new Error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }

      const customerList = data.customers || [];

      customerList.forEach((item = {}) => {
        (item.customer_branches || []).forEach((branch = {}) => {
          (branch.routes || []).sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });

      updateContextData("customers", customerList);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setState((prevState) => ({
        ...prevState,
        isIcFetching: false,
        isFetchingList: {
          ...prevState.isFetchingList,
          customers: false,
        },
      }));
    }
  };

  useEffect(() => {
    fetchPDXCompanyList();
    fetchCustomerList();
  }, []);

  const customerList = useMemo(() => {
    return appDataList.customers.filter((customer) =>
      customer.customer_branches.some(
        (branch) =>
          branch.pdx_company_id === state.pdxCompany?.id &&
          branch.is_active &&
          !branch.is_deleted
      )
    );
  }, [state.pdxCompany]);

  const branchList = useMemo(() => {
    const customer = customerList.find(
      (customer) => customer.id === state.customer?.id
    );
    if (!customer) return [];

    const branchMap = new Map();

    customer.customer_branches.forEach((branch) => {
      if (
        branch.pdx_company_id === state.pdxCompany?.id &&
        branch.is_active &&
        !branch.is_deleted
      ) {
        const location = (branch.location || "").toLowerCase();
        if (!branchMap.has(location)) {
          branchMap.set(location, branch);
        }
      }
    });

    const uniqueBranches = Array.from(branchMap.values());

    uniqueBranches.sort((a, b) => {
      const valueA = (a.location || "").toLowerCase();
      const valueB = (b.location || "").toLowerCase();
      return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
    });

    return uniqueBranches;
  }, [state.customer, state.pdxCompany]);

  const routeList = useMemo(() => {
    const routes =
      branchList.find((location) => location.id === state.location?.id)
        ?.routes || [];

    const activeRoutes = routes?.filter((route) => route.is_active);
    const inactiveRoutes = routes?.filter((route) => !route.is_active);

    const sortedActiveRoutes = activeRoutes.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
    );
    const sortedInactiveRoutes = inactiveRoutes.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
    );

    return [...sortedActiveRoutes, ...sortedInactiveRoutes];
  }, [state.location]);

  const getAllLocations = async (id) => {
    setState((prevState) => ({
      ...prevState,
      isAddressListLoading: true,
    }));
    const { data, error } = await Service.getCustomerLocations();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isAddressListLoading: false,
      }));
    }

    const branchAddresses = data?.locations.customer_branches?.flatMap(
      (location) => {
        if (!location?.addresses) return [];
        return JSON.parse(location.addresses).map((address) => ({
          ...address,
          customer_branch_id: location?.customer_branch_id,
        }));
      }
    );

    setState((prevState) => ({
      ...prevState,
      allBranchLocation: branchAddresses || defaultState.allBranchLocation,
      isAddressListLoading: false,
    }));
  };

  useEffect(() => {
    getAllLocations();
    if (isRowBeingEditing) {
      getCreditMemo(creditMemoId);
    }
  }, []);

  useEffect(() => {
    address = state.isCreditMemoExist
      ? state.creditMemoDetails?.bill_to
      : state.allBranchLocation.length > 0 &&
        state.allBranchLocation.find(
          (ele) => ele.customer_branch_id === state.location?.id
        )?.branchAddress;

    setState((prevState) => ({
      ...prevState,
      streetAddress: address?.street_address || defaultState.streetAddress,
      city: address?.city || defaultState.city,
      zipCode: address?.zip_code || defaultState.zipCode,
      state: address?.state || defaultState.state,
      country: address?.country || defaultState.country,
      primary_contact_name:
        address?.primary_contact || defaultState.primary_contact_name,
      branchAddress:
        [
          address?.primary_contact,
          address?.street_address,
          address?.city,
          address?.state,
          address?.zip_code,
          address?.country,
        ]
          .filter((detail) => detail)
          .join(",\n") || defaultState.branchAddress,
    }));
  }, [
    state.location,
    isRowBeingEditing,
    state.allBranchLocation,
    state.creditMemoDetails,
    state.isCreditMemoExist,
  ]);

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      primary_contact_name: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      streetAddress: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      city: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      customerNotes: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      zipCode: [{ type: VALIDATIONS.MAX_LENGTH, value: 10 }],
      state: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      country: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    isFieldChangedRef.current = true;
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";
    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleAddAddress = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleClose = () => {
    if (
      state.creditMemoDetails.status !== "pending-approval" &&
      state.creditMemoDetails.status !== "approved" &&
      isFieldChangedRef.current
    ) {
      setState((prevState) => ({
        ...prevState,
        isChangesPresent: true,
      }));
    } else {
      isFieldChangedRef.current = false;
      setState(defaultState);
      onClose();
    }
  };

  const handleConfirmClose = () => {
    isFieldChangedRef.current = false;
    setState(defaultState);
    onClose();
  };

  const handleCancelClose = () => {
    setState((prevState) => ({
      ...prevState,
      isChangesPresent: false,
    }));
  };

  const determineCreditMemoAction = useCallback(() => {
    if (!state.isCreditMemoExist) return "Create";
    if (
      state.creditMemoDetails.status === "pending-approval" ||
      state.creditMemoDetails.status === "approved"
    )
      return "View";
    return "Update";
  }, [state.isCreditMemoExist, state.creditMemoDetails]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.dialogPaper }}
        disableBackdropClick={true}
      >
        <Dialog.Title hasClose>
          {`${heading || determineCreditMemoAction()} Credit Memo `}
          {viewOnlyCreditMemo && (
            <span>
              ( <strong>STATUS</strong> -{" "}
              {state.creditMemoDetails.status === "pending-approval" && (
                <span style={{ fontWeight: "bold", color: "red" }}>
                  PENDING APPROVAL
                </span>
              )}
              {state.creditMemoDetails.status === "approved" && (
                <span style={{ fontWeight: "bold", color: "#004700" }}>
                  APPROVED
                </span>
              )}
              {state.creditMemoDetails.status === "draft" && (
                <span style={{ fontWeight: "bold", color: "red" }}>DRAFT</span>
              )}{" "}
              )
            </span>
          )}
          {isOverPayment && (
            <span style={{ fontWeight: "bold", color: "red" }}>
              ( Please finalize the credit memo first, then approve it to add it
              to the customer credit. )
            </span>
          )}
        </Dialog.Title>
        <Dialog.Content>
          {(state.isFetchingData ||
            state.isFinalizeLoading ||
            state.isFetchingList.pdxCompany) &&
            !viewOnlyCreditMemo && (
              <LoadingOverlay message="Processing, please wait..." />
            )}
          <div
            className={clsx(
              (state.isFetchingData ||
                state.isFinalizeLoading ||
                state.isFetchingList.pdxCompany) &&
                !viewOnlyCreditMemo &&
                classes.blur
            )}
          >
            <div className="ml-1 d-flex f-align-center f-justify-between f-wrap">
              <div className="d-flex f-align-center f-wrap">
                <Datepicker
                  mask
                  preventClear
                  label="From Date"
                  value={state.fromDate}
                  disabled={state.isCreditMemoExist || viewOnlyCreditMemo}
                  maxDate={new Date(state.toDate)}
                  classes={{
                    input: {
                      root: clsx("mr-3", classes.datepickerWrapper),
                    },
                  }}
                  onChange={(date) => {
                    isFieldChangedRef.current = true;
                    setState((prevState) => ({
                      ...prevState,
                      fromDate: getDateString(date),
                    }));
                  }}
                />
                <Datepicker
                  mask
                  preventClear
                  label="To Date"
                  value={state.toDate}
                  disabled={state.isCreditMemoExist || viewOnlyCreditMemo}
                  minDate={new Date(state.fromDate)}
                  maxDate={new Date()}
                  classes={{
                    input: {
                      root: clsx("", classes.datepickerWrapper),
                    },
                  }}
                  onChange={(date) => {
                    isFieldChangedRef.current = true;
                    setState((prevState) => ({
                      ...prevState,
                      toDate: getDateString(date),
                    }));
                  }}
                />
                <Autocomplete
                  disableClearable
                  fullWidth
                  className={classes.filterFields}
                  size="small"
                  disabled={
                    state.isCreditMemoExist ||
                    isOverPayment ||
                    viewOnlyCreditMemo
                  }
                  value={state.pdxCompany}
                  options={state.pdxCompanyList}
                  getOptionLabel={(option) => option.value || option}
                  renderInput={(params) =>
                    state.isFetchingList.pdxCompany ? (
                      <Skeleton variant="rect" width="100%" height="42px" />
                    ) : (
                      <TextField
                        {...params}
                        required
                        label="PDX Company"
                        variant="outlined"
                      />
                    )
                  }
                  getOptionSelected={(option, value) =>
                    `${option.value || option}` === value
                  }
                  onChange={(evt, value) => {
                    prevLocationRef.current = true;
                    setState((prevState) => ({
                      ...prevState,
                      pdxCompany: value,
                      location: defaultState.location,
                      customer: defaultState.customer,
                    }));
                  }}
                />
                <Autocomplete
                  className={classes.filterFields}
                  size="small"
                  value={state.customer}
                  disabled={
                    !state.pdxCompany ||
                    state.isCreditMemoExist ||
                    isOverPayment ||
                    viewOnlyCreditMemo
                  }
                  options={customerList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Customer"
                      variant="outlined"
                    />
                  )}
                  onChange={(evt, value) => {
                    prevLocationRef.current = true;
                    setState((prevState) => ({
                      ...prevState,
                      customer: value,
                      location: null,
                    }));
                  }}
                />
                <Autocomplete
                  disableClearable
                  className={classes.filterFields}
                  disabled={
                    !state.customer ||
                    state.isCreditMemoExist ||
                    isOverPayment ||
                    viewOnlyCreditMemo
                  }
                  size="small"
                  value={state.location}
                  options={branchList}
                  getOptionLabel={(option) => option.location || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Location"
                      variant="outlined"
                    />
                  )}
                  onChange={(evt, value) => {
                    isFieldChangedRef.current = true;
                    prevLocationRef.current = true;
                    setState((prevState) => ({
                      ...prevState,
                      location: value,
                    }));
                  }}
                />
              </div>
              <div className={classes.creditNo}>
                <Datepicker
                  mask
                  preventClear
                  label="Date"
                  disabled={state.isCreditMemoExist || viewOnlyCreditMemo}
                  value={state.creditMemoDate}
                  maxDate={new Date()}
                  classes={{
                    input: {
                      root: clsx("mr-1", classes.datepickerWrapper),
                    },
                  }}
                  onChange={(date) => {
                    isFieldChangedRef.current = true;
                    setState((prevState) => ({
                      ...prevState,
                      creditMemoDate: getDateString(date),
                    }));
                  }}
                />
                <TextField
                  className="w-50"
                  disabled
                  label="Credit No."
                  variant="outlined"
                  size="small"
                  value={state.creditNo || ""}
                />
              </div>
            </div>
            <div
              className={clsx(
                "d-flex  mt-5",
                appData.isTabletView && classes.content
              )}
            >
              <div
                className={clsx(
                  "p-4 mr-3",
                  appData.isTabletView && classes.tabletView
                )}
              >
                <div className={clsx("d-flex flex-column", classes.paper)}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={state.isBillToEmpty && !state.branchAddress}
                    className={classes.outlinedInputError}
                  >
                    <InputLabel htmlFor="bill-to">Address*</InputLabel>
                    <OutlinedInput
                      id="bill-to"
                      label="Address"
                      variant="outlined"
                      size="small"
                      disabled
                      required
                      value={`${
                        state.location
                          ? `${state.customer.name}, ${state.location.location}, ${state.branchAddress}`
                          : ""
                      }`}
                      style={{ color: "black" }}
                      minRows={6}
                      maxRows={8}
                      multiline
                      endAdornment={
                        <InputAdornment
                          position="end"
                          style={{ marginTop: "90px" }}
                        >
                          <Tooltip
                            title={
                              !!state.branchAddress
                                ? "Edit Address"
                                : "Add Address"
                            }
                            placement="top-start"
                          >
                            <IconButton
                              disabled={
                                !state.location ||
                                state.isEmailSentToCustomer ||
                                state.creditMemoDetails.status ===
                                  "pending-approval" ||
                                state.creditMemoDetails.status === "approved" ||
                                viewOnlyCreditMemo
                              }
                              color="primary"
                              onClick={() =>
                                setState((prevState) => ({
                                  ...prevState,
                                  addBillTo: true,
                                }))
                              }
                            >
                              {!!state.branchAddress ? (
                                state.isBillLoading ? (
                                  <CircularProgress
                                    size={24}
                                    className="p-absolute progress-btn"
                                  />
                                ) : (
                                  <EditIcon fontSize="medium" />
                                )
                              ) : (
                                <AddCircleIcon fontSize="large" />
                              )}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      }
                    />
                    {state.isBillToEmpty && !state.branchAddress && (
                      <FormHelperText error>
                        Address is Required...
                      </FormHelperText>
                    )}
                  </FormControl>
                  <TextField
                    name="customerNotes"
                    label="Customer Note"
                    variant="outlined"
                    size="small"
                    value={state.customerNotes}
                    className={classes.customerNotes}
                    disabled={
                      state.isEmailSentToCustomer ||
                      state.creditMemoDetails.status === "pending-approval" ||
                      state.creditMemoDetails.status === "approved" ||
                      viewOnlyCreditMemo
                    }
                    minRows={2}
                    maxRows={4}
                    multiline
                    error={!!state.errors.customerNotes?.trim()}
                    helperText={state.errors.customerNotes}
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
              <GridContainer
                columnConfig={columnConfig}
                rows={state.creditItemsDetails}
                isRowBeingEditing={state.creditMemoDetails}
                isOverPayment={isOverPayment}
                isPaymentAssociated={state.isPaymentAssociated}
                routeList={routeList}
                handleCreditMemo={handleCreditMemo}
                handleFinalizeCreditMemo={handleFinalizeCreditMemo}
                deleteCreditMemo={deleteCreditMemo}
                viewOnlyCreditMemo={viewOnlyCreditMemo}
                isDeleting={state.isDeleting}
                isFinalizeLoading={state.isFinalizeLoading}
                isFieldChangedRef={isFieldChangedRef}
                creditNo={state.creditNo}
                status={state.status}
                creditDate={state.creditMemoDate}
                customerNotes={state.customerNotes}
                pdxCompany={state.pdxCompany?.value || state.pdxCompany}
                customerName={state.customer?.name}
                branch={state.location?.location}
                primary_contact={state.primary_contact_name}
                streetAddress={state.streetAddress}
                stateName={state.state}
                city={state.city}
                zipCode={state.zipCode}
                country={state.country}
                fromDate={state.fromDate}
                toDate={state.toDate}
                isLoading={state.isFetchingData}
                isCreditMemoExist={state.isCreditMemoExist}
                branchAddress={state.branchAddress}
                propSetState={setState}
                prevLocationRef={prevLocationRef}
              />
            </div>
          </div>
        </Dialog.Content>
      </Dialog>
      {state.addBillTo && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.addBillTo}
          contentText={
            <div>
              <Typography variant="h5" color="primary" className=" ml-2">
                {!!state.branchAddress ? "Edit Address" : "Add Address"}
              </Typography>

              <br />

              <div className={classes.root}>
                <div className="d-flex f-align-center">
                  <TextField
                    className={classes.contact}
                    name="primary_contact_name"
                    label="Primary Contact Name"
                    variant="outlined"
                    size="small"
                    value={state.primary_contact_name}
                    error={!!state.errors.primary_contact_name?.trim()}
                    helperText={state.errors.primary_contact_name}
                    onChange={handleAddAddress}
                  />
                </div>
                <div className="d-flex f-align-center">
                  <TextField
                    required
                    className={classes.StreetAddress}
                    size="small"
                    variant="outlined"
                    name="streetAddress"
                    label="Street Address"
                    value={state.streetAddress}
                    error={!!state.errors.streetAddress?.trim()}
                    helperText={state.errors.streetAddress}
                    onChange={handleAddAddress}
                  />
                </div>
                <div className="d-flex f-align-center">
                  <TextField
                    required
                    className={classes.city}
                    size="small"
                    variant="outlined"
                    label="City"
                    name="city"
                    value={state.city}
                    error={!!state.errors.city?.trim()}
                    helperText={state.errors.city}
                    onChange={handleAddAddress}
                  />

                  <TextField
                    required
                    className={classes.state}
                    size="small"
                    variant="outlined"
                    label="State"
                    name="state"
                    value={state.state}
                    error={!!state.errors.state?.trim()}
                    helperText={state.errors.state}
                    onChange={handleAddAddress}
                  />
                </div>
                <div className="d-flex f-align-center">
                  <TextField
                    required
                    className={classes.zip}
                    size="small"
                    variant="outlined"
                    label="ZIP Code"
                    name="zipCode"
                    value={state.zipCode}
                    error={!!state.errors.zipCode?.trim()}
                    helperText={state.errors.zipCode}
                    onChange={handleAddAddress}
                  />
                  <TextField
                    required
                    className={classes.country}
                    name="country"
                    size="small"
                    variant="outlined"
                    label="Country"
                    value={state.country}
                    error={!!state.errors.country?.trim()}
                    helperText={state.errors.country}
                    onChange={handleAddAddress}
                  />
                </div>
              </div>
            </div>
          }
          onConfirm={() => {
            const addressMatches =
              (address?.street_address || "") === (state.streetAddress || "") &&
              (address?.city || "") === (state.city || "") &&
              (address?.zip_code || "") === (state.zipCode || "") &&
              (address?.state || "") === (state.state || "") &&
              (address?.country || "") === (state.country || "") &&
              (address?.primary_contact || "") ===
                (state.primary_contact_name || "");

            if (!addressMatches) {
              isFieldChangedRef.current = true;
            }
            setState((prevState) => ({
              ...prevState,
              addBillTo: false,
              branchAddress:
                [
                  state.primary_contact_name,
                  state.streetAddress,
                  state.city,
                  state.state,
                  state.zipCode,
                  state.country,
                ]
                  .filter((detail) => detail)
                  .join(",\n") || defaultState.branchAddress,
            }));
          }}
          onCancel={() => {
            if (!!address) {
              setState((prevState) => ({
                ...prevState,
                addBillTo: false,
                streetAddress: address.street_address,
                city: address.city,
                zipCode: address.zip_code,
                state: address.state,
                country: address.country,
                primary_contact_name: address.primary_contact,
                branchAddress: [
                  address.primary_contact,
                  address.street_address,
                  address.city,
                  address.state,
                  address.zip_code,
                  address.country,
                ]
                  .filter((detail) => detail)
                  .join(",\n"),
              }));
            } else {
              setState((prevState) => ({
                ...prevState,
                addBillTo: false,
                state: defaultState.state,
                streetAddress: defaultState.streetAddress,
                country: defaultState.country,
                city: defaultState.city,
                zipCode: defaultState.zipCode,
                primary_contact_name: defaultState.primary_contact_name,
                branchAddress: defaultState.branchAddress,
              }));
            }
          }}
          positiveActionLabel="Save"
          negativeActionLabel="Cancel"
          isConfirmDisabled={
            validate() ||
            !state.streetAddress ||
            !state.city ||
            !state.state ||
            !state.zipCode ||
            !state.country
          }
        />
      )}
      {state.isChangesPresent && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSize,
          }}
          open={state.isChangesPresent}
          contentText="Are you sure you want to close it? There are unsaved changes present."
          onConfirm={handleConfirmClose}
          onCancel={handleCancelClose}
          positiveActionLabel="OK"
          negativeActionLabel="Cancel"
        />
      )}
    </>
  );
};

export default CreditMemo;
