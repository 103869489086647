/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import useStyles from "./style";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { AppContext, Dialog, VALIDATIONS, noop } from "shared";
import { preventInputKeyCodes, validator } from "utils";
import { toast } from "react-toastify";
import { SharedService } from "modules/shared";
import Service from "modules/invoice/service";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const defaultState = {
  isLoading: false,
  customer: null,
  location: null,
  isPreviouslyUnbilled: false,
  isCredit: false,
  route: null,
  billRate: null,
  billQuantity: null,
  billFuelSurcharge: null,
  billRatePerExtraMile: null,
  billExtraMiles: null,
  tollAmount: null,
  IC: null,
  notes: "",
  errors: {
    customer: " ",
    location: " ",
    route: " ",
    IC: " ",
    notes: " ",
    billRate: " ",
    billQuantity: " ",
    billFuelSurcharge: " ",
    billRatePerExtraMile: " ",
    billExtraMiles: " ",
    tollAmount: " ",
  },
  isFormChanged: false,
  isFetchingDriverList: false,
  representativeDriverList: [],
  representativeDriver: null,
};

const AddInvoiceItemDialog = ({
  open = false,
  isEditing = false,
  handleClose = noop,
  routeName,
  location,
  customer,
  pdxCompany,
  invoiceId,
  invoiceItemId,
  invoiceRowBeingEdited,
  handleGetInvoice,
  allRoutes,
  selectedRoutes = [],
  invoiceDetails,
}) => {
  const classes = useStyles();
  const { appData, updateContextData } = useContext(AppContext);
  const [state, setState] = useState(defaultState);

  const appDataList = useMemo(() => {
    const icList = appData.icList
      .filter((ic) => ic.is_active)
      .sort((a, b) => a.first_name.localeCompare(b.first_name));

    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    return {
      icList,
      customers,
    };
  }, [appData]);

  const fetchList = async (listType = "icList", ...params) => {
    setState((prevState) => ({
      ...prevState,
      isIcFetching: true,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "icList":
        serviceMethod = "getIcList";
        responseKey = "contractors";
        break;
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      default:
        serviceMethod = null;
        responseKey = null;
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isIcFetching: false,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];

    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isIcFetching: false,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  const fetchDriverList = async (id) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingDriverList: true,
    }));
    let queryString = `?filter[where][and][0][or][0][ic_id]=${id}&filter[where][is_deleted]=false`;

    const { data, error } = await Service.getDriverList(queryString);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingDriverList: defaultState.isFetchingDriverList,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      representativeDriverList:
        data.rows || defaultState.representativeDriverList,
      isFetchingDriverList: defaultState.isFetchingDriverList,
    }));
  };

  useEffect(() => {
    if (state?.IC?.contractorTypeValue === "WCP Master Contractor") {
      fetchDriverList(state.IC?.id);
    }
  }, [state.IC]);

  const branchList = useMemo(() => {
    if (!appDataList?.customers) return [];

    const branchMap = new Map();

    appDataList?.customers &&
      appDataList?.customers
        ?.find((item) =>
          item.customer_branches.some(
            (branch) => branch.id === invoiceDetails?.customer_branch_id
          )
        )
        ?.customer_branches.forEach((branch) => {
          if (
            branch.pdx_company_id === pdxCompany?.id &&
            branch.is_active &&
            !branch.is_deleted
          ) {
            const location = (branch.location || "").toLowerCase();
            if (!branchMap.has(location)) {
              branchMap.set(location, branch);
            }
          }
        });

    const uniqueBranches = Array.from(branchMap.values());

    uniqueBranches.sort((a, b) => {
      const valueA = (a.location || "").toLowerCase();
      const valueB = (b.location || "").toLowerCase();
      return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
    });

    return uniqueBranches;
  }, [appDataList?.customers, pdxCompany]);

  const routeList = useMemo(() => {
    const routes =
      branchList.find((location) => location.id === state.location?.id)
        ?.routes || [];

    const activeRoutes = routes?.filter((route) => route.is_active);
    const inactiveRoutes = routes?.filter((route) => !route.is_active);

    const sortedActiveRoutes = activeRoutes.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
    );
    const sortedInactiveRoutes = inactiveRoutes.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
    );

    return [...sortedActiveRoutes, ...sortedInactiveRoutes];
  }, [appDataList, state.location]);

  useEffect(() => {
    if (open && !isEditing) {
      setState((prevState) => ({
        ...prevState,
        customer: customer || defaultState.customer,
        route:
          (selectedRoutes.length === 1 && selectedRoutes[0]) ||
          defaultState.route,
        location: location || defaultState.location,
      }));
    }

    if (isEditing && invoiceRowBeingEdited?.length) {
      setState((prevState) => ({
        ...prevState,
        customer: customer || defaultState.customer,
        location: location || defaultState.location,
        route: invoiceRowBeingEdited[0] || defaultState.route,
        billRate: invoiceRowBeingEdited[0].bill_rate || defaultState.billRate,
        notes: invoiceRowBeingEdited[0].unbilled_notes || defaultState.notes,
        isPreviouslyUnbilled:
          invoiceRowBeingEdited[0].is_previously_unbilled ||
          defaultState.isPreviouslyUnbilled,
        isCredit:
          invoiceRowBeingEdited[0].credit_over_billed || defaultState.isCredit,
        billQuantity:
          invoiceRowBeingEdited[0].bill_quantity || defaultState.billQuantity,
        billFuelSurcharge:
          invoiceRowBeingEdited[0].bill_fuel_surcharge ||
          defaultState.billFuelSurcharge,
        billRatePerExtraMile:
          invoiceRowBeingEdited[0].bill_rate_per_extra_mile ||
          defaultState.billRatePerExtraMile,
        billExtraMiles:
          invoiceRowBeingEdited[0].bill_extra_miles ||
          defaultState.billExtraMiles,
        tollAmount:
          invoiceRowBeingEdited[0].toll_amount || defaultState.tollAmount,
        IC: invoiceRowBeingEdited[0] || defaultState.IC,
      }));
    }
  }, [customer, routeName, location, open, isEditing]);

  useEffect(() => {
    if (open) {
      fetchList("icList");
      fetchList("customers");
    }
  }, [open]);

  const onClose = () => {
    handleClose(false);
    setState(defaultState);
  };

  const handleSubmit = async () => {
    const {
      route,
      IC,
      billRate,
      billQuantity,
      billFuelSurcharge,
      billRatePerExtraMile,
      billExtraMiles,
      tollAmount,
      notes,
      isPreviouslyUnbilled,
      isCredit,
      representativeDriver,
    } = state;

    setState((prevState) => ({ ...prevState, isLoading: true }));

    const payload = {
      bill_rate: +billRate,
      bill_quantity: +billQuantity,
      bill_fuel_surcharge: +billFuelSurcharge,
      bill_rate_per_extra_mile: +billRatePerExtraMile,
      bill_extra_miles: +billExtraMiles,
      toll_amount: +tollAmount,
      is_previously_unbilled: isPreviouslyUnbilled,
      credit_over_billed: isCredit,
      unbilled_notes: notes,
    };

    const original = invoiceRowBeingEdited[0] || {};
    const updatePayload = { id: invoiceItemId };

    for (const key in payload) {
      if (payload[key] !== +original[key]) {
        updatePayload[key] = payload[key];
      }
    }

    const serviceCall = isEditing
      ? Service.updateLineItem(updatePayload)
      : Service.postLineItem({
          invoice_id: invoiceId,
          ic_id: IC?.id,
          driver_id: representativeDriver?.id || null,
          route_id: route?.id,
          ...payload,
        });

    const { error } = await serviceCall;

    setState((prevState) => ({ ...prevState, isLoading: false }));

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    const successMessage = isEditing
      ? "Invoice Item updated successfully."
      : "Invoice Item added successfully.";
    toast.success(successMessage);
    onClose();
    handleGetInvoice();
  };

  const getFieldValidatorMap = (state, field) => {
    const fieldValidatorMap = {
      customer: [{ type: VALIDATIONS.REQUIRED, value: true }],
      location: [{ type: VALIDATIONS.REQUIRED, value: true }],
      route: [
        { type: VALIDATIONS.MAX_LENGTH, value: 30 },
        { type: VALIDATIONS.REQUIRED, value: true },
      ],
      notes: [
        { type: VALIDATIONS.MAX_LENGTH, value: 2000 },
        {
          type: VALIDATIONS.REQUIRED,
          value: state.isPreviouslyUnbilled,
        },
        {
          type: VALIDATIONS.REQUIRED,
          value: state.isCredit,
        },
      ],
      IC: [{ type: VALIDATIONS.REQUIRED, value: true }],
      billRate: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MAX_LENGTH, value: 10, inputType: "number" },
        { type: VALIDATIONS.MIN_LENGTH, value: 0, inputType: "number" },
        { type: VALIDATIONS.MIN, value: 0 },
      ],
      billQuantity: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],

      billFuelSurcharge: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.MAX, value: 100 },
      ],
      billRatePerExtraMile: [
        { type: VALIDATIONS.MAX_LENGTH, value: 10, inputType: "number" },
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.MIN_LENGTH, value: 0, inputType: "number" },
      ],
      billExtraMiles: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      tollAmount: [
        { type: VALIDATIONS.MAX_LENGTH, value: 10, inputType: "number" },
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.MIN_LENGTH, value: 0, inputType: "number" },
      ],
    };

    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleChange = (evt) => {
    const name = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    const type = evt.currentTarget?.type || evt.target?.type;
    const errorMessage = validate(name, value) || " ";

    if (type === "number" && value.indexOf(".") !== -1) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((+value + Number.EPSILON) * 100) / 100;
        value = value.toFixed(2);
      }
    }
    if (type === "number" && value?.length > 1 && value.indexOf(".") === -1) {
      value = value?.replace(/^0+/, "");
    }

    if (isEditing) {
      const parseValue = (val) => (val == null || isNaN(val) ? val : +val);

      const initialValues = {
        billRate: parseValue(invoiceRowBeingEdited[0].bill_rate),
        billQuantity: parseValue(invoiceRowBeingEdited[0].bill_quantity),
        billFuelSurcharge: parseValue(
          invoiceRowBeingEdited[0].bill_fuel_surcharge
        ),
        billRatePerExtraMile: parseValue(
          invoiceRowBeingEdited[0].bill_rate_per_extra_mile
        ),
        billExtraMiles: parseValue(invoiceRowBeingEdited[0].bill_extra_miles),
        tollAmount: parseValue(invoiceRowBeingEdited[0].toll_amount),
      };

      const newValue = parseValue(value);
      const originalValue = parseValue(initialValues[name]);

      const isFormChanged = newValue !== originalValue;

      // Check if all fields are back to their original values
      const allFieldsBackToOriginal = Object.keys(initialValues).every(
        (key) => {
          const currentValue = parseValue(key === name ? value : state[key]);
          const initialValue = parseValue(initialValues[key]);
          return currentValue === initialValue;
        }
      );

      setState((prevState) => ({
        ...prevState,
        [name]: value,
        errors: {
          ...prevState.errors,
          [name]: errorMessage,
        },
        isFormChanged: isFormChanged || !allFieldsBackToOriginal,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
        errors: {
          ...prevState.errors,
          [name]: errorMessage,
        },
      }));
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
        <Dialog.Title hasClose>{`${
          isEditing ? "Edit" : "Add"
        } Row`}</Dialog.Title>
        <Dialog.Content>
          <div className="d-flex f-align-center">
            <TextField
              className="mr-4 mt-4"
              disabled
              name="customer"
              label="Customer"
              variant="outlined"
              size="small"
              value={state.customer?.name || "-"}
              fullWidth
              error={!!state.errors?.customer?.trim()}
              helperText={state.errors?.customer}
            />
            <Autocomplete
              className="mt-4"
              fullWidth
              name="location"
              disableClearable
              disabled={!state.customer || !state.location.length}
              error={!!state.errors?.location?.trim()}
              size="small"
              classes={{
                paper: "mb-2",
              }}
              value={state.location}
              options={branchList}
              getOptionLabel={(option) => option?.location || option || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location"
                  variant="outlined"
                  required
                  helperText={state.errors?.location}
                />
              )}
              onChange={(evt, location) => {
                setState((prevState) => ({
                  ...prevState,
                  location,
                  route: defaultState.route,
                }));
              }}
            />
          </div>
          <Autocomplete
            className="mt-4"
            fullWidth
            name="route"
            disableClearable
            size="small"
            classes={{
              paper: "mb-2",
            }}
            value={state.route}
            disabled={
              !state.location || isEditing || selectedRoutes.length === 1
            }
            required
            error={!!state.errors?.route?.trim()}
            options={allRoutes ? routeList : selectedRoutes}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  {option?.name || option || ""}
                  {!option?.is_active && (
                    <Tooltip title="Inactive Route" placement="top-start">
                      <ToggleOffIcon style={{ color: "red", fontSize: 30 }} />
                    </Tooltip>
                  )}
                </span>
              </React.Fragment>
            )}
            getOptionLabel={(option) =>
              option?.name || option?.route_name || option || ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Route"
                variant="outlined"
                helperText={state.errors?.route}
                required
              />
            )}
            onChange={(evt, route) => {
              setState((prevState) => ({
                ...prevState,
                route,
              }));
            }}
          />
          <div className="d-flex f-align-center">
            <Autocomplete
              size="small"
              disabled={isEditing}
              disableClearable
              name="IC"
              error={!!state.errors?.IC?.trim()}
              className="mr-4 mt-4 w-50"
              value={{
                label: `${state.IC?.last_name || state.IC?.ic_name || ""} ${
                  state.IC?.first_name || ""
                }${
                  state.IC?.contractorTypeValue === "WCP Master Contractor"
                    ? ` - (${state.IC?.contractorTypeValue})`
                    : ""
                }`.trim(),
              }}
              options={appDataList.icList?.map((item) => ({
                ...item,
                label: `${item.icid || ""} - ${item.last_name || ""} ${
                  item.first_name || ""
                } ${
                  item.contractorTypeValue === "WCP Master Contractor"
                    ? `- (${item?.contractorTypeValue})`
                    : ""
                }`,
              }))}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) => option.icid === value.icid}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    state?.IC &&
                    state?.IC?.contractorTypeValue === "WCP Master Contractor"
                      ? "WCP Master Contractor"
                      : "IC"
                  }
                  variant="outlined"
                  helperText={state.errors?.IC}
                  required
                />
              )}
              onChange={(evt, value) => {
                setState((prevState) => ({
                  ...prevState,
                  IC: value,
                  representativeDriver: null,
                }));
              }}
            />
            <TextField
              disabled={true}
              fullWidth
              label="ICID"
              variant="outlined"
              size="small"
              className="mt-4"
              style={{ width: "50%" }}
              value={state.IC?.icid}
              helperText=" "
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          {state.IC?.contractorTypeValue === "WCP Master Contractor" && (
            <div className="d-flex f-align-center mb-6">
              <Autocomplete
                size="small"
                className="w-100 mb-1"
                fullWidth
                disabled={isEditing}
                value={state.representativeDriver}
                options={state.representativeDriverList}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.last_name || ""}`
                }
                renderInput={(params) =>
                  state.isFetchingDriverList ? (
                    <Skeleton variant="rect" width="100%" height="41px" />
                  ) : (
                    <TextField
                      {...params}
                      label="WCP-Driver"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            <InputAdornment
                              position="end"
                              className={classes.weekInputProps}
                            >
                              <Tooltip
                                title="This is the list of representative drivers for WCP."
                                placement="top"
                              >
                                <InfoOutlinedIcon
                                  fontSize="small"
                                  className={classes.infoIcon}
                                />
                              </Tooltip>
                            </InputAdornment>
                          </>
                        ),
                      }}
                    />
                  )
                }
                onChange={(evt, value) => {
                  setState((prevState) => ({
                    ...prevState,
                    representativeDriver: value,
                  }));
                }}
              />
            </div>
          )}
          <div className="d-flex f-align-center">
            <FormControlLabel
              className=" ml-1 mb-4"
              labelPlacement="start"
              disabled={state.isCredit}
              label="Was Previously Unbilled:"
              control={
                <Checkbox
                  color="primary"
                  checked={state.isPreviouslyUnbilled}
                  onChange={(evt) => {
                    const isPreviouslyUnbilled = evt.target.checked;
                    setState((prevState) => ({
                      ...prevState,
                      isPreviouslyUnbilled,
                      isCredit: false,
                      isFormChanged: isEditing,
                      errors: {
                        ...prevState.errors,
                        notes:
                          !isPreviouslyUnbilled &&
                          state.errors.notes === "Required"
                            ? " "
                            : state.errors.notes,
                      },
                    }));
                  }}
                />
              }
            />
            <FormControlLabel
              className="mb-4"
              style={{ marginLeft: "65px" }}
              labelPlacement="start"
              disabled={state.isPreviouslyUnbilled}
              label="Credit:"
              control={
                <Checkbox
                  color="primary"
                  checked={state.isCredit}
                  onChange={(evt) => {
                    const isCredit = evt.target.checked;
                    setState((prevState) => ({
                      ...prevState,
                      isCredit,
                      isPreviouslyUnbilled: false,
                      billQuantity: !!state.billQuantity
                        ? 0
                        : defaultState.billQuantity,
                      isFormChanged: isEditing,
                      errors: {
                        ...prevState.errors,
                        notes:
                          !isCredit && state.errors.notes === "Required"
                            ? " "
                            : state.errors.notes,
                      },
                    }));
                  }}
                />
              }
            />
          </div>
          <div className="d-flex f-align-center">
            <TextField
              className="mr-4 mt-4"
              name="billRate"
              type="number"
              label="Bill Rate"
              variant="outlined"
              required
              size="small"
              value={state.billRate}
              fullWidth
              onChange={handleChange}
              error={!!state.errors?.billRate?.trim()}
              onKeyDown={preventInputKeyCodes}
              onWheel={(event) => event.target.blur()}
              helperText={state.errors?.billRate}
            />
            <TextField
              className="mt-4"
              name="billQuantity"
              type="number"
              label="Bill Quantity"
              variant="outlined"
              size="small"
              required
              value={state.billQuantity}
              fullWidth
              onChange={handleChange}
              error={!!state.errors?.billQuantity?.trim()}
              onWheel={(event) => event.target.blur()}
              onKeyDown={(evt) =>
                preventInputKeyCodes(evt, { HYPHEN: state.isCredit })
              }
              helperText={state.errors?.billQuantity}
            />
          </div>
          <div className="d-flex f-align-center">
            <TextField
              className="mr-4 mt-4"
              name="billFuelSurcharge"
              type="number"
              label="Bill Fuel Surcharge"
              variant="outlined"
              size="small"
              value={state.billFuelSurcharge}
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment>%</InputAdornment>,
              }}
              onChange={handleChange}
              error={!!state.errors?.billFuelSurcharge?.trim()}
              onKeyDown={preventInputKeyCodes}
              onWheel={(event) => event.target.blur()}
              helperText={state.errors?.billFuelSurcharge}
            />
            <TextField
              className="mt-4"
              name="billRatePerExtraMile"
              type="number"
              label="Bill Rate Per Extra Mile"
              variant="outlined"
              size="small"
              value={state.billRatePerExtraMile}
              onKeyDown={preventInputKeyCodes}
              onWheel={(event) => event.target.blur()}
              fullWidth
              onChange={handleChange}
              error={!!state.errors?.billRatePerExtraMile?.trim()}
              helperText={state.errors?.billRatePerExtraMile}
            />
          </div>
          <div className="d-flex f-align-center">
            <TextField
              className="mr-4 mt-4"
              name="billExtraMiles"
              type="number"
              label="Bill Extra Miles"
              variant="outlined"
              size="small"
              value={state.billExtraMiles}
              fullWidth
              onChange={handleChange}
              error={!!state.errors?.billExtraMiles?.trim()}
              onKeyDown={preventInputKeyCodes}
              onWheel={(event) => event.target.blur()}
              helperText={state.errors?.billExtraMiles}
            />
            <TextField
              className="mt-4"
              name="tollAmount"
              type="number"
              label="Toll Amount"
              variant="outlined"
              size="small"
              value={state.tollAmount}
              fullWidth
              onChange={handleChange}
              error={!!state.errors?.tollAmount?.trim()}
              onWheel={(event) => event.target.blur()}
              onKeyDown={preventInputKeyCodes}
              helperText={state.errors?.tollAmount}
            />
          </div>
          <TextField
            className="mb-1"
            fullWidth
            multiline
            name="notes"
            required={state.isPreviouslyUnbilled || state.isCredit}
            rows={4}
            label="Notes"
            variant="outlined"
            size="small"
            value={state.notes}
            error={!!state.errors.notes?.trim()}
            helperText={state.errors.notes}
            onChange={handleChange}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <div className="p-4">
            <Button variant="outlined" onClick={onClose} className="ml-2 mr-2">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="ml-2 mr-2"
              disabled={
                state.isLoading ||
                validate() ||
                (isEditing && !state.isFormChanged)
              }
              onClick={handleSubmit}
            >
              {isEditing ? "Edit" : "Add"}
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default AddInvoiceItemDialog;
