import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  fixedHeader: {
    width: "100%",
    top: "64px",
    marginBottom: "12px",
    height: "64px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
  },
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  dropdownPaper: {
    minWidth: 156,
  },
  dropdownBackground: {
    background: "cornflowerblue",
    borderRadius: 8,
  },
  scheduleDateWrapper: {
    "& .react-datepicker__close-icon": {
      top: 2,
      right: 48,
    },
    "& .react-datepicker__close-icon::after": {
      background: "transparent",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: 28,
    },
  },
  blur: {
    filter: "blur(5px)",
  },
  datepickerWrapper: {
    width: 171,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  dropdownPaper: {
    minWidth: 156,
  },
  customerDropdown: {
    width: 180,
  },
  autocompleteFilters: {
    minWidth: 208,
  },
  paperSize: {
    width: "400px",
  },
}));

export default useStyles;
