import jsPDF from "jspdf";
import "jspdf-autotable";
import pdxSouthLogo from "assets/images/PDXSouth.png";
import pdxNorthLogo from "assets/images/PDXNorth.png";
import pdxWestLogo from "assets/images/PDXWest.png";
import pdxLogo from "assets/images/PDX.jpg";

const GenerateCreditMemoPDF = ({
  creditMemoDetails = [],
  creditNo,
  creditDate,
  customerNotes,
  pdxCompany,
  status,
  address = {},
  invoices = 0,
  isPreview = false,
  isPrinting = false,
  isArrayPrinting = false,
  fromDate,
  toDate,
}) => {
  const doc = new jsPDF({
    orientation: "portrait",
    format: "a4",
  });

  const pageWidth = doc.internal.pageSize.width;

  const tableData = creditMemoDetails.sort((a, b) => {
    if (a.position === null) return 1;
    if (b.position === null) return -1;
    return a.position - b.position;
  });

  const initialTotalAmount = tableData
    .reduce((sum, row) => sum + (+row.credit_amount || 0), 0)
    .toFixed(2);

  const totalAmount = (+initialTotalAmount + +(invoices || 0)).toFixed(2);

  const text = status !== "approved" ? status?.toUpperCase() || " " : " ";

  // Function to add watermark
  const addwatermarkDStripe = (text, options) => {
    const { fontSize, angle, color, xInterval, yInterval } = options;

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    doc.setFontSize(fontSize);
    doc.setTextColor(color);

    const radAngle = (angle * Math.PI) / 180;
    const cosAngle = Math.cos(radAngle);
    const sinAngle = Math.sin(radAngle);

    // Calculate the width and height of the rotated text box
    const textWidth =
      (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
    const textHeight = fontSize / doc.internal.scaleFactor;
    const rotatedTextWidth =
      Math.abs(textWidth * cosAngle) + Math.abs(textHeight * sinAngle);
    const rotatedTextHeight =
      Math.abs(textWidth * sinAngle) + Math.abs(textHeight * cosAngle);

    // Iterate over the defined pattern and place the text
    for (let y = -pageHeight; y < pageHeight * 2; y += yInterval) {
      for (let x = -pageWidth; x < pageWidth * 2; x += xInterval) {
        // Calculate rotated coordinates
        const rotatedX = x * cosAngle - y * sinAngle + pageWidth / 2;
        const rotatedY = x * sinAngle + y * cosAngle + pageHeight / 2;

        // Adjust for centering the text at (rotatedX, rotatedY)
        const textOffsetX = rotatedTextWidth / 2;
        const textOffsetY = rotatedTextHeight / 2;

        if (
          rotatedX - textOffsetX >= 0 &&
          rotatedX + textOffsetX <= pageWidth &&
          rotatedY - textOffsetY >= 0 &&
          rotatedY + textOffsetY <= pageHeight
        ) {
          doc.saveGraphicsState();
          doc.setGState(new doc.GState({ opacity: 0.4 }));
          doc.text(text, rotatedX - textOffsetX + 0, rotatedY - textOffsetY, {
            angle: angle,
          });

          doc.setTextColor(0, 0, 0); // Reset text color
          doc.restoreGraphicsState();
        }
      }
    }
  };

  const watermarkOptions = {
    fontSize: 12,
    angle: -45,
    color: (128, 128, 128),
    xInterval: 50, // Horizontal interval between watermarks
    yInterval: 70, // Vertical interval between watermarks
  };

  let footArray = [
    [
      {
        content: `${customerNotes || " "}`,
        colSpan: 2,
        styles: {
          halign: "left",
          valign: "top",
          lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0.1 },
        },
      },

      {
        content: "Total",
        colSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          fontSize: 10,
          lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0.1 },
        },
      },
      {
        content: `$${initialTotalAmount || "0.00"}`,
        colSpan: 1,
        styles: {
          halign: "right",
          lineWidth: { top: 0.1, right: 0.1, bottom: 0, left: 0 },
          valign: "middle",
        },
      },
    ],
    [
      {
        content: "",
        colSpan: 2,
        styles: {
          halign: "left",
          valign: "top",
          lineWidth: { top: 0, right: 0, bottom: 0, left: 0.1 },
        },
      },
      {
        content: "Balance Credit",
        colSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          fontSize: 12,
          lineWidth: { top: 0, right: 0, bottom: 0, left: 0.1 },
        },
      },
      {
        content: `$${totalAmount || "0.00"}`,
        colSpan: 1,
        styles: {
          halign: "right",
          fontSize: 11,
          lineWidth: { top: 0, right: 0.1, bottom: 0, left: 0 },
          valign: "middle",
        },
      },
    ],
  ];

  const invoicesRow = [
    [
      {
        content: "",
        colSpan: 2,
        styles: {
          halign: "left",
          valign: "top",
          lineWidth: { top: 0, right: 0, bottom: 0, left: 0.1 },
        },
      },
      {
        content: "Invoices",
        colSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          fontSize: 10,
          lineWidth: { top: 0, right: 0, bottom: 0, left: 0.1 },
        },
      },
      {
        content: `$${invoices || "0.00"}`,
        colSpan: 1,
        styles: {
          halign: "right",
          lineWidth: { top: 0, right: 0.1, bottom: 0, left: 0 },
          valign: "middle",
        },
      },
    ],
  ];
  footArray.splice(1, 0, ...invoicesRow);

  const addHeaders = () => {
    // Set text color
    doc.setTextColor(0, 0, 0);

    // Switch case to handle different companies
    switch (pdxCompany.toUpperCase()) {
      case "PDX SOUTH":
        doc.text("PDX South Inc.", 9, 22);
        doc.setFontSize(11);
        doc.text("P.O. Box 329", 9, 28);
        doc.text("Essington, PA 19029", 9, 34);
        doc.text("(610) 595-3390", 9, 40);
        doc.addImage(pdxSouthLogo, "PNG", 60, 18, 40, 20); // assuming pdxSouthLogo is defined
        break;

      case "PDX NORTH":
        doc.text("PDX North", 9, 22);
        doc.setFontSize(11);
        doc.text("P.O. Box 329", 9, 28);
        doc.text("Essington, PA 19029", 9, 34);
        doc.text("(610) 595-3390", 9, 40);
        doc.addImage(pdxNorthLogo, "PNG", 60, 18, 40, 20); // assuming abcCorpLogo is defined
        break;

      case "PDX WEST":
        doc.text("PDX West", 9, 22);
        doc.setFontSize(11);
        doc.text("P.O. Box 329", 9, 28);
        doc.text("Essington, PA 19029", 9, 34);
        doc.text("(610) 595-3390", 9, 40);
        doc.addImage(pdxWestLogo, "PNG", 60, 18, 40, 20); // assuming xyzEnterprisesLogo is defined
        break;

      // Add more cases as needed for different companies

      default:
        doc.text("PDX Inc.", 9, 22);
        doc.setFontSize(11);
        doc.text("P.O. Box 329", 9, 28);
        doc.text("Essington, PA 19029", 9, 34);
        doc.text("(610) 595-3390", 9, 40);
        // Add a default logo if needed
        doc.addImage(pdxLogo, "JPEG", 60, 18, 40, 20); // assuming defaultLogo is defined
        break;
    }

    // Add invoice title
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text("Credit Memo", pageWidth - 55, 25);

    // Add Credit Date and Number with border
    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");

    // Draw rectangle for Credit Date and Number
    doc.rect(141.5, 30, 62, 15); // Main border
    doc.line(173, 45, 173, 30); // Vertical line
    doc.line(141.5, 36, 203.5, 36); // Horizontal line

    // Add text inside the table
    doc.text("Date", pageWidth - 52, 34.5, { align: "center" });
    doc.text("Credit No.", pageWidth - 22, 34.5, { align: "center" });
    doc.text(`${creditDate || "-"}`, 148, 42);
    doc.text(`${creditNo || "-"}`, pageWidth - 22, 42, {
      align: "center",
    });

    // Add Bill To section with border box and header line
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    doc.text("Customer", 14, 60);

    // Draw rectangle around Bill To section
    doc.rect(12, 54, 90, 45); // Adjust the size and position as needed

    // Draw horizontal line inside the rectangle for header separation
    doc.line(12, 62, 102, 62);

    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");
    doc.text(
      [address?.customerName, address?.branch]
        .filter((value) => value)
        .join(", "),
      14,
      67
    );
    doc.text(`${address?.primary_contact || ""}`, 14, 73);
    doc.text(`${address?.streetAddress || ""}`, 14, 79);
    doc.text(
      [address?.city, address?.state].filter((value) => value).join(", "),
      14,
      85
    );
    doc.text(
      [address?.zipCode, address?.country].filter((value) => value).join(", "),
      14,
      91
    );
  };

  addHeaders();

  const addWatermarkImage = (imgData) => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const imgWidth = 100;
    const imgHeight = 60;

    // Calculate center position
    const xPos = (pageWidth - imgWidth) / 2;
    const yPos = (pageHeight - imgHeight) / 2;
    doc.saveGraphicsState();
    doc.setGState(new doc.GState({ opacity: 0.1 }));

    doc.addImage(imgData, "PNG", xPos, yPos, imgWidth, imgHeight);
    doc.restoreGraphicsState();
  };

  const addBlankRows = (table) => {
    const rowsPerPage = 5;
    const modifiedTable = [...table];

    if (table.length < rowsPerPage) {
      const blankRow = {
        credit_description: "\n\n\n",
        credit_quantity: "\n\n\n",
        credit_rate: "\n\n\n",
        credit_amount: "\n\n\n",
      };

      // Add only one blank row if needed
      modifiedTable.push(blankRow);
    }

    return modifiedTable; // Return the modified copy
  };

  const updatedTableData = addBlankRows(tableData);

  // Function to add table with autoTable
  const addTable = () => {
    doc.autoTable({
      rowPageBreak: "avoid",
      head: [["Description", "Qty", "Rate", "Amount"]],
      columns: [
        { header: "DESCRIPTION", dataKey: "credit_description" },
        { header: "QTY", dataKey: "credit_quantity" },
        { header: "RATE", dataKey: "credit_rate" },
        { header: "AMOUNT", dataKey: "credit_amount" },
      ],
      body: [
        {
          credit_description: `Parts Delivery W/E ${
            `${fromDate} - ${toDate}` || " "
          }`,
        },
        ...updatedTableData,
      ],
      showFoot: "lastPage",
      theme: "plain",
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.1,
        halign: "center",
      },
      margin: { left: 6, right: 6, top: 110 },
      columnStyles: {
        credit_description: {
          cellWidth: 110,
          lineWidth: { top: 0, right: 0.1, bottom: 0.1, left: 0.1 },
          halign: "left",
        },
        credit_quantity: {
          cellWidth: 20,
          lineWidth: { top: 0, right: 0.1, bottom: 0.1, left: 0 },
          halign: "right",
        },
        credit_rate: {
          cellWidth: 35,
          lineWidth: { top: 0, right: 0.1, bottom: 0.1, left: 0 },
          halign: "right",
        },
        credit_amount: {
          cellWidth: 32,
          lineWidth: { top: 0, right: 0.1, bottom: 0.1, left: 0 },
          halign: "right",
        },
      },
      foot: footArray,
      footStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.1,
        fontStyle: "normal",
      },
      styles: {
        fontSize: 9,
        cellPadding: 1,
        lineColor: [0, 0, 0],
        border: { bottom: 10 },
      },
      didDrawPage: (data) => {
        addwatermarkDStripe(text, watermarkOptions);

        switch (pdxCompany.toUpperCase()) {
          case "PDX SOUTH":
            addWatermarkImage(pdxSouthLogo);
            break;

          case "PDX NORTH":
            addWatermarkImage(pdxNorthLogo);
            break;

          case "PDX WEST":
            addWatermarkImage(pdxWestLogo);
            break;

          default:
            addWatermarkImage(pdxLogo);
            break;
        }

        // Add page number
        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();

        doc.setFontSize(10);

        // Calculate text width
        const textWidth =
          doc.getStringUnitWidth(`Page ${data.pageNumber}`) *
          doc.internal.scaleFactor;
        // Calculate x position to center text
        const textX = (pageWidth - textWidth) / 2;

        doc.text(`Page ${data.pageNumber}`, textX, pageHeight - 10);
        if (data.pageNumber > 1) {
          addHeaders();
        }
      },
    });
    const finalY =
      doc.previousAutoTable.finalY || doc.internal.pageSize.height - 20;
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
    doc.line(6, finalY, 203, finalY);
  };

  // Add the table
  addTable();

  doc.setProperties({
    title: `Credit No. ${creditNo}`,
  });

  // Save the PDF
  if (isPreview) return doc.output("bloburl");
  else if (isPrinting) {
    window.open(doc.output("bloburl"));
  } else if (isArrayPrinting) {
    return doc.output("arraybuffer");
  } else {
    doc.save(`Credit-${creditNo}.pdf`);
  }
};

export default GenerateCreditMemoPDF;
