import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  fixedHeader: {
    width: "100%",
    top: "64px",
    marginBottom: "12px",
    height: "64px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
  },
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  scheduleDateWrapper: {
    "& .react-datepicker__close-icon": {
      top: 2,
      right: 48,
    },
    "& .react-datepicker__close-icon::after": {
      background: "transparent",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: 28,
    },
  },
  paid: {
    backgroundColor: "#d4edda",
  },
  pulseRow: {
    animation: "$pulse 2s infinite",
  },
  "@keyframes pulse": {
    "0%": {
      backgroundColor: "rgba(0, 0, 139, 0.4)",
    },
    "10%": {
      backgroundColor: "rgba(0, 0, 139, 0.36)",
    },
    "20%": {
      backgroundColor: "rgba(0, 0, 139, 0.32)",
    },
    "30%": {
      backgroundColor: "rgba(0, 0, 139, 0.28)",
    },
    "40%": {
      backgroundColor: "rgba(0, 0, 139, 0.24)",
    },
    "50%": {
      backgroundColor: "rgba(0, 0, 139, 0.2)",
    },
    "60%": {
      backgroundColor: "rgba(0, 0, 139, 0.16)",
    },
    "70%": {
      backgroundColor: "rgba(0, 0, 139, 0.12)",
    },
    "80%": {
      backgroundColor: "rgba(0, 0, 139, 0.08)",
    },
    "90%": {
      backgroundColor: "rgba(0, 0, 139, 0.04)",
    },
    "100%": {
      backgroundColor: "rgba(0, 0, 139, 0)",
    },
  },
  overdue: {
    backgroundColor: "#f8d7da",
  },
  latePayemnt: {
    backgroundColor: "#f5acb3",
  },
  datepickerWrapper: {
    width: 171,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  dropdownPaper: {
    minWidth: 156,
  },
  dropdownBackground: {
    background: "cornflowerblue",
    borderRadius: 8,
  },
  customerDropdown: {
    width: 180,
  },
  autocompleteFilters: {
    minWidth: 208,
  },
  paperSize: {
    width: "400px",
  },
  invoiceSentPaperSize: {
    minWidth: 800,
    width: 800,
    "& .MuiDialogContent-root": {
      paddingTop: "0px",
    },
    minHeight: 120,
    maxHeight: 800,
    overflowY: "auto",
  },
  inputRoot: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  chipsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "6px",
    marginBottom: theme.spacing(1),
  },
  buttonStyle1: {
    borderRadius: "1rem",
    background: "rgb(0 71 0)",
    marginTop: "16px",
    border: "0px",
    height: "30px",
    minWidth: "28px",
    width: "28px",
    marginRight: "4px",
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  paperSizeDialog: {
    width: 530,
  },
  blur: {
    filter: "blur(5px)",
  },
  tableHeaderwidth: {
    minWidth: 210,
  },
}));

export default useStyles;
