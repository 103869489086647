import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import {
  Grid as DataGrid,
  ActionDialog,
  Dialog,
  Dropdown,
  FilterComponent,
} from "shared/components";
import Grid from "../grid";
import {
  APX_COMPANY,
  PAGE_KEYS,
  VALIDATIONS,
  isTransferScheduleEnable,
  noop,
} from "shared/constants";
import { DynamicForm } from "modules/shared/components";
import { useLocation } from "react-router-dom";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {
  queryStringBuilderNew,
  getPageConfig,
  setPageConfig,
  hasFeatureAccess,
  getTokenData,
  updatePagination,
  updatePageConfig,
  exportBase64ToFile,
  updateGridLayout,
  debounce,
  LoadingOverlay,
} from "utils";
import { AppContext } from "shared/contexts";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Chip from "@material-ui/core/Chip";
import ForceSubmitDialog from "../../daily-schedule/forceSubmit";
import {
  ERROR_CODES,
  INVOICE_TYPES,
  RATE_TYPES,
  PERMISSION,
  ROLE,
} from "modules/shared/constants";
import Service from "../service";
import Form from "../form";
import SharedService from "modules/shared/service";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import SearchIcon from "@material-ui/icons/Search";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import Maps from "./../Maps";
import useStyles from "./style";
import EditLocationIcon from "@material-ui/icons/EditLocation";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CreditGrid from "./creditGrid";

let gridHelper = null;
let timeout = null;

const STATUS = [
  { label: "Only Active", value: true },
  { label: "Only Inactive", value: false },
  { label: "All", value: null },
];
const defaultState = {
  stateList: [],
  locationList: [],
  customerlocationList: [],
  entries: [],
  allLocation: [],
  isDownloadLoading: false,
  totalEntries: 0,
  customers: [],
  customerAddressTypeList: [],
  customersList: [],
  pageFilters: [],
  isFiltering: false,
  deletingEntryIds: null,
  priceMatrix: [],
  editLocation: [],
  locationById: [],
  addNewAddress: false,
  deleteAddress: false,
  openAddressDialog: false,
  subLocation: false,
  isFetching: false,
  isFetchingList: {
    pdxCompany: false,
    customerAddressType: false,
    users: false,
    customFuelSurcharge: false,
    routeBillFs: false,
    routePayFs: false,
  },
  search: "",
  isNew: false,
  rowBeingEdited: null,
  rowBeingCopied: null,
  creditAmount: 0,
  addressRowBeingEdited: null,
  addressAdded: true,
  isPriceMatrixFetched: false,
  isRoasterPresent: false,
  isAddressListLoading: false,
  pdxCompanyList: [],
  routeBillFsList: [],
  routePayFsList: [],
  customerTypeList: [],
  customFuelSurchargeList: [],
  fieldBeingEdited: null,
  openForceSubmitDialog: false,
  markActiveInactiveIds: null,
  formType: null,
  deletingEntry: null,
  customerDeleteConfirmation: false,
  openCustomerDeleteDialog: false,
  openBranchDeleteDialog: false,
  openRouteDeleteDialog: false,
  isFetchingAllCustomer: false,
  isFetchingLeaveTypeList: false,
  pageNumber: 1,
  pageSize: 100,
  confirmInactive: false,
  overrideKAMList: [],
  vehicleTypeList: [],
  kamsName: [],
  filters: {
    status: [],
    is_deleted: [],
    roasterType: [],
    locations: [],
  },
  formAlertFields: {},
  selectedLocation: [],
  fieldAlert: {
    show: false,
    id: null,
    errors: {},
    isLoading: false,
    subHeading: "",
    configs: [],
    updateValidations: noop,
    onChange: noop,
  },
  creditPopUp: false,
};

const ViewCustomers = ({ match, history }) => {
  const pageConfig = getPageConfig(PAGE_KEYS.CUSTOMERS);
  const userData = getTokenData();
  const currentUser = getTokenData() || {};
  const { appData, setHeaderElements, updateContextData } =
    useContext(AppContext);
  const location = useLocation();
  const data = location.state || {};

  const classes = useStyles();
  const [state, setState] = useState({
    ...defaultState,
    pageSize: pageConfig?.pageSize || defaultState.pageSize,
    pageNumber: pageConfig?.pageNumber || defaultState.pageNumber,
    // search: !data.route_name ? "" : data?.route_name,
    search: (pageConfig && pageConfig?.search) || defaultState.search,
    // selectedLocation:
    //   (pageConfig && pageConfig?.locationFilter?.value) ||
    //   defaultState.selectedLocation,
    pageFilters:
      (pageConfig && pageConfig?.filters) || defaultState.pageFilters,
    filters: {
      ...defaultState.filters,
      status: (pageConfig && pageConfig?.statusUser?.status) || [],
      roasterType: (pageConfig && pageConfig?.roasterType) || [],
      // locations: (pageConfig && pageConfig?.locationFilter?.locations) || [],
    },
  });

  const isRoleADMIN = (currentUser?.role || "").toLowerCase() === ROLE.ADMIN;
  const isRoleKAM = (currentUser?.role || "").toLowerCase() === ROLE.KAM;
  const isRoleSalesVIEW =
    (currentUser?.role || "").toLowerCase() === ROLE.SALES_VIEW;
  const kamId = isRoleKAM ? userData?.id : state.selectedKAM?.id;

  const getSearchArray = (search) => {
    const columns = [
      "name",
      "route_name",
      "cust_branch_state",
      "cust_branch_location",
    ];
    return columns.map((column) => ({
      field: column,
      value: search,
    }));
  };

  const appDataList = useMemo(() => {
    const users = appData.users.reduce(
      (acc, user) => {
        const role = (user.role || "").toLowerCase();
        acc[role] && user?.is_active && acc[role].push(user);
        return acc;
      },
      {
        kam: [],
        aa: [],
        mgr: [],
      }
    );

    const getOptionList = (users = []) => {
      return users.map((list) => ({
        value: list,
        label:
          list?.name ||
          `${list.first_name || ""} ${list.last_name || ""}-${
            list.email || ""
          }-(${list.username || ""})`,
      }));
    };

    return {
      kamUsers: users.kam,
      officeUsers: users.aa,
      managerUsers: users.mgr,
      kamUsersOptions: getOptionList(users.kam),
      officeUsersOptions: getOptionList(users.aa),
      managerUsersOptions: getOptionList(users.mgr),
    };
  }, [appData]);

  const getPzriceMatrix = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isPriceMatrixFetched: true,
    }));
    const { data, error } = await Service.priceMatrix();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isPriceMatrixFetched: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      priceMatrix: data.rows || defaultState.priceMatrix,
      isPriceMatrixFetched: false,
    }));
  }, []);

  const filterConfig = useMemo(
    () =>
      !isRoleKAM
        ? [
            {
              field: "cust_type",
              fieldToDisplay: "Industry Type",
              operatorType: "string",
              options: state.customerTypeList.map((type) => ({
                ...type,
                label: type.value,
                value: type.value,
              })),
            },
            {
              field: "inv_pymt_terms",
              fieldToDisplay: "Terms",
              operatorType: "eq",
            },
            {
              field: "cust_branch_pdx_comp_name",
              fieldToDisplay: "PDX Company",
              operatorType: "string",
              options: state.pdxCompanyList.map((company) => ({
                label: company.value,
                value: company.value,
              })),
            },

            {
              field: "branch_aa_name",
              fieldToDisplay: "Office",
              operatorType: "string",
              options: appDataList.officeUsers.map((office) => ({
                ...office,
                label: `${office.first_name} ${office.last_name}-(${office.username})`,
                value: `${office.first_name} ${office.last_name}`,
              })),
            },
            {
              field: "branch_kam_name",
              fieldToDisplay: "Default KAM",
              operatorType: "string",
              options: appDataList.kamUsers.map((kam) => ({
                ...kam,
                label: `${kam.first_name} ${kam.last_name}-(${kam.username})`,
                value: `${kam.first_name} ${kam.last_name}`,
              })),
            },
            {
              field: "branch_mgr_name",
              fieldToDisplay: "Manager",
              operatorType: "string",
              options: appDataList.managerUsers.map((manager) => ({
                ...manager,
                label: `${manager.first_name} ${manager.last_name}-(${manager.username})`,
                value: `${manager.first_name} ${manager.last_name}`,
              })),
            },
            {
              field: "cust_branch_invoice_type",
              fieldToDisplay: "Invoice Type",
              operatorType: "iLike",
              options: INVOICE_TYPES.map((invoice) => ({
                ...invoice,
                label: `${invoice.label}`,
                value: invoice.id,
              })),
            },
            {
              field: "price_type",
              fieldToDisplay: "Price Type",
              operatorType: "string",
              options: [
                { label: "Standard", value: "Standard" },
                { label: "Price Matrix", value: "Matrix" },
              ],
            },
            {
              field: "route_name",
              fieldToDisplay: "Route",
              operatorType: "string",
              options: state.customersList
                .map((customer) => customer?.customer_branches)
                .flat()
                .map((branches) => branches?.routes)
                .flat()
                .map((route) => ({ label: route.name, value: route.name }))
                .sort((a, b) => Intl.Collator().compare(a.label, b.label)),
            },
            {
              field: "route_bill_rate_type",
              fieldToDisplay: "Bill Rate Type",
              operatorType: "string",
              options: [
                { label: "Daily", value: "Daily" },
                { label: "Hourly", value: "Hourly" },
                { label: "Per Miles", value: "Per Miles" },
                { label: "Per Tire", value: "Per Tire" },
                { label: "Per Run", value: "Per Run" },
              ],
            },
            {
              field: "route_bill_quantity",
              fieldToDisplay: "Bill Quantity",
              operatorType: "number",
            },
            {
              field: "default_bill_rate",
              fieldToDisplay: "Bill Rate",
              operatorType: "number",
            },

            {
              field: "route_bill_rate_per_extra_mile",
              fieldToDisplay: "Bill Rate Per Extra Mile",
              operatorType: "number",
            },
            {
              field: "route_bill_toll_amount",
              fieldToDisplay: "Bill Toll Amount",
              operatorType: "number",
            },
            {
              field: "route_pay_rate_type",
              fieldToDisplay: "Pay Rate Type",
              operatorType: "string",
              options: [
                { label: "Daily", value: "Daily" },
                { label: "Hourly", value: "Hourly" },
                { label: "Per Miles", value: "Per Miles" },
                { label: "Per Tire", value: "Per Tire" },
                { label: "Per Run", value: "Per Run" },
              ],
            },
            {
              field: "route_pay_quantity",
              fieldToDisplay: "Pay Quantity",
              operatorType: "number",
            },
            {
              field: "default_pay_rate",
              fieldToDisplay: "Pay Rate",
              operatorType: "number",
            },

            {
              field: "route_pay_rate_per_extra_mile",
              fieldToDisplay: "Pay Rate Per Extra Mile",
              operatorType: "number",
            },
            {
              field: "route_toll_amount",
              fieldToDisplay: "Pay Toll Amount",
              operatorType: "number",
            },
            {
              field: "route_vehicle",
              fieldToDisplay: "Vehicle",
              operatorType: "string",
              options: state.vehicleTypeList.map((vehicle) => ({
                ...vehicle,
                label: `${vehicle.value}`,
                value: vehicle.value,
              })),
            },
            {
              field: "route_fuel_type",
              fieldToDisplay: "Route Fuel Type",
              operatorType: "string",
              options: [
                { label: "Regular", value: "regular" },
                { label: "Diesel", value: "diesel" },
              ],
            },
            {
              field: "route_fs_schedule",
              fieldToDisplay: "Route F/S Schedule",
              operatorType: "string",
              options: state.customFuelSurchargeList.map((fsSchedule) => ({
                label: `${fsSchedule}`,
                value: fsSchedule,
              })),
            },
            {
              field: "route_bill_discount",
              fieldToDisplay: "Route Bill F/S%",
              operatorType: "string",
              options: state.routeBillFsList.map((item) => ({
                label: item.value,
                value: item.value.replace(/%/, "{{percent}}"),
              })),
            },
            {
              field: "route_pay_discount",
              fieldToDisplay: "Route Pay F/S%",
              operatorType: "string",
              options: state.routePayFsList.map((item) => ({
                label: item.value,
                value: item.value.replace(/%/, "{{percent}}"),
              })),
            },
          ]
        : [
            {
              field: "cust_type",
              fieldToDisplay: "Industry Type",
              operatorType: "string",
              options: state.customerTypeList.map((type) => ({
                ...type,
                label: type.value,
                value: type.value,
              })),
            },
            {
              field: "cust_branch_pdx_comp_name",
              fieldToDisplay: "PDX Company",
              operatorType: "string",
              options: state.pdxCompanyList.map((company) => ({
                label: company.value,
                value: company.value,
              })),
            },

            {
              field: "branch_aa_name",
              fieldToDisplay: "Office",
              operatorType: "string",
              options: appDataList.officeUsers.map((office) => ({
                ...office,
                label: `${office.first_name} ${office.last_name}-(${office.username})`,
                value: `${office.first_name} ${office.last_name}`,
              })),
            },
            {
              field: "branch_kam_name",
              fieldToDisplay: "Default KAM",
              operatorType: "string",
              options: appDataList.kamUsers.map((kam) => ({
                ...kam,
                label: `${kam.first_name} ${kam.last_name}-(${kam.username})`,
                value: `${kam.first_name} ${kam.last_name}`,
              })),
            },
            {
              field: "branch_mgr_name",
              fieldToDisplay: "Manager",
              operatorType: "string",
              options: appDataList.managerUsers.map((manager) => ({
                ...manager,
                label: `${manager.first_name} ${manager.last_name}-(${manager.username})`,
                value: `${manager.first_name} ${manager.last_name}`,
              })),
            },
            {
              field: "cust_branch_invoice_type",
              fieldToDisplay: "Invoice Type",
              operatorType: "iLike",
              options: INVOICE_TYPES.map((invoice) => ({
                ...invoice,
                label: `${invoice.label}`,
                value: invoice.id,
              })),
            },
            {
              field: "route_name",
              fieldToDisplay: "Route",
              operatorType: "string",
              options: state.customersList
                .map((customer) => customer?.customer_branches)
                .flat()
                .map((branches) => branches?.routes)
                .flat()
                .map((route) => ({ label: route.name, value: route.name }))
                .sort((a, b) => Intl.Collator().compare(a.label, b.label)),
            },
            {
              field: "route_pay_rate_type",
              fieldToDisplay: "Pay Rate Type",
              operatorType: "string",
              options: [
                { label: "Daily", value: "daily" },
                { label: "Hourly", value: "hourly" },
                { label: "Per Miles", value: "Per Miles" },
                { label: "Per Tire", value: "Per Tire" },
                { label: "Per Run", value: "Per Run" },
              ],
            },
            {
              field: "route_pay_quantity",
              fieldToDisplay: "Pay Quantity",
              operatorType: "number",
            },
            {
              field: "default_pay_rate",
              fieldToDisplay: "Pay Rate",
              operatorType: "number",
            },

            {
              field: "route_pay_rate_per_extra_mile",
              fieldToDisplay: "Pay Rate Per Extra Mile",
              operatorType: "number",
            },
            {
              field: "route_toll_amount",
              fieldToDisplay: "Pay Toll Amount",
              operatorType: "number",
            },
            {
              field: "route_vehicle",
              fieldToDisplay: "Vehicle",
              operatorType: "string",
              options: state.vehicleTypeList.map((vehicle) => ({
                ...vehicle,
                label: `${vehicle.value}`,
                value: vehicle.value,
              })),
            },
            {
              field: "route_fuel_type",
              fieldToDisplay: "Route Fuel Type",
              operatorType: "string",
              options: [
                { label: "Regular", value: "regular" },
                { label: "Diesel", value: "diesel" },
              ],
            },
            {
              field: "route_fs_schedule",
              fieldToDisplay: "Route F/S Schedule",
              operatorType: "string",
              options: state.customFuelSurchargeList.map((fsSchedule) => ({
                label: `${fsSchedule}`,
                value: fsSchedule,
              })),
            },
            {
              field: "route_pay_discount",
              fieldToDisplay: "Route Pay F/S%",
              operatorType: "string",
              options: state.routePayFsList.map((item) => ({
                label: item.value,
                value: item.value.replace(/%/, "{{percent}}"),
              })),
            },
          ],
    [
      appDataList.managerUsers,
      appDataList.kamUsers,
      appDataList.officeUsers,
      state.customerTypeList,
      state.routeBillFsList,
      state.routePayFsList,
      state.vehicleTypeList,
      state.locationList,
      state.customersList,
      state.stateList,
      state.pdxCompanyList,
      state.customFuelSurchargeList,
    ]
  );

  const fetchCustomerAddresstypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      isFetchingList: {
        ...prevState.isFetchingList,
        customerAddressType: true,
      },
    }));

    const { data, error } = await SharedService.getCustomerAddressTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isFetchingList: {
          ...prevState.isFetchingList,
          customerAddressType: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      customerAddressTypeList:
        data?.rows.filter(
          (customerAddressType) => customerAddressType.is_active
        ) || defaultState.customerAddressTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        customerAddressType: false,
      },
    }));
  };

  const fetchEntries = useCallback(
    async (
      search,
      pageNumber,
      pageSize,
      filters,
      pageFilters = [],
      hasLoader = true
    ) => {
      setState((prevState) => ({
        ...prevState,
        isFetching: hasLoader,
        isUpdateLoading: !hasLoader,
      }));

      const searchArray = getSearchArray(search);

      const value = false;
      const filters_is_deleted = [{ field: "is_deleted", type: "=", value }];
      // let filtersArr = [];

      // if (filters.roasterType.length) {
      //   filtersArr = [...filtersArr, ...filters.roasterType];
      // }

      let filtersArr = [
        ...filters.status,
        // ...filters.locations,
        ...filters.roasterType,
        ...filters_is_deleted,
        ...pageFilters.map((filter) => ({
          ...filter,
          value: filter?.value?.value || filter?.value,
        })),
      ];

      let queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        searchArray,
        filtersArr,
        {},
        search && search.length > 0
      );

      const { data, error } = await SharedService.getCustomerList(queryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
          isUpdateLoading: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }
      const sortedEntries = (data.customers || defaultState.entries)
        .filter((entry) => !entry.is_deleted)
        .map((entry) => {
          (entry.customer_branches || []).sort((a, b) =>
            Intl.Collator().compare(a.location || "", b.location || "")
          );
          (entry.customer_branches || [])
            .filter((customer) => !customer.is_deleted)
            .map((customer) => {
              (customer.routes || [])
                .sort((a, b) =>
                  Intl.Collator().compare(a.name || "", b.name || "")
                )
                .filter((route) => !route.is_deleted);
              return customer;
            });
          return entry;
        });

      // const locationList = (
      //   data?.customers.find(
      //     (customer) => customer.id === state.branch?.customer?.id
      //   )?.customer_branches || []
      // ).sort((a, b) => {
      //   const valueA = (a.location || "").toLowerCase();
      //   const valueB = (b.location || "").toLowerCase();
      //   return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
      // });

      setState((prevState) => ({
        ...prevState,
        isFetching: false,
        isUpdateLoading: false,
        entries: sortedEntries,
        totalEntries: data.count || defaultState.totalEntries,
        customers: data.customers || defaultState.customers,
        //branch: locationList,
        //totalEntries: sortedEntries.length || defaultState.totalEntries,
      }));
      return data;
    },
    []
  );

  const handleDownload = async (search, filters, pageFilters = []) => {
    const searchArray = getSearchArray(search);

    const value = false;
    const filters_is_deleted = [{ field: "is_deleted", type: "=", value }];

    let filtersArr = [
      ...filters.status,
      // ...filters.locations,
      ...filters.roasterType,
      ...filters_is_deleted,
      ...pageFilters.map((filter) => ({
        ...filter,
        value: filter?.value?.value || filter?.value,
      })),
    ];

    setState((prevState) => ({
      ...prevState,
      isDownloadLoading: true,
    }));

    let query = queryStringBuilderNew(
      0,
      0,
      searchArray,
      filtersArr,
      {},
      search && search.length > 0
    );

    const { data, error } = await Service.download(query);
    if (error) {
      toast.error(Array.isArray(error) ? error[0].message : error.message);
      setState((prevState) => ({
        ...prevState,
        isDownloadLoading: false,
      }));
    } else {
      toast.success("Customers records downloaded successfully.");
      exportBase64ToFile(data, "Customers");
    }

    setState((prevState) => ({
      ...prevState,
      isDownloadLoading: false,
    }));
  };

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
    }));
    setPageConfig(PAGE_KEYS.CUSTOMERS, {
      filters: pageFilters,
    });
  }, []);

  const getAllCustomers = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingAllCustomer: true,
    }));
    const { data, error } = await SharedService.getCustomerList();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingAllCustomer: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingAllCustomer: false,
      customersList: data.customers || defaultState.customersList,
    }));
  };

  const fetchVehicleTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: true,
      },
    }));
    const { data, error } = await SharedService.getVehicleTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          vehicleType: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      vehicleTypeList:
        data?.rows.filter((vehicle) => vehicle.is_active) ||
        defaultState.vehicleTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: false,
      },
    }));
  };

  const fetchCustomerTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        customerType: true,
      },
    }));
    const { data, error } = await SharedService.getCustomerTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      customerTypeList:
        data?.rows.filter((customer) => customer.is_active) ||
        defaultState.customerTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        customerTypeList: false,
      },
    }));
  };

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList:
        data?.rows.filter((pdxCompany) => pdxCompany.is_active) ||
        defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };

  const handleEditAddressClose = () => {
    setState((prevState) => ({
      ...prevState,
      openAddressDialog: false,
    }));
  };

  const fetchRouteBillFsListt = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        routeBillFs: true,
      },
    }));

    const { data, error } = await SharedService.getRouteBillFsList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          routeBillFs: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      routeBillFsList:
        data?.rows.filter((routeBillFs) => routeBillFs.is_active) ||
        defaultState.routeBillFsList,
      isFetchingList: {
        ...prevState.isFetchingList,
        routeBillFs: false,
      },
    }));
  };

  const fetchRoutePayFsList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        routePayFs: true,
      },
    }));

    const { data, error } = await SharedService.getRoutePayFsList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          routePayFs: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      routePayFsList:
        data?.rows.filter((routePayFs) => routePayFs.is_active) ||
        defaultState.routePayFsList,
      isFetchingList: {
        ...prevState.isFetchingList,
        routePayFs: false,
      },
    }));
  };

  const fetchCustomFuelSurcharge = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        customFuelSurcharge: true,
      },
    }));

    const { data, error } = await SharedService.getFuelSurchargeScheduleNames();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          customFuelSurcharge: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      customFuelSurchargeList:
        [...data?.customFSNames, "None"] ||
        defaultState.customFuelSurchargeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        customFuelSurcharge: false,
      },
    }));
  };

  const fetchList = async (listType = "customers", ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "IC":
        serviceMethod = "getContractorList";
        responseKey = "contractors";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      case "transferRecords":
        serviceMethod = "getTransferScheduleRequests";
        responseKey = "transferRecords";
        break;
      default:
        serviceMethod = "getCustomerList";
        responseKey = "customers";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];

    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || [])
          .filter((branch) => !branch.is_deleted)
          .map((branch = {}) =>
            (branch.routes || [])
              .filter((route) => !route.is_deleted)
              .sort((a, b) => Intl.Collator().compare(a.name, b.name))
          );
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  const fetchLeaveTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingLeaveTypeList: true,
    }));

    const { data, error } = await SharedService.getLeaveTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingLeaveTypeList: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingLeaveTypeList: false,
      forceSubmitReasonList: data?.rows || [],
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openForceSubmitDialog: false,
    }));
  };

  const fetchLocalResources = async () => {
    const response = await Service.getLocalResources();
    setState((prevState) => ({
      ...prevState,
      stateList: (response?.data?.states || defaultState.stateList).sort(
        (a, b) => Intl.Collator().compare(a.name || "", b.name || "")
      ),
      locationList: response?.data?.locations || defaultState.locationList,
    }));
  };

  const getAllLocations = async () => {
    setState((prevState) => ({
      ...prevState,
      isAddressListLoading: true,
    }));
    const { data, error } = await Service.getAllLocations();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isAddressListLoading: false,
      }));
    }
    //Chnaged Code added

    const customerLocation = data?.locations?.customers?.flatMap((location) => {
      if (!location?.addresses) return [];
      return JSON.parse(location.addresses).map((address) => ({
        ...address,
        customer_id: location?.customer_id,
        customer_branch_ids: location?.customer_branch_ids,
      }));
    });

    const branchAddresses = data?.locations.customer_branches?.flatMap(
      (location) => {
        if (!location?.addresses) return [];
        return JSON.parse(location.addresses).map((address) => ({
          ...address,
          customer_branch_id: location?.customer_branch_id,
          default_kam: location?.default_kam,
          industryType: location?.industryType,
          industryTypeValue: location?.industryTypeValue,
          pdx_company_name: location?.pdx_company_name,
        }));
      }
    );

    setState((prevState) => ({
      ...prevState,
      allLocation: customerLocation || defaultState.allLocation,
      allBranchLocation: branchAddresses || defaultState.allBranchLocation,
      isAddressListLoading: false,
    }));
  };

  const deleteLocation = async () => {
    setState((prevState) => ({
      ...prevState,
      isAddressListLoading: true,
    }));
    const { error } = await Service.deleteLocation(
      state.formType === "customer" ? "customer" : "customerBranch",
      state.formType === "customer" ? state.customerID : state.customerBranchID,
      state.deletingEntryIds
    );
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isAddressListLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Address deleted successfully.`);
    setState((prevState) => ({
      ...prevState,
      isAddressListLoading: false,
      deleteAddress: false,
      openAddressDialog: false,
    }));
  };

  const handleAddNewLocation = () => {
    setState((prevState) => ({
      ...prevState,
      openMapsDialog: true,
      subLocation: state.formType === "customer" ? false : true,
    }));
  };

  const handleCreditDialog = (close) => {
    setState((prevState) => ({
      ...prevState,
      creditPopUp: close,
    }));
  };

  const handleFetchCreditUpdate = useCallback(() => {
    fetchEntries(
      state.search,
      state.pageNumber,
      state.pageSize,
      state.filters,
      state.pageFilters
    ).then((data) => {
      updatePagination(
        data?.count,
        { pageNumber: state.pageNumber, pageSize: state.pageSize },
        (pageNumber) => {
          setState((prevState) => ({ ...prevState, pageNumber }));
        }
      );
    });
  }, [
    fetchEntries,
    state.search,
    state.pageNumber,
    state.pageSize,
    state.filters,
    state.pageFilters,
  ]);

  useEffect(() => {
    getAllLocations();
  }, []);

  const fetchCustomerLocation = async () => {
    const { data, error } = await Service.getCustomerLocation();

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      customerlocationList:
        data?.locations || defaultState.customerlocationList,
    }));
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      window.requestAnimationFrame(updateGridLayout);
    }, 100);

    updateGridLayout();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [state.entries]);

  useEffect(() => {
    const handleResize = debounce(() => {
      window.requestAnimationFrame(updateGridLayout);
    }, 100);

    updateGridLayout();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data.location]);

  useEffect(() => {
    !!appData.pendingItems.length &&
      isRoleKAM &&
      setHeaderElements([
        <div className="d-flex f-align-center m-2">
          <Chip
            className={"bg-danger color-white"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                openForceSubmitDialog: true,
              }));
            }}
            label={
              appData.isTabletView ? (
                <Tooltip title="PENDING SCHEDULE DATE(S)" placement="top-start">
                  <Typography variant="body2" className="text-bold">
                    ({appData.pendingItems.length})
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" className="text-bold">
                  PENDING SCHEDULE DATE(S) ({appData.pendingItems.length})
                </Typography>
              )
            }
            variant="outlined"
          />
        </div>,
      ]);

    return () => setHeaderElements([]);
  }, [appData.pendingItems]);

  useEffect(() => {
    if (kamId && isRoleKAM) {
      if (isTransferScheduleEnable) {
        fetchList("transferRecords", "", kamId);
      }
      fetchList("pendingItems", kamId);
    }
  }, [state.selectedKAM]);

  useEffect(() => {
    return () => {
      updateContextData("pendingItems", []);
    };
  }, []);

  useEffect(() => {
    fetchEntries(
      state.search,
      state.pageNumber,
      state.pageSize,
      state.filters,
      state.pageFilters
    ).then((data) => {
      updatePagination(
        data?.count,
        { pageNumber: state.pageNumber, pageSize: state.pageSize },
        (pageNumber) => {
          setState((prevState) => ({ ...prevState, pageNumber }));
        }
      );
    });
  }, [
    fetchEntries,
    state.search,
    state.pageNumber,
    state.pageSize,
    state.filters,
    state.pageFilters,
  ]);

  useEffect(() => {
    window.history.replaceState(null, document.title);
    history.replace({ ...history.location, state: null });
    fetchLeaveTypeList();
    getAllCustomers();
    fetchVehicleTypeList();
    fetchCustomerTypeList();
    fetchPDXCompanyList();
    getPzriceMatrix();
    fetchRoutePayFsList();
    fetchRouteBillFsListt();
    fetchCustomFuelSurcharge();
    fetchLocalResources();
    fetchCustomerLocation();
  }, []);

  useEffect(() => {
    fetchList("users");
  }, [match.params.path]);

  const handleSearch = useCallback((search) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        search,
      }));
      setPageConfig(PAGE_KEYS.CUSTOMERS, {
        search,
      });
      // gridHelper && gridHelper.resetSelection();
    }, 1000);
  }, []);

  const handleMarkActiveInactive = async (
    markActiveInactiveIds,
    updateBaseRoster = false
  ) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const { error } = await Service.markActiveInactive(
      {
        recordId: markActiveInactiveIds.recordId,
        status: !markActiveInactiveIds.status,
        updateBaseRoster,
      },
      markActiveInactiveIds?.type
    );

    if (error) {
      const KAMList =
        error[0]?.code === ERROR_CODES.CUSTOMER_OVERRIDE_BASEROSTER_RECORD
          ? (error[0]?.customer_branches || [])
              .map((branch) => branch.base_roster_kams || [])
              .flat()
          : error[0]?.kamUsers || [];

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        confirmInactive:
          (error[0]?.code === ERROR_CODES.CUSTOMER_OVERRIDE_BASEROSTER_RECORD ||
            error[0]?.code === ERROR_CODES.BRANCH_OVERRIDE_BASEROSTER_RECORD) &&
          KAMList.length,
        overrideKAMList: KAMList,
      }));
      if (
        (error[0]?.code === ERROR_CODES.CUSTOMER_OVERRIDE_BASEROSTER_RECORD ||
          error[0]?.code === ERROR_CODES.BRANCH_OVERRIDE_BASEROSTER_RECORD) &&
        !KAMList.length
      ) {
        handleMarkActiveInactive(state.markActiveInactiveIds, true);
      }
      if (
        error[0]?.code !== ERROR_CODES.CUSTOMER_OVERRIDE_BASEROSTER_RECORD &&
        error[0]?.code !== ERROR_CODES.BRANCH_OVERRIDE_BASEROSTER_RECORD
      ) {
        toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      }
      return;
    }

    if (markActiveInactiveIds?.type === "location") {
      toast.success(
        `${markActiveInactiveIds?.type.replace(
          "location",
          "Branch"
        )} status updated successfully.`
      );
    } else {
      toast.success(
        `${
          markActiveInactiveIds?.type.charAt(0).toUpperCase() +
          markActiveInactiveIds?.type.slice(1)
        } status updated successfully.`
      );
    }

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      markActiveInactiveIds: null,
      confirmInactive: false,
      overrideKAMList: [],
    }));
    fetchEntries(
      state.search,
      state.pageNumber,
      state.pageSize,
      state.filters,
      state.pageFilters
    );
    getAllCustomers();
    fetchVehicleTypeList();
    fetchCustomerTypeList();
    fetchCustomerLocation();
    fetchPDXCompanyList();
    fetchRoutePayFsList();
    fetchRouteBillFsListt();
    fetchCustomFuelSurcharge();
  };

  const showFieldAlert = (field, row, errors) => {
    const alertFieldGroup = [
      {
        group: "bill",
        fields: [
          "default_bill_rate_type",
          "default_bill_quantity",
          "default_bill_rate",
        ],
      },
      {
        group: "pay",
        fields: [
          "default_pay_rate_type",
          "default_pay_quantity",
          "default_pay_rate",
        ],
      },
    ];

    const group = (
      alertFieldGroup.find((item) => item.fields.includes(field)) || {}
    ).group;

    if (!group || !row) {
      return;
    }

    const validations = [
      { type: VALIDATIONS.MIN, value: 0 },
      { type: VALIDATIONS.LIMIT, value: 10.2 },
    ];

    setState((prevState) => ({
      ...prevState,
      fieldAlert: {
        ...prevState.fieldAlert,
        show: true,
        id: row?.id,
        errors: {
          ...errors,
        },
        onChange: (field, value, updatedState = {}, callback) => {
          if (field === "default_bill_rate_type") {
            updatedState = {
              ...updatedState,
              default_bill_quantity:
                value === "Hourly" ? "8" : value === "Daily" ? "1" : "",
              errors: {
                ...updatedState.errors,
                default_bill_quantity: " ",
              },
            };
            callback(updatedState);
          }
          if (field === "default_pay_rate_type") {
            updatedState = {
              ...updatedState,
              default_pay_quantity:
                value === "Hourly" ? "8" : value === "Daily" ? "1" : "",
              errors: {
                ...updatedState.errors,
                default_pay_quantity: " ",
              },
            };
            callback(updatedState);
          }
        },
        updateValidations: (fields = {}, fieldValidatorMap = {}) => {
          let updatedFieldValidatorMap = { ...fieldValidatorMap };

          if (fields["default_bill_rate_type"] === "Hourly") {
            if (
              !updatedFieldValidatorMap["default_bill_quantity"].some(
                (item) => item.id === "max_error"
              )
            ) {
              updatedFieldValidatorMap["default_bill_quantity"] = [
                {
                  type: VALIDATIONS.MAX,
                  value: row?.pdxCompany === APX_COMPANY ? 10000 : 24,
                  id: "max_error",
                },
                ...updatedFieldValidatorMap["default_bill_quantity"],
              ];
            }
          }

          if (fields["default_bill_rate_type"] === "Daily") {
            updatedFieldValidatorMap["default_bill_quantity"] = [
              { type: VALIDATIONS.DAILY_QUANTITY_LIMIT, value: 1.99 },
              ...updatedFieldValidatorMap["default_bill_quantity"],
            ];
          }

          if (fields["default_pay_rate_type"] === "Daily") {
            updatedFieldValidatorMap["default_pay_quantity"] = [
              { type: VALIDATIONS.DAILY_QUANTITY_LIMIT, value: 1.99 },
              ...updatedFieldValidatorMap["default_pay_quantity"],
            ];
          }

          if (fields["default_pay_rate_type"] === "Hourly") {
            if (
              !updatedFieldValidatorMap["default_pay_quantity"].some(
                (item) => item.id === "max_error"
              )
            ) {
              updatedFieldValidatorMap["default_pay_quantity"] = [
                {
                  type: VALIDATIONS.MAX,
                  value: row?.pdxCompany === APX_COMPANY ? 10000 : 24,
                  id: "max_error",
                },
                ...updatedFieldValidatorMap["default_pay_quantity"],
              ];
            }
          }

          return updatedFieldValidatorMap;
        },
        subHeading:
          group === "bill"
            ? `Bill Rate Type, Bill Quantity, and Bill Rate are interrelated fields.`
            : `Pay Rate Type, Pay Quantity, and Pay Rate are interrelated fields.`,
        configs: [
          {
            type: "autoComplete",
            name: `default_${group}_rate_type`,
            label: `${group === "bill" ? "Bill" : "Pay"} Rate Type`,
            value: row[`default_${group}_rate_type`],
            options: RATE_TYPES,
            disableClearable: true,
            required: true,
          },
          {
            type: "textField",
            name: `default_${group}_quantity`,
            label: `${group === "bill" ? "Bill" : "Pay"} Quantity`,
            value: row[`default_${group}_quantity`],
            inputType: "number",
            validations: validations,
          },
          {
            type: "textField",
            name: `default_${group}_rate`,
            label: `${group === "bill" ? "Bill" : "Pay"} Rate`,
            value: row[`default_${group}_rate`],
            inputType: "number",
            inputProps: {
              startAdornment: <InputAdornment>$</InputAdornment>,
            },
            validations: validations,
          },
        ],
      },
    }));
  };

  const handleFieldAlertSubmit = async (id, payload, errors) => {
    if (state.rowBeingEdited || state.rowBeingCopied) {
      setState((prevState) => ({
        ...prevState,
        fieldAlert: defaultState.fieldAlert,
        formAlertFields: {
          ...prevState.formAlertFields,
          ...payload,
          errors: { ...errors },
        },
      }));
    }
  };

  const handleDelete = async (deletingEntry, isConfirmed = false) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const { type, id } = deletingEntry;
    const serviceDetails = {
      customer: {
        method: "deleteCustomer",
        label: "Customer",
      },
      branch: {
        method: "deleteBranch",
        label: "Branch",
      },
      route: {
        method: "deleteRoute",
        label: "Route",
      },
    };
    const { error } = await Service[serviceDetails[type].method](
      id,
      isConfirmed
    );

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        deletingEntry:
          error[0]?.code === ERROR_CODES.CUSTOMER_CANNOT_DELETE
            ? false
            : prevState.deletingEntry,
        customerDeleteConfirmation:
          error[0]?.code === ERROR_CODES.DELETE_CUSTOMER_CHILD,
      }));
      if (error[0]?.code !== ERROR_CODES.DELETE_CUSTOMER_CHILD) {
        // toast.error(Array.isArray(error) ? error[0]?.message : error.message);
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          //deletingEntry: null,
          customerDeleteConfirmation: false,
        }));
      }
      if (error[0]?.code === ERROR_CODES.CAN_DELETE_CUSTOMER) {
        setState((prevState) => ({
          ...prevState,
          openCustomerDeleteDialog: true,
          isLoading: false,
          kamsName: error[0]?.kamsName || [],
        }));
      }
      if (error[0]?.code === ERROR_CODES.CAN_DELETE_BRANCH) {
        setState((prevState) => ({
          ...prevState,
          openBranchDeleteDialog: true,
          isLoading: false,
          kamsName: error[0]?.kamsName || [],
        }));
      }
      if (error[0]?.code === ERROR_CODES.CAN_DELETE_ROUTE) {
        setState((prevState) => ({
          ...prevState,
          openRouteDeleteDialog: true,
          isLoading: false,
          kamsName: error[0]?.kamsName || [],
        }));
      }
      return;
    }

    toast.success(`${serviceDetails[type].label} deleted successfully.`);

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      openCustomerDeleteDialog: false,
      openBranchDeleteDialog: false,
      openRouteDeleteDialog: false,
      deletingEntry: null,
      customerDeleteConfirmation: false,
    }));
    fetchEntries(
      state.search,
      state.pageNumber,
      state.pageSize,
      state.filters,
      state.pageFilters
    );
    getAllCustomers();
    fetchVehicleTypeList();
    fetchCustomerTypeList();
    fetchCustomerLocation();
    fetchPDXCompanyList();
    fetchRoutePayFsList();
    fetchRouteBillFsListt();
    fetchCustomFuelSurcharge();
  };

  const handlePageNumberChange = useCallback((pageNumber) => {
    setPageConfig(PAGE_KEYS.CUSTOMERS, {
      pageNumber,
    });
    setState((prevState) => ({
      ...prevState,
      pageNumber,
    }));
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setPageConfig(PAGE_KEYS.CUSTOMERS, {
      pageSize,
    });
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
    }));
  }, []);

  const moreActions = (row) => {
    return (
      <div className="d-flex f-align-center">
        <Tooltip title="Edit" placement="top-start">
          <EditOutlinedIcon
            className={"ml-2 mr-2 c-pointer"}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                openMapsDialog: row.address_id,
                subLocation: state.formType === "customer" ? false : true,
              }))
            }
          />
        </Tooltip>
        <Tooltip title="Delete" placement="top-start">
          <DeleteForeverOutlinedIcon
            color={"error"}
            className={"ml-2 mr-2 c-pointer"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                deleteAddress: true,
                deletingEntryIds: row?.address_id,
              }));
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const mapColumnConfig =
    // (state.formType === "customer" && [
    //   {
    //     headerClassName: classes.editAddress,
    //     id: "col-edit",
    //     noResize: true,
    //     render: (row) => moreActions(row),
    //   },
    //   {
    //     id: "address",
    //     label: "Address",
    //     fieldName: "address",
    //     render: (row) => {
    //       return (
    //         <Tooltip
    //           title={
    //             `${row?.customerAddress?.street_address}, ${row?.customerAddress?.city}, ${row?.customerAddress?.state}, ${row?.customerAddress?.zip_code}, ${row?.customerAddress?.country}` ??
    //             ""
    //           }
    //           placement="top-start"
    //         >
    //           <Typography variant="body2" noWrap>
    //             {`${row?.customerAddress?.street_address}, ${row?.customerAddress?.city}, ${row?.customerAddress?.state}, ${row?.customerAddress?.zip_code}, ${row?.customerAddress?.country}` ||
    //               "-"}
    //           </Typography>
    //         </Tooltip>
    //       );
    //     },
    //   },
    //   {
    //     id: "phone",
    //     label: "Phone",
    //     field: "phone",
    //     render: (row) => {
    //       return (
    //         <Tooltip
    //           title={row?.customerAddress?.phone ?? ""}
    //           placement="top-start"
    //         >
    //           <Typography variant="body2">
    //             {row?.customerAddress?.phone || "-"}
    //           </Typography>
    //         </Tooltip>
    //       );
    //     },
    //   },
    //   // {
    //   //   id: "type",
    //   //   label: "Type",
    //   //   field: "type",
    //   //   render: (row) => {
    //   //     return (
    //   //       <Tooltip title={row?.type?.label ?? ""} placement="top-start">
    //   //         <Typography variant="body2">{row?.type?.label || "-"}</Typography>
    //   //       </Tooltip>
    //   //     );
    //   //   },
    //   // },
    // ]) ||
    !isRoleKAM && !isRoleSalesVIEW
      ? state.formType === "branch" && [
          {
            headerClassName: classes.editAddress,
            id: "col-edit",
            noResize: true,
            render: (row) => moreActions(row),
          },
          {
            id: "address",
            label: "Address",
            fieldName: "address",
            render: (row) => {
              return (
                <Tooltip
                  title={
                    `${row?.branchAddress?.street_address}, ${row?.branchAddress?.city}, ${row?.branchAddress?.state}, ${row?.branchAddress?.zip_code}, ${row?.branchAddress?.country}` ??
                    ""
                  }
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {`${row?.branchAddress?.street_address}, ${row?.branchAddress?.city}, ${row?.branchAddress?.state}, ${row?.branchAddress?.zip_code}, ${row?.branchAddress?.country}` ||
                      "-"}
                  </Typography>
                </Tooltip>
              );
            },
          },
          {
            id: "phone",
            label: "Phone",
            field: "phone",
            render: (row) => {
              return (
                <Tooltip
                  title={row?.branchAddress?.phone ?? ""}
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {row?.branchAddress?.phone || "-"}
                  </Typography>
                </Tooltip>
              );
            },
          },
          {
            id: "primary_contact",
            label: "Primary Contact Name",
            field: "primary_contact",
            render: (row) => {
              const primaryContact = row?.branchAddress?.primary_contact;
              const modifiedContact =
                primaryContact && primaryContact.length > 0
                  ? primaryContact.length > 10
                    ? `${primaryContact.slice(0, 10)}...`
                    : primaryContact
                  : "-";
              return (
                <Tooltip
                  title={row?.branchAddress?.primary_contact ?? ""}
                  placement="top-start"
                >
                  <Typography variant="body2">
                    {modifiedContact}
                    {/* {row?.branchAddress?.primary_contact || "-"} */}
                  </Typography>
                </Tooltip>
              );
            },
          },
          // {
          //   id: "type",
          //   label: "Type",
          //   field: "type",
          //   render: (row) => {
          //     return (
          //       <Tooltip title={row?.type?.label ?? ""} placement="top-start">
          //         <Typography variant="body2">{row?.type?.label || "-"}</Typography>
          //       </Tooltip>
          //     );
          //   },
          // },
        ]
      : state.formType === "branch" && [
          {
            id: "address",
            label: "Address",
            fieldName: "address",
            render: (row) => {
              return (
                <Tooltip
                  title={
                    `${row?.branchAddress?.street_address}, ${row?.branchAddress?.city}, ${row?.branchAddress?.state}, ${row?.branchAddress?.zip_code}, ${row?.branchAddress?.country}` ??
                    ""
                  }
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {`${row?.branchAddress?.street_address}, ${row?.branchAddress?.city}, ${row?.branchAddress?.state}, ${row?.branchAddress?.zip_code}, ${row?.branchAddress?.country}` ||
                      "-"}
                  </Typography>
                </Tooltip>
              );
            },
          },
          {
            id: "phone",
            label: "Phone",
            field: "phone",
            render: (row) => {
              return (
                <Tooltip
                  title={row?.branchAddress?.phone ?? ""}
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {row?.branchAddress?.phone || "-"}
                  </Typography>
                </Tooltip>
              );
            },
          },
          {
            id: "primary_contact",
            label: "Primary Contact Name",
            field: "primary_contact",
            render: (row) => {
              const primaryContact = row?.branchAddress?.primary_contact;
              const modifiedContact =
                primaryContact && primaryContact.length > 0
                  ? primaryContact.length > 10
                    ? `${primaryContact.slice(0, 10)}...`
                    : primaryContact
                  : "-";
              return (
                <Tooltip
                  title={row?.branchAddress?.primary_contact ?? ""}
                  placement="top-start"
                >
                  <Typography variant="body2">
                    {modifiedContact}
                    {/* {row?.branchAddress?.primary_contact || "-"} */}
                  </Typography>
                </Tooltip>
              );
            },
          },
        ];

  // const locationOPtions = Object.entries(state.customerlocationList)
  //   .map(([state, value]) =>
  //     value.map((item) => ({
  //       label: `${item}-(${state})`,
  //       value: item,
  //     }))
  //   )
  //   .flat()
  //   .sort((a, b) => Intl.Collator().compare(a.label, b.label));

  const columnConfig =
    !isRoleKAM && !isRoleSalesVIEW
      ? [
          [
            {
              headerClassName: classes.colEdit,
              id: "col-edit",
              noResize: true,
              render: (row) => (
                <div className="d-flex f-align-center f-justify-end">
                  <Tooltip title="Edit" placement="top-start">
                    <EditOutlinedIcon
                      className="ml-2 mr-2 c-pointer"
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          rowBeingEdited: row,
                          formType: "customer",
                        }))
                      }
                    />
                  </Tooltip>
                  {hasFeatureAccess(PERMISSION.DELETE_CUSTOMERS) && (
                    <Tooltip title="Delete" placement="top-start">
                      <DeleteForeverOutlinedIcon
                        className="ml-2 mr-2 c-pointer"
                        onClick={() => {
                          const isRoasterPresent = row.customer_branches?.some(
                            (branch) =>
                              branch.routes?.some(
                                (route) => route.is_roaster_present
                              )
                          );
                          setState((prevState) => ({
                            ...prevState,
                            deletingEntry: {
                              type: "customer",
                              id: row.id,
                            },
                            isRoasterPresent,
                          }));
                        }}
                        color="error"
                      />
                    </Tooltip>
                  )}
                  <Tooltip
                    title={`Mark ${row.is_active ? "Inactive" : "Active"}`}
                    placement="top-start"
                  >
                    <Switch
                      size="small"
                      checked={row.is_active}
                      onChange={() => {
                        setState((prevState) => ({
                          ...prevState,
                          markActiveInactiveIds: {
                            recordId: row.id,
                            status: row.is_active,
                            type: "customer",
                          },
                        }));
                      }}
                      color="primary"
                    />
                  </Tooltip>
                  <Tooltip title="New Branch" placement="top-start">
                    <PlaylistAddIcon
                      style={{
                        color: row.is_active ? "#004700" : "rgb(0 0 0 / 38%)",
                      }}
                      className={clsx("ml-2 mr-2 c-pointer", {
                        disabled: !row.is_active,
                      })}
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          isNew: true,
                          formType: "branch",
                          customer_id: row.id,
                          customerBranchEntry: row,
                        }));
                      }}
                    />
                  </Tooltip>
                </div>
              ),
            },
            {
              id: "name",
              label: "Name",
              field: "name",
              canSort: true,
            },
            {
              id: "customerType.value",
              label: "Industry Type",
              field: "customerType.value",
              canSort: true,
              render: (row) => (
                <Tooltip
                  title={row.customerType?.value || ""}
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {row.customerType?.value}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              id: "inv_pymt_terms",
              label: "Terms",
              field: "inv_pymt_terms",
              canSort: true,
              render: (row) => (
                <Tooltip title={row.inv_pymt_terms || ""} placement="top-start">
                  <Typography variant="body2" noWrap>
                    {row.inv_pymt_terms}
                  </Typography>
                </Tooltip>
              ),
            },
          ],
          [
            // {
            //   headerClassName: classes.emptyCell,
            //   id: "",
            //   label: "",
            //   field: "",
            //   render: (row) => (
            //     <Typography variant="body2" noWrap>
            //       {""}
            //     </Typography>
            //   ),
            // },
            {
              headerClassName: classes.colEdit,
              id: "col-edit",
              noResize: true,
              render: (row) => (
                <div className="d-flex f-align-center f-justify-end">
                  <Tooltip title="Edit" placement="top-start">
                    <EditOutlinedIcon
                      className="ml-2 mr-2 c-pointer"
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          rowBeingEdited: row,
                          formType: "branch",
                        }))
                      }
                    />
                  </Tooltip>
                  {isRoleADMIN && (
                    <Tooltip title="Delete" placement="top-start">
                      <DeleteForeverOutlinedIcon
                        className="ml-2 mr-2 c-pointer"
                        onClick={() => {
                          const isRoasterPresent = row.routes?.some(
                            (route) => route.is_roaster_present
                          );

                          setState((prevState) => ({
                            ...prevState,
                            deletingEntry: {
                              type: "branch",
                              id: row.id,
                            },
                            isRoasterPresent,
                          }));
                        }}
                        color="error"
                      />
                    </Tooltip>
                  )}
                  <Tooltip
                    title={`Mark ${row.is_active ? "Inactive" : "Active"}`}
                    placement="top-start"
                  >
                    <Switch
                      disabled={!row.isActiveCustomer}
                      size="small"
                      checked={row.is_active}
                      onChange={() => {
                        setState((prevState) => ({
                          ...prevState,
                          markActiveInactiveIds: {
                            recordId: row.id,
                            status: row.is_active,
                            type: "location",
                          },
                        }));
                      }}
                      color="primary"
                    />
                  </Tooltip>
                  <Tooltip title="New Route" placement="top-start">
                    <PlaylistAddIcon
                      style={{
                        color: row.is_active ? "#004700" : "rgb(0 0 0 / 38%)",
                      }}
                      className={clsx("ml-2 mr-2 c-pointer", {
                        disabled: !row.is_active,
                      })}
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          isNew: true,
                          formType: "route",
                          customer_branch_id: row.id,
                          customerRouteEntry: row,
                        }));
                      }}
                    />
                  </Tooltip>
                </div>
              ),
            },
            {
              id: "pdx_company",
              label: "PDX Company",
              fieldName: "pdx_company",
              render: (row) => (
                <Tooltip
                  title={row.pdxCompany?.value || ""}
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {row.pdxCompany?.value}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              id: "state",
              label: "State",
              field: "state",
              render: (row) => (
                <Typography variant="body2">{row.state?.name}</Typography>
              ),
            },
            {
              id: "location",
              label: "Location",
              field: "location",
            },
            {
              id: "aaUser",
              label: "Office",
              fieldName: "aaUser",
              render: (row) => {
                const tooltip = `${row.aaUser?.name}-(${row.aaUser?.username})`;
                return (
                  <Tooltip title={tooltip} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.aaUser?.name}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "kamUser",
              label: "Default KAM",
              fieldName: "kamUser",
              render: (row) => {
                const tooltip = `${row.kamUser?.name}-(${row.kamUser?.username})`;
                return (
                  <Tooltip title={tooltip} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.kamUser?.name}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "mgrUser",
              label: "Manager",
              fieldName: "mgrUser",
              render: (row) => {
                const tooltip = `${row.mgrUser?.name}-(${row.mgrUser?.username})`;
                return (
                  <Tooltip title={tooltip} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.mgrUser?.name}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "invoice_type",
              label: "Invoice Type",
              fieldName: "invoice_type",
              headerClassName: classes.invoiceTypeHeader,
              render: (row) => {
                const invoice =
                  INVOICE_TYPES.find(
                    (invoice) =>
                      invoice.id === row.invoice_type ||
                      invoice.label === row.invoice_type
                  )?.label || "";
                return (
                  <Tooltip title={invoice} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {invoice}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "email_addresses",
              label: "Email",
              fieldName: "email_addresses",
              headerClassName: classes.invoiceTypeHeader,
              render: (row) => {
                const email =
                  row.email_addresses &&
                  Object.keys(row.email_addresses).length > 0 &&
                  row.email_addresses?.emailAddresses?.length > 0
                    ? row.email_addresses?.emailAddresses?.join(", ")
                    : "";

                return (
                  <Tooltip title={email ?? ""} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {email || "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "credit",
              label: "Credit",
              fieldName: "credit",
              headerClassName: classes.invoiceTypeHeader,
              render: (row) => {
                const credit = row.available_credit || "0.00";
                const isNegative = parseFloat(credit) < 0;
                const color = isNegative ? "red" : "green";

                return (
                  <Tooltip title={`$ ${credit}` ?? ""} placement="top-start">
                    <Typography
                      variant="body2"
                      noWrap
                      style={{
                        fontWeight: "bold",
                        cursor: "pointer",
                        textDecoration: "underline",
                        color,
                      }}
                      onClick={() => {
                        handleCreditDialog(true);
                        setState((prevState) => ({
                          ...prevState,
                          customerBranchID: row.id,
                          creditAmount: row.available_credit,
                        }));
                      }}
                    >
                      {`$ ${credit}`}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "credit_note",
              label: "Credit Notes",
              field: "credit_note",
              render: (row) => {
                const title = row?.credit_note || "-";
                return (
                  <Tooltip title={title} placement="top-start">
                    <Typography variant="body2">{title}</Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "location",
              label: "Address",
              field: "location",
              render: (row) => (
                <div className="c-pointer">
                  {row.addresses === null || row.addresses === "[]" ? (
                    <Tooltip title={"Add Branch Address"} placement="top-start">
                      <LocationOnIcon
                        style={{ color: "#9e9e9e" }}
                        onClick={() => {
                          fetchCustomerAddresstypeList();
                          setState((prevState) => ({
                            ...prevState,
                            openMapsDialog: true,
                            customerBranchID: row.id,
                            customer_name: state.customers.filter(
                              (customer) => customer.id === row.customer_id
                            ),
                            subLocation: true,
                            location: row.location,
                            state: row.state,
                          }));
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={"Edit Branch Address"}
                      placement="top-start"
                    >
                      <LocationOnIcon
                        style={{ color: "#004700" }}
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            openAddressDialog: true,
                            customerBranchID: row.id,
                            customer_name: state.customers.filter(
                              (customer) => customer.id === row.customer_id
                            ),
                            editLocation: state.allBranchLocation?.filter(
                              (location) =>
                                location.customer_branch_id === row.id
                            ),
                            subLocation: true,
                            location: row.location,
                            state: row.state,
                            formType: "branch",
                          }));
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              ),
            },
          ],
          [
            {
              headerClassName: classes.emptyCell,
              id: "",
              label: "",
              field: "",
              render: (row) => (
                <Typography variant="body2" noWrap>
                  {""}
                </Typography>
              ),
            },
            {
              headerClassName: classes.editColumns,
              id: "col-edit",
              noResize: true,
              render: (row) => (
                <div className="d-flex f-align-center f-justify-end">
                  <Tooltip title="Edit" placement="top-start">
                    <EditOutlinedIcon
                      className="ml-4 mr-2 c-pointer"
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          rowBeingEdited: row,
                          formType: "route",
                        }))
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Delete" placement="top-start">
                    <DeleteForeverOutlinedIcon
                      className="ml-2 mr-2 c-pointer"
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          deletingEntry: {
                            type: "route",
                            id: row.id,
                          },
                          isRoasterPresent: row.is_roaster_present,
                        }))
                      }
                      color="error"
                    />
                  </Tooltip>
                  <Tooltip
                    title={`Mark ${row.is_active ? "Inactive" : "Active"}`}
                    placement="top-start"
                  >
                    <Switch
                      // disabled={row.is_active}
                      className="ml-1 mr-1"
                      size="small"
                      checked={row.is_active}
                      onChange={() => {
                        setState((prevState) => ({
                          ...prevState,
                          markActiveInactiveIds: {
                            recordId: row.id,
                            status: row.is_active,
                            type: "route",
                          },
                        }));
                      }}
                      color="primary"
                    />
                  </Tooltip>
                  <Tooltip title="Copy Route" placement="top-start">
                    <FileCopyIcon
                      style={{ color: "#004700" }}
                      className="ml-2 mr-2 c-pointer"
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          rowBeingCopied: row,
                          formType: "route",
                        }))
                      }
                    />
                  </Tooltip>
                </div>
              ),
            },
            {
              headerClassName: classes.routeColumns,
              id: "route_type",
              label: "Route Type",
              field: "route_type",
              render: (row) => {
                const label = row.is_weekday === true ? "Weekday" : "Weekend";

                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "price_type",
              label: "Price Type",
              field: "price_type",
              render: (row) => {
                const label =
                  row?.price_type === null || row?.price_type === "Standard"
                    ? "Standard"
                    : "Price Matrix";
                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "matrix_name",
              label: "Price Matrix Name",
              field: "matrix_name",
              render: (row) => {
                return (
                  <Tooltip
                    title={row?.matrix_name || "-"}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row?.matrix_name || "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "name",
              label: "Route Name",
              field: "name",
              render: (row) => {
                const label = row.name ? `${row.name || ""}` : "-";

                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "default_bill_rate_type",
              label: "Bill Rate Type",
              field: "default_bill_rate_type",
              render: (row) => {
                const label = row.default_bill_rate_type
                  ? `${row.default_bill_rate_type || ""}`
                  : "-";

                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "default_bill_quantity",
              label: "Bill Quantity",
              field: "default_bill_quantity",
              render: (row) => {
                const label = row.default_bill_quantity
                  ? `${row.default_bill_quantity || ""}`
                  : "-";

                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "default_bill_rate",
              label: "Bill Rate",
              field: "default_bill_rate",
              render: (row) => {
                const label = row.default_bill_rate
                  ? `$${row.default_bill_rate || ""}`
                  : "-";

                return (
                  <Tooltip
                    title={row.default_bill_rate || ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "bill_rate_per_extra_mile",
              label: "Bill Rate Per Extra Mile",
              field: "bill_rate_per_extra_mile",
              render: (row) => {
                const label = row.bill_rate_per_extra_mile
                  ? `$${row.bill_rate_per_extra_mile || ""}`
                  : "-";

                return (
                  <Tooltip
                    title={row.bill_rate_per_extra_mile || ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "bill_toll_amount",
              label: "Bill Toll Amount",
              headerClassName: classes.routeColumns,
              field: "bill_toll_amount",
              render: (row) => {
                const tollAmount = row.bill_toll_amount ?? "-";
                return (
                  <Tooltip
                    title={row.bill_toll_amount || "-"}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {tollAmount === "-" ? "-" : `$${row.bill_toll_amount}`}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "default_pay_rate_type",
              label: "Pay Rate Type",
              field: "default_pay_rate_type",
              render: (row) => {
                const label = row.default_pay_rate_type
                  ? `${row.default_pay_rate_type || ""}`
                  : "-";

                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "default_pay_quantity",
              label: "Pay Quantity",
              field: "default_pay_quantity",
              render: (row) => {
                const label = row.default_pay_quantity
                  ? `${row.default_pay_quantity || ""}`
                  : "-";

                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "default_pay_rate",
              label: "Pay Rate",
              field: "default_pay_rate",
              render: (row) => {
                const label = row.default_pay_rate
                  ? `$${row.default_pay_rate || ""}`
                  : "-";

                return (
                  <Tooltip
                    title={row.default_pay_rate || ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "pay_rate_per_extra_mile",
              tooltip: "Pay Rate Per Extra Mile",
              label: "Pay Rate Per Extra Mile",
              field: "pay_rate_per_extra_mile",
              render: (row) => {
                const label = row.pay_rate_per_extra_mile
                  ? `$${row.pay_rate_per_extra_mile || ""}`
                  : "-";

                return (
                  <Tooltip
                    title={row.pay_rate_per_extra_mile || ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "pay_toll_amount",
              label: "Pay Toll Amount",
              headerClassName: classes.routeColumns,
              field: "pay_toll_amount",
              render: (row) => {
                const tollAmount = row.pay_toll_amount ?? "-";
                return (
                  <Tooltip
                    title={row.pay_toll_amount || "-"}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {tollAmount === "-" ? "-" : `$${row.pay_toll_amount}`}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "vehicle_type",
              label: "Vehicle",
              field: "vehicle_type",
              render: (row) => {
                return (
                  <Tooltip
                    title={row.vehicleType?.value || "-"}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.vehicleType?.value || "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "fuel_type",
              label: "Route Fuel Type",
              fieldName: "fuel_type",
              headerClassName: classes.routeColumns,
              render: (row) => {
                return (
                  <Tooltip title={row.fuel_type || "-"} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.fuel_type}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "fs_schedule",
              label: "Route F/S Schedule",
              fieldName: "fs_schedule",
              headerClassName: classes.routeColumns,
              render: (row) => {
                return (
                  <Tooltip title={row.fs_schedule || "-"} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.fs_schedule}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "bill_discount",
              label: "Route Bill F/S%",
              fieldName: "bill_discount",
              headerClassName: classes.routeColumns,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.bill_discount || "-"}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.bill_discount}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "pay_discount",
              label: "Route Pay F/S%",
              fieldName: "pay_discount",
              headerClassName: classes.routeColumns,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.pay_discount || "-"}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.pay_discount}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "profit_percentage",
              label: "Profit Percentage",
              field: "profit_percentage",
              endAdornment: "%",
              headerClassName: classes.routeColumns,
              render: (row) => (
                <Tooltip title={row.profit_percentage} placement="top-start">
                  <Typography
                    variant="body2"
                    noWrap
                    className={clsx({
                      "color-error": row.profit_percentage < 0,
                    })}
                  >
                    {(+row.profit_percentage || 0).toFixed(2)}%
                  </Typography>
                </Tooltip>
              ),
            },
            {
              id: "description",
              label: "Description",
              field: "description",
              headerClassName: classes.routeColumns,
              render: (row) => (
                <Tooltip title={row.description || ""} placement="top-start">
                  <Typography variant="body2" noWrap>
                    {row.description}
                  </Typography>
                </Tooltip>
              ),
            },
          ],
        ]
      : !isRoleSalesVIEW
      ? [
          [
            {
              id: "name",
              label: "Name",
              field: "name",
              canSort: true,
            },
            {
              id: "customerType.value",
              label: "Industry Type",
              field: "customerType.value",
              canSort: true,
              render: (row) => (
                <Tooltip
                  title={row.customerType?.value || ""}
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {row.customerType?.value}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              id: "inv_pymt_terms",
              label: "Terms",
              field: "inv_pymt_terms",
              canSort: true,
              render: (row) => (
                <Tooltip title={row.inv_pymt_terms || ""} placement="top-start">
                  <Typography variant="body2" noWrap>
                    {row.inv_pymt_terms}
                  </Typography>
                </Tooltip>
              ),
            },
          ],
          [
            {
              id: "pdx_company",
              label: "PDX Company",
              fieldName: "pdx_company",
              render: (row) => (
                <Tooltip
                  title={row.pdxCompany?.value || ""}
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {row.pdxCompany?.value}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              id: "state",
              label: "State",
              field: "state",
              render: (row) => (
                <Tooltip title={row.state?.name || ""} placement="top-start">
                  <Typography variant="body2">{row.state?.name}</Typography>
                </Tooltip>
              ),
            },
            {
              id: "location",
              label: "Location",
              field: "location",
            },
            {
              id: "aaUser",
              label: "Office",
              fieldName: "aaUser",
              render: (row) => {
                const tooltip = `${row.aaUser?.name}-(${row.aaUser?.username})`;
                return (
                  <Tooltip title={tooltip} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.aaUser?.name}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "kamUser",
              label: "Default KAM",
              fieldName: "kamUser",
              render: (row) => {
                const tooltip = `${row.kamUser?.name}-(${row.kamUser?.username})`;
                return (
                  <Tooltip title={tooltip} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.kamUser?.name}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "mgrUser",
              label: "Manager",
              fieldName: "mgrUser",
              render: (row) => {
                const tooltip = `${row.mgrUser?.name}-(${row.mgrUser?.username})`;
                return (
                  <Tooltip title={tooltip} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.mgrUser?.name}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "invoice_type",
              label: "Invoice Type",
              fieldName: "invoice_type",
              headerClassName: classes.invoiceTypeHeader,
              render: (row) => {
                const invoice =
                  INVOICE_TYPES.find(
                    (invoice) =>
                      invoice.id === row.invoice_type ||
                      invoice.label === row.invoice_type
                  )?.label || "";
                return (
                  <Tooltip title={invoice} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {invoice}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "email_addresses",
              label: "Email",
              fieldName: "email_addresses",
              headerClassName: classes.invoiceTypeHeader,
              render: (row) => {
                const email =
                  row.email_addresses &&
                  Object.keys(row.email_addresses).length > 0 &&
                  row.email_addresses?.emailAddresses?.length > 0
                    ? row.email_addresses?.emailAddresses?.join(", ")
                    : "";
                return (
                  <Tooltip title={email ?? ""} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {email || "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "credit",
              label: "Credit",
              fieldName: "credit",
              headerClassName: classes.invoiceTypeHeader,
              render: (row) => {
                const credit = row.available_credit || "0.00";
                const isNegative = parseFloat(credit) < 0;
                const color = isNegative ? "red" : "green";

                return (
                  <Tooltip title={`$ ${credit}` ?? ""} placement="top-start">
                    <Typography
                      variant="body2"
                      noWrap
                      style={{
                        fontWeight: "bold",
                        cursor: "pointer",
                        textDecoration: "underline",
                        color,
                      }}
                      onClick={() => {
                        handleCreditDialog(true);
                        setState((prevState) => ({
                          ...prevState,
                          customerBranchID: row.id,
                        }));
                      }}
                    >
                      {`$ ${credit}`}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "credit_note",
              label: "Credit Notes",
              field: "credit_note",
              render: (row) => {
                const title = row?.credit_note || "-";
                return (
                  <Tooltip title={title} placement="top-start">
                    <Typography variant="body2">{title}</Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "location",
              label: "Address",
              field: "location",
              render: (row) => {
                return row.addresses === null || row.addresses === "[]" ? (
                  <LocationOnIcon
                    style={{ color: "#9e9e9e" }}
                    disabled={!isRoleKAM ? false : true}
                  />
                ) : (
                  <div className="c-pointer">
                    <Tooltip
                      title={"View Branch Address"}
                      placement="top-start"
                    >
                      <LocationOnIcon
                        style={{ color: "#004700" }}
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            openAddressDialog: true,
                            customerBranchID: row.id,
                            customer_name: state.customers.filter(
                              (customer) => customer.id === row.customer_id
                            ),
                            editLocation: state.allBranchLocation?.filter(
                              (location) =>
                                location.customer_branch_id === row.id
                            ),
                            subLocation: true,
                            location: row.location,
                            state: row.state,
                            formType: "branch",
                          }));
                        }}
                      />
                    </Tooltip>
                  </div>
                );
              },
            },
            // {
            //   id: "fuel_surcharge_schedule",
            //   label: "F/S Schedule",
            //   fieldName: "fuel_surcharge_schedule",
            //   headerClassName: classes.fuelSurchargeHeader,
            //   render: (row) => {
            //     return (
            //       <Tooltip
            //         title={row.fuel_surcharge_schedule || "-"}
            //         placement="top-start"
            //       >
            //         <Typography variant="body2" noWrap>
            //           {row.fuel_surcharge_schedule}
            //         </Typography>
            //       </Tooltip>
            //     );
            //   },
            // },
            // {
            //   id: "default_fuel_type",
            //   label: "Fuel Type",
            //   fieldName: "default_fuel_type",
            //   headerClassName: classes.fuelTypeHeader,
            //   render: (row) => {
            //     return (
            //       <Tooltip title={row.default_fuel_type || "-"} placement="top-start">
            //         <Typography variant="body2" noWrap>
            //           {row.default_fuel_type}
            //         </Typography>
            //       </Tooltip>
            //     );
            //   },
            // },
            // {
            //   id: "default_bill_discount",
            //   label: "Bill F/S%",
            //   fieldName: "default_bill_discount",
            //   headerClassName: classes.fsReductionHeader,
            //   render: (row) => {
            //     return (
            //       <Tooltip
            //         title={row.default_bill_discount || "-"}
            //         placement="top-start"
            //       >
            //         <Typography variant="body2" noWrap>
            //           {row.default_bill_discount}
            //         </Typography>
            //       </Tooltip>
            //     );
            //   },
            // },
            // {
            //   id: "default_pay_discount",
            //   label: "Pay F/S%",
            //   fieldName: "default_pay_discount",
            //   headerClassName: classes.fsReductionHeader,
            //   render: (row) => {
            //     return (
            //       <Tooltip
            //         title={row.default_pay_discount || "-"}
            //         placement="top-start"
            //       >
            //         <Typography variant="body2" noWrap>
            //           {row.default_pay_discount}
            //         </Typography>
            //       </Tooltip>
            //     );
            //   },
            // },
          ],
          [
            {
              headerClassName: classes.emptyCell,
              id: "",
              label: "",
              field: "",
              render: (row) => (
                <Typography variant="body2" noWrap>
                  {""}
                </Typography>
              ),
            },
            {
              headerClassName: classes.routeColumns,
              id: "route_type",
              label: "Route Type",
              field: "route_type",
              render: (row) => {
                const label = row.is_weekday === true ? "Weekday" : "Weekend";

                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "price_type",
              label: "Price Type",
              field: "price_type",
              render: (row) => {
                const label =
                  row?.price_type === null || row?.price_type === "Standard"
                    ? "Standard"
                    : "Price Matrix";
                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "matrix_name",
              label: "Price Matrix Name",
              field: "matrix_name",
              render: (row) => {
                return (
                  <Tooltip
                    title={row?.matrix_name || "-"}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row?.matrix_name || "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "name",
              label: "Route Name",
              field: "name",
              render: (row) => {
                const label = row.name ? `${row.name || ""}` : "-";

                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "default_pay_rate_type",
              label: "Pay Rate Type",
              field: "default_pay_rate_type",
              render: (row) => {
                const label = row.default_pay_rate_type
                  ? `${row.default_pay_rate_type || ""}`
                  : "-";

                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "default_pay_quantity",
              label: "Pay Quantity",
              field: "default_pay_quantity",
              render: (row) => {
                const label = row.default_pay_quantity
                  ? `${row.default_pay_quantity || ""}`
                  : "-";

                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "default_pay_rate",
              label: "Pay Rate",
              field: "default_pay_rate",
              render: (row) => {
                const label = row.default_pay_rate
                  ? `$${row.default_pay_rate || ""}`
                  : "-";

                return (
                  <Tooltip
                    title={row.default_pay_rate || ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              // headerClassName: classes.extraMilesheader,
              tooltip: "Pay Rate Per Extra Mile",
              id: "pay_rate_per_extra_mile",
              label: "Pay Rate Per...",
              field: "pay_rate_per_extra_mile",
              render: (row) => {
                const label = row.pay_rate_per_extra_mile
                  ? `$${row.pay_rate_per_extra_mile || ""}`
                  : "-";

                return (
                  <Tooltip
                    title={row.pay_rate_per_extra_mile || ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "pay_toll_amount",
              label: "Pay Toll Amount",
              headerClassName: classes.routeColumns,
              field: "pay_toll_amount",
              render: (row) => {
                const tollAmount = row.pay_toll_amount ?? "-";
                return (
                  <Tooltip
                    title={row.pay_toll_amount || "-"}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {tollAmount === "-" ? "-" : `$${row.pay_toll_amount}`}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "vehicle_type",
              label: "Vehicle",
              field: "vehicle_type",
              headerClassName: classes.routeColumns,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.vehicleType?.value || "-"}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.vehicleType?.value || "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "fuel_type",
              label: "Route Fuel Type",
              fieldName: "fuel_type",
              headerClassName: classes.routeColumns,

              render: (row) => {
                return (
                  <Tooltip title={row.fuel_type || "-"} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.fuel_type}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "fs_schedule",
              label: "Route F/S Schedule",
              fieldName: "fs_schedule",
              headerClassName: classes.routeColumns,
              render: (row) => {
                return (
                  <Tooltip title={row.fs_schedule || "-"} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.fs_schedule}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "pay_discount",
              label: "Route Pay F/S%",
              fieldName: "pay_discount",
              headerClassName: classes.routeColumns,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.pay_discount || "-"}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.pay_discount}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "description",
              label: "Description",
              field: "description",
              headerClassName: classes.routeColumns,
              render: (row) => (
                <Tooltip title={row.description || ""} placement="top-start">
                  <Typography variant="body2" noWrap>
                    {row.description}
                  </Typography>
                </Tooltip>
              ),
            },
          ],
        ]
      : [
          [
            {
              headerClassName: classes.colEdit,
              id: "col-edit",
              noResize: true,
              render: (row) => (
                <Tooltip
                  title={`${!row.is_active ? "Inactive" : "Active"}`}
                  placement="top-start"
                  style={{ cursor: "default" }}
                >
                  <Switch
                    className="none-events"
                    size="small"
                    checked={row.is_active}
                    color="primary"
                  />
                </Tooltip>
              ),
            },
            {
              id: "name",
              label: "Name",
              field: "name",
              canSort: true,
            },
            {
              id: "customerType.value",
              label: "Industry Type",
              field: "customerType.value",
              canSort: true,
              render: (row) => (
                <Tooltip
                  title={row.customerType?.value || ""}
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {row.customerType?.value}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              id: "inv_pymt_terms",
              label: "Terms",
              field: "inv_pymt_terms",
              canSort: true,
              render: (row) => (
                <Tooltip title={row.inv_pymt_terms || ""} placement="top-start">
                  <Typography variant="body2" noWrap>
                    {row.inv_pymt_terms}
                  </Typography>
                </Tooltip>
              ),
            },
          ],
          [
            {
              headerClassName: classes.colEdit,
              id: "col-edit",
              noResize: true,
              render: (row) => (
                <Tooltip
                  title={`${!row.is_active ? "Inactive" : "Active"}`}
                  placement="top-start"
                  style={{ cursor: "default" }}
                >
                  <Switch
                    className="none-events"
                    size="small"
                    checked={row.is_active}
                    color="primary"
                  />
                </Tooltip>
              ),
            },
            {
              id: "pdx_company",
              label: "PDX Company",
              fieldName: "pdx_company",
              render: (row) => (
                <Tooltip
                  title={row.pdxCompany?.value || ""}
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {row.pdxCompany?.value}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              id: "state",
              label: "State",
              field: "state",
              render: (row) => (
                <Typography variant="body2">{row.state?.name}</Typography>
              ),
            },
            {
              id: "location",
              label: "Location",
              field: "location",
            },
            {
              id: "aaUser",
              label: "Office",
              fieldName: "aaUser",
              render: (row) => {
                const tooltip = `${row.aaUser?.name}-(${row.aaUser?.username})`;
                return (
                  <Tooltip title={tooltip} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.aaUser?.name}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "kamUser",
              label: "Default KAM",
              fieldName: "kamUser",
              render: (row) => {
                const tooltip = `${row.kamUser?.name}-(${row.kamUser?.username})`;
                return (
                  <Tooltip title={tooltip} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.kamUser?.name}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "mgrUser",
              label: "Manager",
              fieldName: "mgrUser",
              render: (row) => {
                const tooltip = `${row.mgrUser?.name}-(${row.mgrUser?.username})`;
                return (
                  <Tooltip title={tooltip} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.mgrUser?.name}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "invoice_type",
              label: "Invoice Type",
              fieldName: "invoice_type",
              headerClassName: classes.invoiceTypeHeader,
              render: (row) => {
                const invoice =
                  INVOICE_TYPES.find(
                    (invoice) =>
                      invoice.id === row.invoice_type ||
                      invoice.label === row.invoice_type
                  )?.label || "";
                return (
                  <Tooltip title={invoice} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {invoice}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "email_addresses",
              label: "Email",
              fieldName: "email_addresses",
              headerClassName: classes.invoiceTypeHeader,
              render: (row) => {
                const email =
                  row.email_addresses &&
                  Object.keys(row.email_addresses).length > 0 &&
                  row.email_addresses?.emailAddresses?.length > 0
                    ? row.email_addresses?.emailAddresses?.join(", ")
                    : "";
                return (
                  <Tooltip title={email ?? ""} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {email || "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "location",
              label: "Address",
              field: "location",
              render: (row) => {
                return row.addresses === null || row.addresses === "[]" ? (
                  <LocationOnIcon
                    style={{ color: "#9e9e9e" }}
                    disabled={!isRoleKAM ? false : true}
                  />
                ) : (
                  <div className="c-pointer">
                    <Tooltip
                      title={"View Branch Address"}
                      placement="top-start"
                    >
                      <LocationOnIcon
                        style={{ color: "#004700" }}
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            openAddressDialog: true,
                            customerBranchID: row.id,
                            customer_name: state.customers.filter(
                              (customer) => customer.id === row.customer_id
                            ),
                            editLocation: state.allBranchLocation?.filter(
                              (location) =>
                                location.customer_branch_id === row.id
                            ),
                            subLocation: true,
                            location: row.location,
                            state: row.state,
                            formType: "branch",
                          }));
                        }}
                      />
                    </Tooltip>
                  </div>
                );
              },
            },
          ],
          [
            {
              headerClassName: classes.emptyCell,
              id: "",
              label: "",
              field: "",
              render: (row) => (
                <Typography variant="body2" noWrap>
                  {""}
                </Typography>
              ),
            },
            {
              headerClassName: classes.routeColumns,
              id: "route_type",
              label: "Route Type",
              field: "route_type",
              render: (row) => {
                const label = row.is_weekday === true ? "Weekday" : "Weekend";

                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "price_type",
              label: "Price Type",
              field: "price_type",
              render: (row) => {
                const label =
                  row?.price_type === null || row?.price_type === "Standard"
                    ? "Standard"
                    : "Price Matrix";
                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "matrix_name",
              label: "Price Matrix Name",
              field: "matrix_name",
              render: (row) => {
                return (
                  <Tooltip
                    title={row?.matrix_name || "-"}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row?.matrix_name || "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "name",
              label: "Route Name",
              field: "name",
              render: (row) => {
                const label = row.name ? `${row.name || ""}` : "-";

                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "default_bill_rate_type",
              label: "Bill Rate Type",
              field: "default_bill_rate_type",
              render: (row) => {
                const label = row.default_bill_rate_type
                  ? `${row.default_bill_rate_type || ""}`
                  : "-";

                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "default_bill_quantity",
              label: "Bill Quantity",
              field: "default_bill_quantity",
              render: (row) => {
                const label = row.default_bill_quantity
                  ? `${row.default_bill_quantity || ""}`
                  : "-";

                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "default_bill_rate",
              label: "Bill Rate",
              field: "default_bill_rate",
              render: (row) => {
                const label = row.default_bill_rate
                  ? `$${row.default_bill_rate || ""}`
                  : "-";

                return (
                  <Tooltip
                    title={row.default_bill_rate || ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "bill_rate_per_extra_mile",
              label: "Bill Rate Per Extra Mile",
              field: "bill_rate_per_extra_mile",
              render: (row) => {
                const label = row.bill_rate_per_extra_mile
                  ? `$${row.bill_rate_per_extra_mile || ""}`
                  : "-";

                return (
                  <Tooltip
                    title={row.bill_rate_per_extra_mile || ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "bill_toll_amount",
              label: "Bill Toll Amount",
              headerClassName: classes.routeColumns,
              field: "bill_toll_amount",
              render: (row) => {
                const tollAmount = row.bill_toll_amount ?? "-";
                return (
                  <Tooltip
                    title={row.bill_toll_amount || "-"}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {tollAmount === "-" ? "-" : `$${row.bill_toll_amount}`}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "default_pay_rate_type",
              label: "Pay Rate Type",
              field: "default_pay_rate_type",
              render: (row) => {
                const label = row.default_pay_rate_type
                  ? `${row.default_pay_rate_type || ""}`
                  : "-";

                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "default_pay_quantity",
              label: "Pay Quantity",
              field: "default_pay_quantity",
              render: (row) => {
                const label = row.default_pay_quantity
                  ? `${row.default_pay_quantity || ""}`
                  : "-";

                return (
                  <Tooltip title={label} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "default_pay_rate",
              label: "Pay Rate",
              field: "default_pay_rate",
              render: (row) => {
                const label = row.default_pay_rate
                  ? `$${row.default_pay_rate || ""}`
                  : "-";

                return (
                  <Tooltip
                    title={row.default_pay_rate || ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "pay_rate_per_extra_mile",
              tooltip: "Pay Rate Per Extra Mile",
              label: "Pay Rate Per Extra Mile",
              field: "pay_rate_per_extra_mile",
              render: (row) => {
                const label = row.pay_rate_per_extra_mile
                  ? `$${row.pay_rate_per_extra_mile || ""}`
                  : "-";

                return (
                  <Tooltip
                    title={row.pay_rate_per_extra_mile || ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "pay_toll_amount",
              label: "Pay Toll Amount",
              headerClassName: classes.routeColumns,
              field: "pay_toll_amount",
              render: (row) => {
                const tollAmount = row.pay_toll_amount ?? "-";
                return (
                  <Tooltip
                    title={row.pay_toll_amount || "-"}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {tollAmount === "-" ? "-" : `$${row.pay_toll_amount}`}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              headerClassName: classes.routeColumns,
              id: "vehicle_type",
              label: "Vehicle",
              field: "vehicle_type",
              render: (row) => {
                return (
                  <Tooltip
                    title={row.vehicleType?.value || "-"}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.vehicleType?.value || "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "fuel_type",
              label: "Route Fuel Type",
              fieldName: "fuel_type",
              headerClassName: classes.routeColumns,
              render: (row) => {
                return (
                  <Tooltip title={row.fuel_type || "-"} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.fuel_type}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "fs_schedule",
              label: "Route F/S Schedule",
              fieldName: "fs_schedule",
              headerClassName: classes.routeColumns,
              render: (row) => {
                return (
                  <Tooltip title={row.fs_schedule || "-"} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.fs_schedule}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "bill_discount",
              label: "Route Bill F/S%",
              fieldName: "bill_discount",
              headerClassName: classes.routeColumns,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.bill_discount || "-"}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.bill_discount}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "pay_discount",
              label: "Route Pay F/S%",
              fieldName: "pay_discount",
              headerClassName: classes.routeColumns,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.pay_discount || "-"}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.pay_discount}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "profit_percentage",
              label: "Profit Percentage",
              field: "profit_percentage",
              endAdornment: "%",
              headerClassName: classes.routeColumns,
              render: (row) => (
                <Tooltip title={row.profit_percentage} placement="top-start">
                  <Typography
                    variant="body2"
                    noWrap
                    className={clsx({
                      "color-error": row.profit_percentage < 0,
                    })}
                  >
                    {(+row.profit_percentage || 0).toFixed(2)}%
                  </Typography>
                </Tooltip>
              ),
            },
            {
              id: "description",
              label: "Description",
              field: "description",
              headerClassName: classes.routeColumns,
              render: (row) => (
                <Tooltip title={row.description || ""} placement="top-start">
                  <Typography variant="body2" noWrap>
                    {row.description}
                  </Typography>
                </Tooltip>
              ),
            },
          ],
        ];

  const totalAvailableCreditAmount = useCallback(
    (availableCreditAmount = 0) => {
      setState((prevState) => ({
        ...prevState,
        creditAmount: availableCreditAmount,
      }));
    },
    []
  );

  return (
    <div id="numbers-page-wrapper">
      <div className={clsx("mr-5", state.isUpdateLoading && classes.blur)}>
        <div className={clsx("  mr-10", classes.fixedHeader)}>
          <Typography variant="h4" color="primary" className=" ml-2">
            Customers
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/home")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Home
            </Button>
          </Typography>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            {!isRoleSalesVIEW
              ? " View/Edit Customer's information."
              : " View Customer's information."}
          </Typography>
        </div>

        <div className="d-flex f-justify-between f-align-center">
          <div className="d-flex ml-5"></div>
          <div className="d-flex">
            {/* <Autocomplete
            classes={{
              root: classes.locationInput,
              inputRoot: classes.locationInputRoot,
            }}
            style={{ width: "244px" }}
            size="small"
            fullWidth
            value={state.selectedLocation || []}
            multiple
            options={locationOPtions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) =>
              state.isFetchingList.users ? (
                <Skeleton variant="rect" width="100%" height="36px" />
              ) : (
                <TextField {...params} label="Location" variant="outlined" />
              )
            }
            getOptionSelected={(option, value) => option.label === value.label}
            onChange={(evt, value) => {
              setState((prevState) => ({
                ...prevState,
                selectedLocation: value,
                filters: {
                  ...prevState.filters,
                  locations: [
                    {
                      field: "cust_branch_location",
                      type: "=",
                      value: value?.map((item) => item.value),
                    },
                  ],
                },
              }));
              setPageConfig(PAGE_KEYS.CUSTOMERS, {
                locationFilter: {
                  value,
                  locations: [
                    {
                      field: "cust_branch_location",
                      type: "=",
                      value: value?.map((item) => item.value),
                    },
                  ],
                },
              });
            }}
          /> */}
            <div className="d-flex f-align-center">
              <Typography variant="body1">Enter to search by:</Typography>
              <TextField
                type="text"
                variant="outlined"
                className="ml-2"
                size="small"
                // defaultValue={!data?.route_name ? undefined : state.search}
                defaultValue={state.search}
                placeholder="Name,State,Location"
                InputProps={{ endAdornment: <SearchIcon /> }}
                onChange={(evt) =>
                  handleSearch((evt.target.value || "").trim())
                }
              />
            </div>
          </div>
        </div>
        <Paper
          elevation={2}
          className={clsx("p-4 mt-10 h-100", classes.paperSpacing)}
        >
          <div
            className={clsx(
              "d-flex f-align-center f-justify-between pl-4 pr-4",
              classes.actionsWrapper
            )}
          >
            {!isRoleKAM && !isRoleSalesVIEW && (
              <div className="d-flex f-align-center">
                <Button
                  startIcon={<PlaylistAddIcon />}
                  variant="outlined"
                  className="mr-2 mb-3"
                  color="primary"
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      isNew: true,
                      formType: "customer",
                      isButton: true,
                    }))
                  }
                >
                  New Customer
                </Button>
                <Button
                  startIcon={<PlaylistAddIcon />}
                  variant="outlined"
                  color="primary"
                  className="mr-2 mb-3"
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      isNew: true,
                      formType: "branch",
                      isDirect: true,
                      isButton: true,
                    }))
                  }
                >
                  New Branch
                </Button>
                <Button
                  startIcon={<PlaylistAddIcon />}
                  variant="outlined"
                  color="primary"
                  className="mb-3"
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      isNew: true,
                      formType: "route",
                      isDirect: true,
                      isButton: true,
                    }))
                  }
                >
                  New Route
                </Button>
              </div>
            )}
            <div></div>

            <div className="d-flex f-align-center">
              <Button
                variant="contained"
                color="primary"
                className="mr-2 mb-2"
                startIcon={<CloudDownloadIcon />}
                disabled={
                  state.isDownloadLoading ||
                  state.isFetching ||
                  !state.totalEntries ||
                  state.customers?.every((cust) => cust.is_active === false)
                }
                onClick={() => {
                  handleDownload(
                    state.search,
                    state.filters,
                    state.pageFilters
                  );
                }}
              >
                Download
                {state.isDownloadLoading && (
                  <CircularProgress size={24} className={classes.progressBtn} />
                )}
              </Button>
              <div style={{ marginBottom: "11px" }}>
                <Dropdown
                  classes={{
                    paper: clsx("mr-1", classes.tunePaper),
                  }}
                  disablePortal={false}
                  labelEllipses
                  hasEllipses
                  //placement={"top-end"}
                  placement={"bottom-end"}
                  options={[
                    { label: "All", value: null },
                    { label: "Weekdays", value: true },
                    { label: "Weekend", value: false },
                  ]}
                  label={`Route Type: ${
                    state.filters.roasterType.length
                      ? state.filters.roasterType[0]?.value
                        ? "Weekdays"
                        : "Weekend"
                      : "All"
                  }`}
                  onChange={(option) => {
                    setState((prevState) => ({
                      ...prevState,
                      filters: {
                        ...prevState.filters,
                        roasterType:
                          option.value === null
                            ? []
                            : [
                                {
                                  field: "branch_route_type",
                                  type: "=",
                                  value: option.value,
                                },
                              ],
                      },
                      pageNumber: defaultState.pageNumber,
                    }));
                    setPageConfig(PAGE_KEYS.CUSTOMERS, {
                      roasterType:
                        option.value === null
                          ? []
                          : [
                              {
                                field: "branch_route_type",
                                type: "=",
                                value: option.value,
                              },
                            ],
                    });
                  }}
                />
              </div>
              <div style={{ marginBottom: "11px" }}>
                <Dropdown
                  classes={{
                    paper: classes.statusFilterPaper,
                  }}
                  placement="bottom-end"
                  options={STATUS.map(({ label, value }) => ({
                    value,
                    label,
                  }))}
                  labelEllipses
                  hasEllipses
                  label={`Show: ${
                    STATUS.find(
                      (status) =>
                        status.value === state.filters?.status[0]?.value
                    )?.label || "All"
                  }`}
                  onChange={(evt) => {
                    const { value } = evt;
                    setState((prevState) => ({
                      ...prevState,
                      pageNumber: defaultState.pageNumber,
                      filters: {
                        ...prevState.filters,
                        status:
                          value === null
                            ? []
                            : [{ field: "is_active", type: "=", value }],
                      },
                    }));
                    setPageConfig(PAGE_KEYS.CUSTOMERS, {
                      statusUser: {
                        value: evt?.value,
                        status:
                          value === null
                            ? []
                            : [{ field: "is_active", type: "=", value }],
                      },
                    });
                  }}
                />
              </div>
              <Tooltip placement="top-start" title="Filter">
                <IconButton
                  color="primary"
                  className={clsx({
                    "bg-primary": !!state.pageFilters?.length,
                  })}
                  style={{ marginBottom: "12px" }}
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      isFiltering: true,
                    }));
                  }}
                >
                  <FilterListIcon
                    className={clsx({
                      "color-white": !!state.pageFilters?.length,
                    })}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip placement="top-end" title="Reset All Filters">
                <IconButton
                  // disabled={pageConfig === undefined || pageConfig === null}
                  color="primary"
                  className="mb-2"
                  onClick={() => {
                    updatePageConfig("customers");
                    window.location.reload();
                  }}
                >
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <Grid
            columns={columnConfig}
            rows={state.entries.map((entry) => ({
              ...entry,
              className: !entry.is_active && classes.highlightDisable,
              customer_branches: (entry.customer_branches || [])
                .filter((customer_branches) => !customer_branches.is_deleted)
                .map((branch) => ({
                  ...branch,
                  isActiveCustomer: entry.is_active,
                  className: !branch.is_active && classes.highlightDisable,
                })),
            }))}
            customersList={state.customersList}
            customers={state.customers}
            childIdentifier={["customer_branches", "routes"]}
            isLoading={state.isFetching}
            totalRows={state.totalEntries}
            pageSize={state.pageSize}
            pageNumber={state.pageNumber}
            onPageNumberChange={handlePageNumberChange}
            onPageSizeChange={handlePageSizeChange}
            rowEvents={[
              {
                type: "onDoubleClick",
                handler: (row, rowIndex, level = 0) => {
                  if (!isRoleKAM && !isRoleSalesVIEW) {
                    setState((prevState) => {
                      return {
                        ...prevState,
                        rowBeingEdited: row,
                        formType: ["customer", "branch", "route"][level - 1],
                      };
                    });
                  } else {
                    return false;
                  }
                },
              },
            ]}
            classes={{
              gridActions: "f-justify-end",
            }}
            onReady={(instance) => (gridHelper = instance)}
          />
        </Paper>
        {(state.isNew || state.rowBeingEdited || state.rowBeingCopied) && (
          <Form
            isTabletView={appData.isTabletView}
            priceMatrix={state.priceMatrix}
            isDirect={state.isDirect}
            vehicleTypeList={state.vehicleTypeList}
            formType={state.formType}
            fetchLocalResource={fetchLocalResources}
            getPzriceMatrix={getPzriceMatrix}
            isPriceMatrixFetched={state.isPriceMatrixFetched}
            stateList={state.stateList}
            locationList={state.locationList}
            customerlocationList={state.customerlocationList}
            open={
              state.isNew || !!state.rowBeingEdited || !!state.rowBeingCopied
            }
            entry={state.rowBeingEdited}
            copyEntry={state.rowBeingCopied}
            formAlertFields={state.formAlertFields}
            isNew={state.isNew}
            pdxCompanyList={state.pdxCompanyList}
            routePayFsList={state.routePayFsList}
            routeBillFsList={state.routeBillFsList}
            customerTypeList={state.customerTypeList}
            fuelSurchargeTypeList={state.fuelSurchargeTypeList}
            customers={state.customers}
            customersList={state.customersList}
            isButton={state.isButton}
            customFuelSurchargeList={state.customFuelSurchargeList}
            kamUsersOptions={appDataList.kamUsersOptions}
            officeUsersOptions={appDataList.officeUsersOptions}
            managerUsersOptions={appDataList.managerUsersOptions}
            customer_branch_id={state.customer_branch_id}
            customer_id={state.customer_id}
            customerBranchEntry={state.customerBranchEntry}
            customerRouteEntry={state.customerRouteEntry}
            showFieldAlert={showFieldAlert}
            onClose={async (
              isSubmitted = false,
              customer = {},
              branchId,
              isCreating = true
            ) => {
              setState((prevState) => ({
                ...prevState,
                rowBeingEdited: null,
                rowBeingCopied: null,
                isNew: false,
                formType: null,
                customer_branch_id: null,
                customer_id: null,
                formAlertFields: {},
                customerBranchEntry: null,
                customerRouteEntry: null,
                isButton: false,
              }));
              if (typeof isSubmitted === "boolean" && isSubmitted) {
                await (() => {
                  getAllCustomers();
                  fetchVehicleTypeList();
                  fetchCustomerTypeList();
                  fetchCustomerLocation();
                  fetchPDXCompanyList();
                  fetchRoutePayFsList();
                  fetchRouteBillFsListt();
                  fetchCustomFuelSurcharge();
                  fetchEntries(
                    state.search,
                    state.pageNumber,
                    state.pageSize,
                    state.filters,
                    state.pageFilters,
                    isCreating
                  );
                })();
                if (isCreating) {
                  setTimeout(() => {
                    gridHelper && gridHelper.toggleAll(false);
                    gridHelper &&
                      gridHelper.toggle(true, customer?.name, branchId);
                  }, 2000);
                }
              }
            }}
          />
        )}

        {state.isFiltering && (
          <FilterComponent
            isFiltering={state.isFiltering}
            filterConfig={filterConfig}
            appliedFilters={state.pageFilters}
            onFilterApply={handleFilterChange}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                isFiltering: false,
              }));
            }}
          />
        )}
        {!!state.markActiveInactiveIds && (
          <ActionDialog
            classes={{
              confirm: state.markActiveInactiveIds?.status && "bg-primary",
            }}
            open={!!state.markActiveInactiveIds}
            contentText={`Are you sure you want to mark the ${
              state.markActiveInactiveIds.type
            } ${state.markActiveInactiveIds?.status ? "inactive" : "active"}?`}
            onConfirm={() =>
              handleMarkActiveInactive(state.markActiveInactiveIds)
            }
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                markActiveInactiveIds: null,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Mark{" "}
                {state.markActiveInactiveIds?.status ? "inactive" : "active"}
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {state.deletingEntry && (
          <ActionDialog
            classes={{
              confirm: "bg-danger",
              paper: classes.paperSize,
            }}
            open={!!state.deletingEntry}
            contentText={
              <>
                {`Are you sure you want to delete ${state.deletingEntry?.type}?`}
                {state.isRoasterPresent && (
                  <p style={{ color: "red" }}>
                    Note: The{" "}
                    {`${
                      state.deletingEntry?.type === "route"
                        ? ""
                        : state.deletingEntry?.type
                    }`}{" "}
                    route is associated with the base roster.
                  </p>
                )}
              </>
            }
            onConfirm={() => handleDelete(state.deletingEntry)}
            onCancel={() =>
              setState((prevState) => ({ ...prevState, deletingEntry: null }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Delete
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {state.confirmInactive && (
          <ActionDialog
            open={state.confirmInactive}
            contentText={
              <div>
                Base roster(s) exists for this{" "}
                {state.markActiveInactiveIds?.type === "customer"
                  ? "customer"
                  : "branch"}{" "}
                for KAM(s){" "}
                {state.overrideKAMList.map((item) => item).toString()}
                . <br />
                These base roster(s) will also be mark as inactive along with
                {state.markActiveInactiveIds?.type === "customer"
                  ? " customer"
                  : " branch"}
                .
                <br />
                <br />
                Do you want to continue?
              </div>
            }
            onConfirm={() =>
              handleMarkActiveInactive(state.markActiveInactiveIds, true)
            }
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                markActiveInactiveIds: null,
                confirmInactive: false,
                overrideKAMList: [],
              }))
            }
            positiveActionLabel="Yes"
            negativeActionLabel="No"
          />
        )}
        {state.customerDeleteConfirmation && (
          <ActionDialog
            classes={{
              confirm: "bg-danger",
            }}
            open={state.customerDeleteConfirmation}
            contentText={`All the branches and routes will also be deleted for this customer. Are you sure you want to delete?`}
            onConfirm={() => {
              handleDelete(state.deletingEntry, true);
            }}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                customerDeleteConfirmation: false,
                //deletingEntry: null,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Delete
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}

        {state.openCustomerDeleteDialog && (
          <ActionDialog
            classes={{
              confirm: "bg-danger",
            }}
            open={state.openCustomerDeleteDialog}
            contentText={
              <div>
                Customer is present in the base roster of following:{" "}
                {!!state.kamsName.length && (
                  <p className={"text-bold"}>KAMs:</p>
                )}
                <ul>
                  <b>
                    {state.kamsName?.map((kamName) => {
                      return <li>{kamName}</li>;
                    })}
                  </b>
                </ul>
                Deleting customer would delete data from base roster too.
                <br />
                <br />
                Do you want to continue?
              </div>
            }
            onConfirm={() => {
              handleDelete(state.deletingEntry, true);
            }}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                openCustomerDeleteDialog: false,
                //deletingEntry: null,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Delete
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}

        {state.openBranchDeleteDialog && (
          <ActionDialog
            classes={{
              confirm: "bg-danger",
            }}
            open={state.openBranchDeleteDialog}
            contentText={
              <div>
                Customer branch is present in the base roster of following:{" "}
                {!!state.kamsName.length && (
                  <p className={"text-bold"}>KAMs:</p>
                )}
                <ul>
                  <b>
                    {state.kamsName?.map((kamName) => {
                      return <li>{kamName}</li>;
                    })}
                  </b>
                </ul>
                Deleting branch would delete data from base roster too.
                <br />
                <br />
                Do you want to continue?
              </div>
            }
            onConfirm={() => {
              handleDelete(state.deletingEntry, true);
            }}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                openBranchDeleteDialog: false,
                //deletingEntry: null,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Delete
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}

        {state.openRouteDeleteDialog && (
          <ActionDialog
            classes={{
              confirm: "bg-danger",
            }}
            open={state.openRouteDeleteDialog}
            contentText={
              <div>
                Customer route is present in the base roster of following:{" "}
                {!!state.kamsName.length && (
                  <p className={"text-bold"}>KAMs:</p>
                )}
                <ul>
                  <b>
                    {state.kamsName?.map((kamName) => {
                      return <li>{kamName}</li>;
                    })}
                  </b>
                </ul>
                Deleting route would delete data from base roster too.
                <br />
                <br />
                Do you want to continue?
              </div>
            }
            onConfirm={() => {
              handleDelete(state.deletingEntry, true);
            }}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                openRouteDeleteDialog: false,
                deletingEntry: null,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Delete
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}

        {state.fieldAlert.show && (
          <DynamicForm
            open={state.fieldAlert.show}
            title="Update Entry(s)"
            subHeading={state.fieldAlert.subHeading}
            positiveLabel="Save"
            fieldErrors={state.fieldAlert.errors}
            isLoading={state.fieldAlert.isLoading}
            configs={state.fieldAlert.configs}
            updateValidations={state.fieldAlert.updateValidations}
            onChange={state.fieldAlert.onChange}
            onSubmit={(data = {}, errors = {}) => {
              const payload = Object.entries(data).reduce(
                (acc, [key, value]) => {
                  acc[key] =
                    key !== "bill_rate_type" &&
                    key !== "pay_rate_type" &&
                    !data[key]
                      ? "0"
                      : value;
                  return acc;
                },
                {}
              );

              handleFieldAlertSubmit(state.fieldAlert.id, payload, errors);
            }}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                fieldAlert: defaultState.fieldAlert,
              }));
            }}
          />
        )}
        {state.openForceSubmitDialog && (
          <ForceSubmitDialog
            open={state.openForceSubmitDialog}
            pendingItems={appData.pendingItems.map((date, index) => ({
              date,
              id: index,
            }))}
            forceSubmitReasonList={state.forceSubmitReasonList}
            onClose={handleClose}
            kamId={kamId}
            onForceSubmit={() => fetchList("pendingItems", kamId)}
          />
        )}
        {state.openMapsDialog && (
          <Maps
            isTabletView={appData.isTabletView}
            // allLocation={
            //   state.allLocation.length > 0
            //     ? state.formType === "customer"
            //       ? state.allLocation
            //           .filter(
            //             (location) => location.customer_id === state.customerID
            //           )
            //           .filter(
            //             (address) => address.address_id === state.openMapsDialog
            //           )
            //       : state.allBranchLocation
            //           ?.filter(
            //             (location) =>
            //               location.customer_branch_id === state.customerBranchID
            //           )
            //           .filter(
            //             (address) => address.address_id === state.openMapsDialog
            //           )
            //     : []
            // }
            allLocation={state.allBranchLocation
              ?.filter(
                (location) =>
                  location.customer_branch_id === state.customerBranchID
              )
              ?.filter(
                (address) => address.address_id === state.openMapsDialog
              )}
            customerID={state.customerID}
            getAllLocations={getAllLocations}
            addressId={state.openMapsDialog}
            customerBranchID={state.customerBranchID}
            customerName={state.customer_name}
            customerAddressTypeList={state.customerAddressTypeList}
            onClose={async (isSubmitted = false) => {
              setState((prevState) => ({
                ...prevState,
                openMapsDialog: false,
                subLocation: false,
              }));
              if (typeof isSubmitted === "boolean" && isSubmitted) {
                await (() => {
                  getAllLocations();
                  fetchEntries(
                    state.search,
                    state.pageNumber,
                    state.pageSize,
                    state.filters,
                    state.pageFilters
                  );
                })();
              }
            }}
            isSubLocation={state.subLocation}
            location={state.location}
            states={state.state}
          />
        )}
        {state.openAddressDialog && (
          <Dialog
            open={true}
            onClose={handleEditAddressClose}
            classes={{ paper: classes.paper }}
          >
            <Dialog.Title hasClose>
              {state.formType === "customer"
                ? "Customer Address List"
                : "Customer Branch Address List"}
            </Dialog.Title>
            <Dialog.Content>
              <>
                {/* <Button
                  className="mb-4"
                  variant="contained"
                  color="primary"
                  onClick={handleAddNewLocation}
                  disabled
                >
                  Add New Address
                </Button> */}
                <DataGrid
                  columns={mapColumnConfig}
                  rows={
                    state.formType === "customer"
                      ? state.allLocation?.filter(
                          (location) =>
                            location?.customer_id === state.customerID
                        )
                      : state.allBranchLocation?.filter(
                          (location) =>
                            location?.customer_branch_id ===
                            state.customerBranchID
                        )
                  }
                  actionBarConfig={null}
                  isLoading={state.isAddressListLoading}
                  hasSelection={false}
                  hasPagination={false}
                  onReady={(instance) => (gridHelper = instance)}
                  classes={{
                    container: classes.gridPaper,
                    noRecordsImg: classes.noRecordsImg,
                  }}
                />
              </>
            </Dialog.Content>
            <Dialog.Actions>
              <div className="d-flex f-align-center f-justify-end p-4">
                <Button
                  className="ml-2"
                  variant="contained"
                  color="primary"
                  onClick={handleEditAddressClose}
                >
                  Close
                </Button>
              </div>
            </Dialog.Actions>
          </Dialog>
        )}
        {state.deleteAddress && (
          <ActionDialog
            classes={{
              confirm: "bg-danger",
              paper: classes.deleteAddress,
            }}
            open={!!state.deleteAddress}
            contentText={
              <Typography variant="body2" className="mt-2 text-bold">
                Are you sure you want to delete this address?
              </Typography>
            }
            onConfirm={() => {
              deleteLocation();
              getAllLocations().then(() =>
                fetchEntries(
                  state.search,
                  state.pageNumber,
                  state.pageSize,
                  state.filters,
                  state.pageFilters
                )
              );
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                deleteAddress: false,
              }));
            }}
            isConfirmDisabled={state.isAddressListLoading}
            positiveActionLabel={
              <>
                DELETE
                {state.isAddressListLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
            negativeActionLabel={<>CANCEL</>}
          />
        )}
        {state.creditPopUp && (
          <CreditGrid
            open={state.creditPopUp}
            handleClose={handleCreditDialog}
            customerBranchId={state.customerBranchID}
            creditAmount={state.creditAmount}
            handleFetchCreditUpdate={handleFetchCreditUpdate}
            totalAvailableCreditAmount={totalAvailableCreditAmount}
            isRoleKAM={isRoleKAM}
          />
        )}
      </div>
      {state.isUpdateLoading && (
        <LoadingOverlay message={`Updating, please wait...`} />
      )}
    </div>
  );
};

export default ViewCustomers;
