import makeStyles from "@material-ui/core/styles/makeStyles";
import yellow from "@material-ui/core/colors/yellow";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import blue from "@material-ui/core/colors/blue";
import pink from "@material-ui/core/colors/pink";
import grey from "@material-ui/core/colors/grey";
import purple from "@material-ui/core/colors/purple";

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  notification: {
    border: `2px solid ${theme.palette.grey[500]}`,
  },
  datepickerWrapper: {
    minWidth: 164,
    maxWidth: 164,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  icidTitleColor: {
    background: `${yellow[50]}`,
  },
  datepickerWrapperDesktop: {
    minWidth: 190,
  },
  pageLoader: {
    position: "fixed",
    top: "50%",
    left: "50%",
  },
  inactiveICID: {
    background: red[400],
  },
  warningIcon: {
    color: "#d7d718",
    zIndex: 1,
    position: "relative",
    marginTop: "2px",
  },
  transferSchedule: { background: "#fce4ec" },
  warningIconHeight: {
    height: "24px",
    position: "relative",
  },
  exclamationMark: {
    width: "3px",
    height: "9px",
    background: "black",
    position: "absolute",
    top: "11px",
    left: "11px",
  },
  pendingItemsDropdown: {
    maxHeight: 200,
    minWidth: 120,
  },
  highlightUpdate: {
    background: yellow[50],
  },
  paper: {
    minHeight: 400,
  },
  tableHeaderCell: {
    minWidth: 144,
  },
  autocompleteSearchInput: {
    minWidth: 100,
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "&:hover": {
      "& .MuiInput-underline:before": {
        borderBottom: 0,
      },
    },
  },
  autocompletePaper: {
    minWidth: 150,
    maxHeight: 200,
    fontSize: 14,
  },
  autocompleteInput: {
    fontSize: 14,
  },
  highlightDisable: {
    background: theme.palette.grey[200],
  },
  highlightDeleted: {
    background: red[50],
  },
  highlightTransfer: {
    background: red[50],
  },
  clickableDate: {
    background: grey[300],
  },
  //added new
  notCreatedDay: {
    background: red[500],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right, ${grey[200]}, ${red[500]} )`,
    },
  },
  notSubmittedDay: {
    background: yellow[500],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${yellow[500]} )`,
    },
  },
  partialSubmittedDay: {
    background: orange[600],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${orange[600]} )`,
    },
  },
  completeSubmittedDay: {
    background: green[500],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${green[500]} )`,
    },
  },
  forcedSubmitDay: {
    background: blue[500],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${blue[500]} )`,
    },
  },
  underReview: {
    background: purple[300],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${purple[300]} )`,
    },
  },
  bsApproved: {
    background: blue[300],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${blue[300]} )`,
    },
  },
  completeSubmittedAlert: {
    background: `${green[500]}!important`,
  },
  approvedAlert: {
    background: `#bbdefb!important`,
    color: "#000",
  },
  dateLegend: {
    marginTop: 350,
    left: 0,
  },
  dateLegendCopy: {
    marginTop: 25,
    left: 75,
  },
  legendHint: {
    width: 10,
    height: 10,
    border: `1px solid ${theme.palette.divider}`,
  },
  backgroundWhite: {
    background: "white",
  },
  kamNotesTableHeaderCell: { minWidth: "320px" },
  paperSize: {
    width: 316,
  },
  quantityExceedpaperSize: {
    minWidth: "90%",
  },
  paperWidthSm: {
    maxWidth: "98%",
  },
  paperWidthforTransferStatus: {
    width: "27rem",
  },
  customSpace: {
    whiteSpace: "pre",
  },
  calendarDay: {
    borderRadius: 4,
    color: `${theme.palette.common.black}`,
  },
  weekoff: {
    backgroundImage: `linear-gradient(to bottom right,white, ${grey[400]} )`,
  },
  colorNone: {
    color: "transparent !important",
  },
  bgColorNone: {
    background: "transparent !important",
  },
  transfer: {
    background: pink[200],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${pink[200]} )`,
    },
  },
  weekend: {
    //border: "1px solid black",
    //backgroundImage: "linear-gradient(to bottom right, red, yellow)",
  },
  icidTitle: {
    minWidth: "450px",
  },
  tabs: {
    background: "#eeeeee",
    maxWidth: "20.72rem",
    boxShadow:
      "0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    marginTop: "1.5rem",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  icidFlagTitle: {
    minWidth: 320,
    maxWidth: 320,
    paddingLeft: "48px",
  },
  wrapTableTitle: {
    minWidth: 125,
    maxWidth: 125,
  },
  kamDropdownWidth: {
    minWidth: 244,
    maxWidth: 244,
  },
  bottomNavigation: {
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    height: 64,
  },
  fixedHeader: {
    width: "100%",
    height: "75px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
  legendsPosition: {
    marginLeft: "24.375rem",
  },
  isTransferRejected: {
    backgroundColor: "#fff3e0",
  },
  isAccepted: {
    backgroundColor: "#e8f5e9",
  },
  tableHeaderwidth: {
    minWidth: 200,
    maxWidth: 200,
  },
  tableHeaderRouteWidth: {
    minWidth: 260,
    maxWidth: 260,
  },
  tableHeaderColumnwidth: {
    minWidth: 144,
    maxWidth: 144,
  },
  pdxCompanyHeader: {
    minWidth: 200,
    maxWidth: 200,
  },
  tableHeaderNotesWidth: {
    minWidth: 200,
    maxWidth: 200,
  },
  blur: {
    filter: "blur(5px)",
  },
}));

export default useStyles;
