/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import {
  Paper,
  Button,
  Divider,
  ListItemIcon,
  MenuItem,
  Menu,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import Grid from "./grid";
import useStyles from "./style";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { PictureAsPdf as PdfIcon } from "@material-ui/icons";
import DescriptionIcon from "@material-ui/icons/Description";
import { ActionDialog, noop, VALIDATIONS } from "shared";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GenerateCreditMemoPDF from "../generate-credit-pdf";
import { toast } from "react-toastify";
import { validator } from "utils";

const defaultState = {
  data: [],
  anchorEl: null,
  invoiceAmount: 0,
  isDisabled: false,
  errors: {
    invoiceAmount: "",
    credit_description: "",
    credit_quantity: "",
    credit_rate: "",
  },
  total: 0,
  balanceCredit: 0,
  pdfUrl: "",
  pdfViewerDialog: false,
  finalizeInvoiceData: false,
  deletingrow: null,
  finalizedData: null,
  deletingCreditMemo: false,
  selectedRows: {},
};

const GridContainer = ({
  columnConfig = [],
  rows = [],
  routeList = [],
  isRowBeingEditing = {},
  isLoading = false,
  isOverPayment = false,
  isPaymentAssociated = false,
  isFinalizeLoading = false,
  isDeleting = false,
  viewOnlyCreditMemo = false,
  isCreditMemoExist = false,
  isFieldChangedRef,
  creditNo = "",
  creditDate = "",
  customerNotes = "",
  pdxCompany = "",
  customerName = "",
  branch = "",
  primary_contact = "",
  streetAddress = "",
  stateName = "",
  country = "",
  fromDate = "",
  toDate = "",
  city = "",
  zipCode = "",
  branchAddress = "",
  status = null,
  propSetState,
  prevLocationRef,
  handleCreditMemo = noop,
  handleFinalizeCreditMemo = noop,
  deleteCreditMemo = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    const defaultRows = [
      {
        id: Date.now().toString(),
        credit_description: "",
        credit_quantity: -1,
        credit_rate: 0,
        credit_amount: 0,
        position: 1,
        isNew: true,
      },
      {
        id: (Date.now() + 1).toString(),
        credit_description: "",
        credit_quantity: -1,
        credit_rate: 0,
        credit_amount: 0,
        position: 2,
        isNew: true,
      },
    ];

    setState((prevState) => ({
      ...prevState,
      data:
        rows.length > 0
          ? rows.map((row) => ({
              ...row,
              route: row.route_id
                ? {
                    id: row.route_id,
                    name: row.route_name,
                  }
                : null,
            }))
          : defaultRows,
      invoiceAmount:
        isRowBeingEditing?.invoice_amount || defaultState.invoiceAmount,
    }));
  }, [rows]);

  const handleGeneratePDF = (value) => {
    const updatedData = state.data.map((item) => {
      if (item.route && item.route.name) {
        return {
          ...item,
          credit_description: `${item.route.name} - ${
            item.credit_description || ""
          }`,
        };
      }
      return item;
    });
    const creditMemoData = {
      creditMemoDetails: updatedData,
      creditNo: creditNo,
      creditDate: creditDate,
      customerNotes: customerNotes,
      pdxCompany: pdxCompany,
      status: status,
      address: {
        customerName: customerName,
        branch: branch,
        primary_contact: primary_contact,
        streetAddress: streetAddress,
        city: city,
        state: stateName,
        zipCode: zipCode,
        country: country,
      },
      invoices: state.invoiceAmount,
      isPreview: value,
      fromDate: fromDate,
      toDate: toDate,
    };
    if (!value) {
      GenerateCreditMemoPDF(creditMemoData);
    } else {
      const blob = GenerateCreditMemoPDF(creditMemoData);
      setState((prevState) => ({
        ...prevState,
        pdfUrl: blob,
        pdfViewerDialog: true,
      }));
    }
  };

  const handleUpdateValues = (total, balanceCredit) => {
    setState((prevState) => ({
      ...prevState,
      total,
      balanceCredit,
    }));
  };

  const handleViewerClose = () => {
    setState((prevState) => ({ ...prevState, pdfViewerDialog: false }));
  };

  const handleMenuOpen = (event) => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: event.currentTarget,
    }));
  };

  const handleMenuClose = () => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: defaultState.anchorEl,
    }));
  };

  const handleDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;
      isFieldChangedRef.current = true;
      const items = Array.from(state.data);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const updatedData = items.map((row, index) => ({
        ...row,
        position: index + 1,
      }));

      setState((prevState) => ({ ...prevState, data: updatedData }));
    },
    [state.data]
  );

  const handleAddRow = useCallback(() => {
    const newRow = {
      id: Date.now().toString(),
      credit_description: "",
      credit_quantity: -1,
      credit_rate: 0,
      credit_amount: 0,
      isNew: true,
      position: state.data.length + 1,
    };

    setState((prevState) => ({
      ...prevState,
      data: [...prevState.data, newRow],
    }));
  }, [state.data]);

  const handleDeleteRow = useCallback((row) => {
    setState((prevState) => ({
      ...prevState,
      deletingrow: row,
    }));
  }, []);

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      invoiceAmount: [{ type: VALIDATIONS.INVOICE_LIMIT, value: 8.2 }],
      credit_description: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      credit_quantity: [
        { type: VALIDATIONS.NEGATIVE_NUMBER, value: -1 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      credit_rate: [{ type: VALIDATIONS.LIMIT, value: 10.2 }],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = useCallback((evt, field, id) => {
    isFieldChangedRef.current = true;
    const fieldName = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    const type = evt.currentTarget?.type || evt.target?.type;

    let errorMessage =
      fieldName === "invoiceAmount"
        ? validate(fieldName, value) || ""
        : validate(field, value) || "";

    if (type === "number" && value.indexOf(".") !== -1) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((+value + Number.EPSILON) * 100) / 100;
        value = value.toFixed(2);
      }
    }

    if (type === "number" && value?.length > 1 && value.indexOf(".") === -1) {
      value = value?.replace(/^0+/, "");
    }

    if (field === "route") {
      setState((prevState) => {
        const updatedData = prevState.data.map((row) => {
          if (row.id === id) {
            const updatedRow = { ...row, route: value };

            const quantity = updatedRow.route.default_bill_quantity || "";
            const rate = updatedRow.route.default_bill_rate || "";

            updatedRow.credit_quantity = quantity
              ? -quantity
              : row.credit_quantity;
            updatedRow.credit_rate = rate || row.credit_rate;
            updatedRow.credit_amount = (
              (-quantity || -1) * (rate || 0)
            ).toFixed(2);

            return updatedRow;
          }
          return row;
        });

        return {
          ...prevState,
          data: updatedData,
          errors: {
            ...prevState.errors,
            [id]: {
              ...(prevState.errors[id] || {}),
              credit_quantity:
                validate(
                  "credit_quantity",
                  updatedData.find((row) => row.id === id)?.credit_quantity
                ) || "",
              credit_rate:
                validate(
                  "credit_rate",
                  updatedData.find((row) => row.id === id)?.credit_rate
                ) || "",
            },
          },
        };
      });
    }

    setState((prevState) => ({
      ...prevState,
      [fieldName]: value,
      data: prevState.data.map((row) => {
        if (row.id === id) {
          const updatedRow = { ...row, [field]: value };
          if (field === "credit_quantity" || field === "credit_rate") {
            updatedRow.credit_amount = (
              (updatedRow.credit_quantity || -1) * (updatedRow.credit_rate || 0)
            ).toFixed(2);
          }
          return updatedRow;
        }
        return row;
      }),
      errors: {
        ...prevState.errors,
        [fieldName]: errorMessage,
        [id]: {
          ...prevState.errors[id],
          [field]: errorMessage,
        },
      },
    }));
  }, []);

  useEffect(() => {
    setState((prevState) => {
      const updatedErrors = { ...prevState.errors };

      prevState.data.forEach((row) => {
        const quantityError = validate("credit_quantity", row.credit_quantity);
        const rateError = validate("credit_rate", row.credit_rate);

        if (!quantityError) {
          delete updatedErrors[row.id]?.credit_quantity;
        } else {
          updatedErrors[row.id] = {
            ...(updatedErrors[row.id] || {}),
            credit_quantity: quantityError,
          };
        }

        if (!rateError) {
          delete updatedErrors[row.id]?.credit_rate;
        } else {
          updatedErrors[row.id] = {
            ...(updatedErrors[row.id] || {}),
            credit_rate: rateError,
          };
        }
      });

      return {
        ...prevState,
        errors: updatedErrors,
      };
    });
  }, [state.data]);

  const isSaveDisabled =
    isRowBeingEditing?.is_sent_to_customer ||
    validate() ||
    !branch ||
    state.data.length === 0 ||
    state.data.some(
      (row) => !row.credit_description || row.credit_description?.trim() === ""
    );

  useEffect(() => {
    const hasErrors = Object.values(state.errors)?.some((errorObj) => {
      if (typeof errorObj === "object" && errorObj !== null) {
        return Object.values(errorObj).some((error) => error !== "");
      }
      return false;
    });
    setState((prevState) => ({
      ...prevState,
      isDisabled: hasErrors,
    }));
  }, [state.errors]);

  const handleSelectionChange = (rowId) => {
    isFieldChangedRef.current = true;
    setState((prevState) => {
      const isChecked = !prevState.selectedRows[rowId];

      const updatedRow = {
        id: rowId,
        route: isChecked
          ? prevState.data.find((row) => row.id === rowId)?.route
          : null,
      };

      return {
        ...prevState,
        selectedRows: {
          ...prevState.selectedRows,
          [rowId]: isChecked,
        },
        data: prevState.data.map((row) =>
          row.id === rowId
            ? { ...row, route: updatedRow.route, isChecked: true }
            : row
        ),
      };
    });
  };

  useEffect(() => {
    if (prevLocationRef.current) {
      setState((prevState) => {
        return {
          ...prevState,
          selectedRows: defaultState.selectedRows,
        };
      });

      if (Array.isArray(state.data)) {
        const updatedTableData = state.data.map((row) => ({
          ...row,
          route: null,
        }));

        setState((prevState) => ({
          ...prevState,
          data: updatedTableData,
        }));
      }
    }

    prevLocationRef.current = false;
  }, [prevLocationRef.current, state.data]);

  const updateData = useCallback((data) => {
    return data.map((item) => {
      if (item.route && item.route.name) {
        const { isChecked, route, ...rest } = item;
        return {
          ...rest,
          route_id: route.id,
          route_name: route.name,
          manual_entry: false,
        };
      } else {
        const { isChecked, route, route_name, route_id, ...rest } = item;
        return {
          ...rest,
          manual_entry: true,
          route_name: null,
          route_id: null,
        };
      }
    });
  }, []);

  useEffect(() => {
    const initialSelectedRows = state.data.reduce((acc, row) => {
      if (row.route_id && !row.isChecked) {
        acc[row.id] = true;
      }
      return acc;
    }, {});

    setState((prevState) => ({
      ...prevState,
      selectedRows: {
        ...prevState.selectedRows,
        ...initialSelectedRows,
      },
    }));
  }, [state.data]);

  return (
    <Paper className={clsx("m-2", classes.paper)}>
      <Grid
        columnConfig={columnConfig}
        data={state.data}
        selectedRows={state.selectedRows}
        routeList={routeList}
        viewOnlyCreditMemo={viewOnlyCreditMemo}
        onDragEnd={handleDragEnd}
        onDeleteRow={handleDeleteRow}
        handleFieldChange={handleFieldChange}
        handleSelectionChange={handleSelectionChange}
        onUpdateValues={handleUpdateValues}
        isOverPayment={isOverPayment}
        isPaymentAssociated={isPaymentAssociated}
        invoiceAmount={state.invoiceAmount}
        prevLocationRef={prevLocationRef}
        status={
          isRowBeingEditing.status === "pending-approval" ||
          isRowBeingEditing.status === "approved"
        }
        isEmailSentToCustomer={isRowBeingEditing?.is_sent_to_customer}
        errors={state.errors}
      />
      <Divider className="mt-2" />
      <div className="d-flex f-align-center f-justify-between">
        <div>
          {isCreditMemoExist &&
            isRowBeingEditing.status === "draft" &&
            !isOverPayment &&
            !viewOnlyCreditMemo &&
            !isPaymentAssociated && (
              <Button
                startIcon={<DeleteForeverOutlinedIcon color="error" />}
                classes={{
                  root: "border-error ml-4",
                  label: "color-error",
                }}
                variant="outlined"
                disabled={
                  isRowBeingEditing?.is_sent_to_customer || isOverPayment
                }
                onClick={() => {
                  if (!!isRowBeingEditing?.payment_id) {
                    return toast.error(
                      `Deletion is not allowed for credit memo because a payment is associated with it.`
                    );
                  }
                  setState((prevState) => ({
                    ...prevState,
                    deletingCreditMemo: true,
                  }));
                }}
              >
                Delete
              </Button>
            )}
        </div>
        <div className="d-flex f-align-center f-justify-between">
          {!viewOnlyCreditMemo && (
            <Button
              onClick={handleAddRow}
              variant="contained"
              color="primary"
              disabled={
                isRowBeingEditing?.is_sent_to_customer ||
                !branch ||
                isRowBeingEditing.status === "pending-approval" ||
                isRowBeingEditing.status === "approved" ||
                isOverPayment ||
                isPaymentAssociated
              }
              className="mt-4 mb-4 mr-4"
            >
              Add Row
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            className="mt-4 mb-4 mr-4"
            startIcon={<DescriptionIcon />}
            onClick={handleMenuOpen}
            disabled={!branch || state.data.length === 0}
          >
            View/Download
            {state.isRefresh && (
              <CircularProgress size={24} className="p-absolute progress-btn" />
            )}
          </Button>
          <Menu
            anchorEl={state.anchorEl}
            open={Boolean(state.anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                handleGeneratePDF(true);
                setState((prevState) => ({
                  ...prevState,
                  anchorEl: defaultState.anchorEl,
                }));
              }}
            >
              <ListItemIcon>
                <VisibilityIcon />
              </ListItemIcon>
              Preview
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleGeneratePDF(false);
                setState((prevState) => ({
                  ...prevState,
                  anchorEl: defaultState.anchorEl,
                }));
              }}
            >
              <ListItemIcon>
                <PdfIcon />
              </ListItemIcon>
              Download
            </MenuItem>
          </Menu>
          {!viewOnlyCreditMemo && (
            <Button
              variant="contained"
              color="primary"
              className="mt-4 mb-4 mr-4"
              disabled={
                isSaveDisabled ||
                state.isDisabled ||
                !isFieldChangedRef.current ||
                isRowBeingEditing.status === "pending-approval" ||
                isRowBeingEditing.status === "approved"
              }
              onClick={() => {
                if (!branchAddress) {
                  propSetState((prevState) => ({
                    ...prevState,
                    isBillToEmpty: true,
                  }));
                  return toast.error(
                    `Please add the "Address" information before saving the credit memo.`
                  );
                }
                propSetState((prevState) => ({
                  ...prevState,
                  isBillToEmpty: false,
                }));

                const normalizedData = state.data.map((item) => ({
                  ...item,
                  credit_quantity:
                    item.credit_quantity === "" ? -1 : item.credit_quantity,
                  credit_rate: item.credit_rate === "" ? 0 : item.credit_rate,
                }));
                const updatedData = updateData(normalizedData);

                const data = {
                  invoice_amount: state.invoiceAmount,
                  total_amount: state.total,
                  balance_credit_amount: state.balanceCredit,
                  creditMemoItemRecords: updatedData,
                };
                if (isOverPayment) {
                  setState((prevState) => ({
                    ...prevState,
                    finalizedData: data,
                    finalizeInvoiceData: true,
                  }));
                } else {
                  handleCreditMemo(data);
                }
              }}
            >
              {isOverPayment ? "Finalize Credit Memo" : "Save"}
              {isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </Button>
          )}
          {!isOverPayment && !viewOnlyCreditMemo && (
            <Button
              variant="contained"
              color="primary"
              className="mt-4 mb-4 mr-4"
              disabled={
                isFinalizeLoading ||
                isRowBeingEditing.status === "pending-approval" ||
                isRowBeingEditing.status === "approved" ||
                (isCreditMemoExist && !isFieldChangedRef.current
                  ? false
                  : true) ||
                state.data.some(
                  (row) =>
                    !row.credit_description ||
                    row.credit_description?.trim() === ""
                )
              }
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  finalizeInvoiceData: true,
                }))
              }
            >
              Finalize Credit Memo
              {isFinalizeLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </Button>
          )}
        </div>
      </div>
      {state.pdfViewerDialog && (
        <Dialog
          onClose={handleViewerClose}
          aria-labelledby="customized-dialog-title"
          open={state.pdfViewerDialog}
          classes={{ paper: classes.paperIframe }}
        >
          <DialogContent dividers>
            <iframe
              type="application/pdf"
              src={state.pdfUrl}
              title="Invoice PDF"
              width="1150px"
              height="700px"
              style={{ border: "none" }}
            ></iframe>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleViewerClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {state.deletingrow && (
        <ActionDialog
          classes={{
            confirm: "bg-danger",
            paper: classes.paperSize,
          }}
          open={!!state.deletingrow}
          contentText="Are you sure you want to delete?"
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              deletingrow: null,
            }));
            const updatedErrors = { ...state.errors };
            const errorKey = Object.keys(updatedErrors).find(
              (key) => key === state.deletingrow?.id?.toString()
            );
            if (errorKey) {
              delete updatedErrors[errorKey];
            }
            if (state.deletingrow?.isNew) {
              setState((prevState) => {
                const updatedData = prevState.data
                  .filter((row) => row.id !== state.deletingrow.id)
                  .map((row, index) => ({ ...row, position: index + 1 }));
                return {
                  ...prevState,
                  data: updatedData,
                  errors: updatedErrors,
                };
              });
              toast.success("Deleted successfully.");
            } else {
              const updatedData = updateData(state.data);
              const data = {
                invoice_amount: state.invoiceAmount,
                total_amount: state.total,
                balance_credit_amount: state.balanceCredit,
                creditMemoItemRecords: updatedData,
                creditMemoItemsForDeletion: [state.deletingrow.id],
              };
              const isDeleting = true;
              handleCreditMemo(data, isDeleting);
              setState((prevState) => {
                return {
                  ...prevState,
                  errors: updatedErrors,
                };
              });
            }
          }}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              deletingrow: null,
            }))
          }
          isConfirmDisabled={isLoading}
          positiveActionLabel="Delete"
        />
      )}
      {state.deletingCreditMemo && (
        <ActionDialog
          classes={{
            confirm: "bg-danger",
            paper: classes.paperSize,
          }}
          open={!!state.deletingCreditMemo}
          contentText="Are you sure you want to delete?"
          onConfirm={() => {
            deleteCreditMemo(isRowBeingEditing?.id, () => {
              setState((prevState) => ({
                ...prevState,
                deletingCreditMemo: false,
              }));
            });
          }}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              deletingCreditMemo: false,
            }))
          }
          isConfirmDisabled={isDeleting}
          positiveActionLabel={
            <>
              Delete
              {isDeleting && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
      {state.finalizeInvoiceData && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.finalizeInvoiceData}
          contentText={
            <Typography variant="body2" color="primary" className=" ml-2">
              {`Are you sure you want to finalize the credit memo?\n After this you cannot make any changes.`}
            </Typography>
          }
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              finalizeInvoiceData: false,
            }));
            if (isOverPayment) {
              handleCreditMemo(state.finalizedData);
            } else {
              handleFinalizeCreditMemo(isRowBeingEditing.id);
            }
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              finalizeInvoiceData: false,
            }));
          }}
          positiveActionLabel="Save"
          negativeActionLabel="Cancel"
        />
      )}
    </Paper>
  );
};

export default GridContainer;
