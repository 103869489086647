/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import useStyles from "./style";
import {
  ActionDialog,
  Dropdown,
  FilterComponent,
  Grid,
} from "shared/components";
import clsx from "clsx";
import { toast } from "react-toastify";
import {
  debounce,
  getUTCDateString,
  queryStringBuilderNew,
  setPageConfig,
  updateGridLayout,
  updatePageConfig,
  updatePagination,
} from "utils";
import { noop, PAGE_KEYS } from "shared";
import TuneIcon from "@material-ui/icons/Tune";
import { CREDIT_MEMO_STATUS } from "modules/shared/constants";
import FilterListIcon from "@material-ui/icons/FilterList";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import GenerateCreditMemoPDF from "../credit-memo/generate-credit-pdf";
import Service from "../service";

let gridHelper = null;

const CreditMemoGrid = ({
  defaultState = {},
  state = {},
  setState = noop,
  filters = {},
  dateFilterType,
  setInitialFetch = noop,
}) => {
  const classes = useStyles();

  const getCreditMemo = async (row, preview) => {
    setState((prevState) => ({ ...prevState, isCreditMemoLoading: true }));

    let queryString = `?filter[where][id]=${row?.id}`;

    const { data, error } = await Service.getCreditMemo(queryString);

    if (error) {
      setState((prevState) => ({ ...prevState, isCreditMemoLoading: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    const credit = data?.rows[0];

    if (!credit) {
      setState((prevState) => ({ ...prevState, isCreditMemoLoading: false }));
      return null;
    }

    const updatedData = credit.CreditMemoItemRecords.map((item) => {
      if (item.route_name && item.route_id) {
        return {
          ...item,
          credit_description: `${item.route_name} - ${
            item.credit_description || ""
          }`,
        };
      }
      return item;
    });

    const blob = GenerateCreditMemoPDF({
      creditMemoDetails: updatedData,
      creditNo: credit.credit_no,
      creditDate: getUTCDateString(credit.credit_memo_date),
      customerNotes: credit.customer_notes,
      pdxCompany: credit.pdx_company,
      status: credit.status,
      address: {
        customerName: credit.customer_branch.customer?.name,
        branch: credit.customer_branch.location,
        primary_contact: credit.bill_to.primary_contact,
        streetAddress: credit.bill_to.street_address,
        city: credit.bill_to.city,
        state: credit.bill_to.state,
        zipCode: credit.bill_to.zip_code,
        country: credit.bill_to.country,
      },
      invoices: credit.invoice_amount,
      fromDate: getUTCDateString(credit.from_date),
      toDate: getUTCDateString(credit.to_date),
      isPreview: preview,
    });

    setState((prevState) => ({
      ...prevState,
      isCreditMemoLoading: false,
      pdfUrl: blob,
      entry: credit || defaultState.entry,
    }));
    return credit;
  };

  const fetchEntries = useCallback(
    async (filters, pageSize, pageNumber, order, orderBy, pageFilters = []) => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const sortObj = { field: orderBy, order };

      let filtersArr = [
        ...pageFilters.map((filter) => ({
          ...filter,
          value: filter?.value?.value || filter?.value,
        })),
      ];

      if (filters.customer.length && filters.customer[0].value) {
        filtersArr = [...filtersArr, ...filters.customer];
      }

      if (filters.pdxCompany.length && filters.pdxCompany[0].value) {
        filtersArr = [...filtersArr, ...filters.pdxCompany];
      }
      if (
        filters.selectedLocation.length &&
        filters.selectedLocation[0].value
      ) {
        filtersArr = [...filtersArr, ...filters.selectedLocation];
      }
      filtersArr = [
        ...filtersArr,
        {
          field: "status",
          type: "=",
          value: "pending-approval",
        },
      ];

      let queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        [],
        filtersArr,
        sortObj
      );

      if (
        dateFilterType === "single" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        queryString += `&filter[where][credit_memo_date]=${filters.startDate[0].value}`;
      }
      if (
        dateFilterType === "multiple" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        queryString += `&filter[where][credit_memo_date][gte]=${filters.startDate[0].value}`;
      }
      if (
        dateFilterType === "multiple" &&
        filters.endDate.length &&
        (filters.endDate[0].value || filters.endDate)
      ) {
        queryString += `&filter[where][credit_memo_date][lte]=${
          filters.endDate[0].value || filters.endDate
        }`;
      }
      queryString += `&is_deleted=false`;
      const { data, error } = await Service.getCreditMemo(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          selectedRows: defaultState.selectedRows,
        }));
        gridHelper && gridHelper.resetSelection();
        return toast.error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        entries: data.rows || defaultState.entries,
        selectedRows: defaultState.selectedRows,
        totalEntries: data.count,
        hasFetched: true,
      }));
      gridHelper && gridHelper.resetSelection();
      return data;
    },
    [dateFilterType]
  );

  const filterConfig = useMemo(
    () => [
      {
        field: "total_amount",
        fieldToDisplay: "Total Amount",
        operatorType: "number",
      },
      {
        field: "invoice_amount",
        fieldToDisplay: "Invoice Amount",
        operatorType: "number",
      },
      {
        field: "balance_credit_amount",
        fieldToDisplay: "Balance Credit Amount",
        operatorType: "number",
      },
    ],
    []
  );

  useEffect(() => {
    const handleResize = debounce(() => {
      window.requestAnimationFrame(updateGridLayout);
    }, 100);

    updateGridLayout();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [state.entries]);

  const handlePageNumberChange = useCallback((pageNumber) => {
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
    setInitialFetch();
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
    setInitialFetch();
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSelectionChange = useCallback(async (selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
      selectedRows: defaultState.selectedRows,
    }));
    setInitialFetch();
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleApproval = async (id = [], type) => {
    setState((prevState) => ({
      ...prevState,
      isApprovalLoading: true,
    }));

    let serviceMethod, status;

    switch (type) {
      case "approve":
        serviceMethod = "approveCreditMemo";
        status = "approved";

        break;
      case "reject":
        serviceMethod = "rejectCreditMemo";
        status = "rejected";
        break;
      default:
        serviceMethod = null;
    }

    const { error } = await Service[serviceMethod]({ creditMemoIds: id });

    setState((prevState) => ({
      ...prevState,
      isApprovalLoading: false,
      isApprovedDialog: false,
    }));

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success(`Credit memo has been ${status} successfully.`);
      fetchEntries(
        filters,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.pageFilters
      );
      getCreditMemo({ id: id }, true);
    }
  };

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
      selectedRows: [],
    }));
    setInitialFetch();
    setPageConfig(PAGE_KEYS.APPROVE_CREDIT_MEMO, {
      filters: pageFilters,
    });
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleApprovalDialog = (type, value) => {
    setState((prevState) => ({
      ...prevState,
      isApprovedDialog: value,
      approvalType: type,
    }));
  };

  const columnConfig = [
    {
      isHidden: !state.dynamicColumns?.credit_no?.isSelected,
      id: "credit_no",
      label: "Credit Memo No.",
      field: "credit_no",
      canSort: true,
      render: (row) => {
        const title = row.credit_no;
        return (
          <div className="d-flex f-align-center">
            <Tooltip title={title} placement="top-start">
              <Typography
                noWrap
                variant="body2"
                onClick={() => {
                  getCreditMemo(row, true);
                  setState((prevState) => ({
                    ...prevState,
                    pdfViewerDialog: true,
                  }));
                }}
                style={{
                  color: "#775edc",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                {title}
              </Typography>
            </Tooltip>
          </div>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.status?.isSelected,
      id: "status",
      label: "Status",
      field: "status",
      render: (row) => {
        const title = CREDIT_MEMO_STATUS.find(
          (item) => item.value === row?.status
        );
        return (
          <Tooltip title={title?.label ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title?.label || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.credit_memo_date?.isSelected,
      id: "credit_memo_date",
      label: "Credit Memo Date",
      field: "credit_memo_date",
      canSort: true,
      render: (row) => {
        const title = getUTCDateString(row.credit_memo_date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.initial_sent_date?.isSelected,
      id: "initial_sent_date",
      label: "Credit Memo Sent Date",
      field: "initial_sent_date",
      canSort: true,
      render: (row) => {
        const title = getUTCDateString(row.initial_sent_date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pdxCompany?.isSelected,
      id: "pdx_company_name",
      label: "PDX Company",
      field: "pdx_company_name",
      canSort: true,
      render: (row) => {
        const title = row?.customer_branch?.pdxCompany?.value;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.customer?.isSelected,
      id: "customer",
      label: "Customer",
      field: "customer",
      render: (row) => {
        const title = row?.customer_branch?.customer?.name;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.location?.isSelected,
      id: "location",
      label: "Location",
      field: "location",
      render: (row) => {
        const title = row?.customer_branch?.location;

        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.total_amount?.isSelected,
      id: "total_amount",
      label: "Total Amount",
      field: "total_amount",
      canSort: true,
      render: (row) => {
        const title = `$${row.total_amount || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.invoice_amount?.isSelected,
      id: "invoice_amount",
      label: "Invoice Amount",
      field: "invoice_amount",
      canSort: true,
      render: (row) => {
        const title = `$${row.invoice_amount || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.balance_credit_amount?.isSelected,
      id: "balance_credit_amount",
      label: "Balance Credit Amount",
      field: "balance_credit_amount",
      canSort: true,
      render: (row) => {
        const title = row.balance_credit_amount;
        return (
          <Tooltip title={`$${title || "0.00"}`} placement="top-start">
            <Typography variant="body2" noWrap>{`$${
              title || "0.00"
            }`}</Typography>
          </Tooltip>
        );
      },
    },
  ];

  const handleViewerClose = () => {
    setState((prevState) => ({ ...prevState, pdfViewerDialog: false }));
  };

  const LoadingOverlay = ({ message }) => (
    <div className={classes.overlayStyles}>
      <div className={classes.messageStyles}>
        <CircularProgress color="primary" />
        <Typography variant="h6" color="primary" style={{ marginTop: "16px" }}>
          {message}
        </Typography>
      </div>
    </div>
  );

  useEffect(() => {
    if (!state.hasFetched) {
      fetchEntries(
        filters,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.pageFilters
      ).then((data) => {
        updatePagination(
          data?.count,
          { pageNumber: state.pageNumber, pageSize: state.pageSize },
          (pageNumber) => {
            setState((prevState) => ({ ...prevState, pageNumber }));
          }
        );
      });
    }
  }, [
    fetchEntries,
    filters,
    state.pageSize,
    state.pageNumber,
    state.order,
    state.orderBy,
    state.viewCreditMemoSummary,
    state.pageFilters,
  ]);

  return (
    <>
      <Paper elevation={2} className={clsx("p-4", classes.paperSpacing)}>
        <div
          className={clsx(
            "d-flex f-align-center f-justify-between p-2 pl-4 pr-4",
            classes.actionsWrapper
          )}
        >
          <div className="d-flex f-align-center">
            {!!state.selectedRows.length && (
              <>
                <Button
                  variant="outlined"
                  style={{
                    color: "#f44336",
                    borderColor: "#f44336",
                  }}
                  className={classes.iconButton}
                  onClick={() => handleApprovalDialog("reject", true)}
                >
                  Reject
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.iconButton}
                  onClick={() => handleApprovalDialog("approve", true)}
                >
                  Approve
                </Button>
              </>
            )}
          </div>

          <div className="d-flex f-align-center">
            <Dropdown
              classes={{
                paper: classes.tunePaper,
              }}
              disablePortal={false}
              remainOpen
              isMultiSelect
              placement="bottom-end"
              options={Object.keys(state.dynamicColumns).map(
                (key) => state.dynamicColumns[key]
              )}
              customToggle={({ anchorRef, onClick }) => (
                <Tooltip title="Show/Hide column(s)" placement="top-start">
                  <IconButton color="primary" ref={anchorRef} onClick={onClick}>
                    <TuneIcon />
                  </IconButton>
                </Tooltip>
              )}
              onChange={(options) => {
                const isSelectAll = options.some(
                  (item) => item.value === "select_all"
                );
                const isCurrentSelectAll =
                  state.dynamicColumns?.select_all?.isSelected;

                const isClickedOnSelectAll = isSelectAll !== isCurrentSelectAll;

                const isAllOptionSelected =
                  Object.keys(state.dynamicColumns).length - 1 ===
                  options.filter((item) => item.value !== "select_all").length;
                const updatedDynamicColumns = Object.keys(
                  state.dynamicColumns
                ).reduce((acc, key) => {
                  const isSelected = isClickedOnSelectAll
                    ? isSelectAll
                    : key === "select_all"
                    ? isAllOptionSelected
                    : !!options.some((item) => item.value === key);

                  return {
                    ...acc,
                    [key]: {
                      ...state.dynamicColumns[key],
                      isSelected,
                    },
                  };
                }, {});
                setPageConfig(PAGE_KEYS.APPROVE_CREDIT_MEMO, {
                  dynamicColumns: updatedDynamicColumns,
                });
                setState((prevState) => ({
                  ...prevState,
                  dynamicColumns: {
                    ...prevState.dynamicColumns,
                    ...updatedDynamicColumns,
                  },
                }));
              }}
            />
            <Tooltip placement="top-start" title="Filter">
              <IconButton
                color="primary"
                className={clsx("ml-2", {
                  "bg-primary": !!state.pageFilters?.length,
                })}
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    isFiltering: true,
                  }));
                }}
              >
                <FilterListIcon
                  className={clsx({
                    "color-white": !!state.pageFilters?.length,
                  })}
                />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top-end" title="Reset All Filters">
              <IconButton
                color="primary"
                onClick={() => {
                  updatePageConfig("approve-credit-memo");
                  window.location.reload();
                }}
              >
                <RotateLeftIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <Grid
          columns={columnConfig}
          rows={state.entries}
          actionBarConfig={null}
          hasSelectAll={false}
          isLoading={state.isLoading}
          totalRows={state.totalEntries}
          onReady={(instance) => (gridHelper = instance)}
          pageSize={state.pageSize}
          pageNumber={state.pageNumber}
          order={state.order}
          orderBy={state.orderBy}
          onPageNumberChange={handlePageNumberChange}
          onPageSizeChange={handlePageSizeChange}
          onSelectionChange={handleSelectionChange}
          onSortChange={handleSortChange}
          classes={{
            container: classes.addressGridridPaper,
          }}
        />
      </Paper>

      {state.pdfViewerDialog && (
        <Dialog
          onClose={handleViewerClose}
          aria-labelledby="customized-dialog-title"
          open={state.pdfViewerDialog}
          classes={{ paper: classes.paper }}
        >
          <DialogContent dividers>
            {state.isCreditMemoLoading ? (
              <LoadingOverlay message="Processing, please wait..." />
            ) : (
              <iframe
                type="application/pdf"
                src={state.pdfUrl}
                title="Credit Memo PDF"
                width="1150px"
                height="700px"
                style={{ border: "none" }}
                // onLoad={handleIframeLoad}
              ></iframe>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleViewerClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {state.isApprovedDialog && (
        <ActionDialog
          classes={{
            confirm: `${
              state.approvalType === "reject" ? "bg-danger" : "bg-primary"
            }`,
            paper: classes.paperSize,
          }}
          open={state.isApprovedDialog}
          contentText={`Are you sure you want to ${state.approvalType} ?`}
          onConfirm={() =>
            handleApproval(state.selectedRows, state.approvalType)
          }
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              isApprovedDialog: false,
            }))
          }
          isConfirmDisabled={state.isApprovalLoading}
          positiveActionLabel={
            <>
              {state.approvalType}
              {state.isApprovalLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}

      {state.isFiltering && (
        <FilterComponent
          isFiltering={state.isFiltering}
          filterConfig={filterConfig}
          appliedFilters={state.pageFilters}
          onFilterApply={handleFilterChange}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              isFiltering: false,
            }));
          }}
        />
      )}
    </>
  );
};

export default CreditMemoGrid;
