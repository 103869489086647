/* eslint-disable react-hooks/exhaustive-deps */
import { useRef } from "react";
import clsx from "clsx";
import { isTransferScheduleEnable, noop } from "shared/constants";
import { toast } from "react-toastify";
import { getDateString, setPageConfig, useOnScreen } from "utils";
import { Datepicker, Dropdown } from "shared/components";
import { CollapsibleGrid, DSCollapsibleGrid } from "../../shared/components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "@material-ui/core/Box";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import FilterListIcon from "@material-ui/icons/FilterList";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { Tab, Tabs } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import SearchIcon from "@material-ui/icons/Search";
import TuneIcon from "@material-ui/icons/Tune";
import HistoryIcon from "@material-ui/icons/History";
import { useMemo } from "react";

const TAB_LIST = [
  { id: 1, label: "Daily Schedule" },
  { id: 2, label: "Transfer Schedule" },
];

const ResponsiveView = ({
  kamId = null,
  isRoleKAM = false,
  canViewAllSchedules = false,
  state = {},
  classes = {},
  history = {},
  PAGE_KEYS = {},
  RECORD_STATUS = {},
  ROLE = {},
  tabIndex = 1,
  columnConfig = {},
  appDataList = {},
  testId = "",
  isRoleADMIN = false,
  isRoleView = false,
  locationOPtions,
  rosterValue = null,
  selectedLocation = [],
  isTransferredScheduledReceived = false,
  isScheduledTransferred = false,
  userData = null,
  // selectedRef = null,
  tableHeadRef = null,
  inputRef,
  setState = noop,
  handleCalendarClose = noop,
  handleCalendarOpen = noop,
  handleSearch = noop,
  getDayClassName = noop,
  expandLocation = noop,
  handleTabChange = noop,
  getDayClassNamePrevious = noop,
  renderDayContents = noop,
  handleSelectionChange = noop,
  handleGridHelper = noop,
  getPzriceMatrix = noop,
  getDatesInRange = noop,
  handleTransferStatusDialog = noop,
  clearAllEntries = noop,
  wrapRef1,
  wrapRef2,
  gridRefs,
  handleMultiCollapsibleGridScroll = noop,
  handleCollapsibleGridScroll = noop,
  handleRowOpen = noop,
  syncChildGridsScroll = noop,
}) => {
  const actionRef = useRef(null);
  const isActionBarOnScreen = useOnScreen(actionRef);

  const updatedRowEntries = useMemo(() => {
    return Object.keys(state.rowEntries).reduce((acc, key) => {
      acc[key] = state.rowEntries[key]
        .sort((a, b) => {
          const collator = new Intl.Collator(undefined, {
            numeric: true,
            sensitivity: "base",
          });
          return collator.compare(a.route, b.route);
        })
        .map((entry, rowIndex) => ({
          ...entry,
          rowIndex,
          preventSelection:
            entry.status === RECORD_STATUS.SUBMITTED ||
            entry.status === RECORD_STATUS.UNDER_REVIEW ||
            (!!entry.KAM && !entry.KAM.is_active) ||
            (!!entry.kamUser && !entry.kamUser.is_active),
          classes: {
            "individual_contractor.first_name":
              entry.ic_inactive && classes.inactiveICID,
          },
          className: clsx({
            [classes.icidTitleColor]: entry?.individual_contractor_changed,
            [classes.highlightDisable]:
              !entry?.individual_contractor_changed &&
              entry?.status === RECORD_STATUS.SUBMITTED,
            disabled:
              (!!entry.KAM && !entry.KAM.is_active) ||
              (!!entry.kamUser && !entry.kamUser.is_active),
            [classes.highlightDeleted]: entry?.is_bs_deleted,
            [classes.notification]: entry?.id === testId,
            [classes.transferSchedule]:
              isTransferScheduleEnable &&
              !!entry.transfer_by &&
              !!entry.transfer_to,
            [classes.isTransferRejected]: !!entry.is_rejected,
            [classes.isAccepted]: !!entry.is_accepted && !entry.is_rejected,
          }),
        }));

      return acc;
    }, {});
  }, [
    state.rowEntries,
    state.filters.status,
    classes,
    testId,
    isTransferScheduleEnable,
  ]);

  const renderActionBar = (isBottomNav = false) => {
    return (
      <Box className="d-flex f-justify-between f-align-center f-wrap">
        <Box
          className={`${
            tabIndex === 2 ? "f-justify-start" : "f-justify-between"
          } d-flex f-align-center f-justify-between f-wrap`}
        >
          {!isRoleView && tabIndex === 1 && (
            <>
              <Box className="mr-4">
                {state.isFetchingList.users ||
                state.isFetchingList.customers ||
                state.isFetchingList.IC ? (
                  <Skeleton variant="rect" width={90} height={35} />
                ) : (
                  <Button
                    size="small"
                    startIcon={<PlaylistAddIcon />}
                    variant="outlined"
                    color="primary"
                    disabled={
                      tabIndex === 1
                        ? state.scheduleSubmitted
                        : state.transferScheduleSubmitted
                    }
                    onClick={() => {
                      getPzriceMatrix();
                      setState((prevState) => ({
                        ...prevState,
                        isNew: true,
                      }));
                    }}
                  >
                    New
                  </Button>
                )}
              </Box>
            </>
          )}
          {state.userRole === ROLE.KAM && tabIndex === 2 && (
            <>
              <Autocomplete
                disableClearable
                size="small"
                value={state.selectOriginalKAM}
                fullWidth
                className="mr-5"
                classes={{
                  root: classes.kamDropdownWidth,
                  listbox: classes.kamDropdownWidth,
                }}
                options={state.originalKAM}
                getOptionLabel={(option) =>
                  option
                    ? `${option.first_name || ""} ${option.last_name || ""}-${
                        option.email || ""
                      }-(${option.username || ""})`
                    : []
                }
                getOptionSelected={(option, value) => option?.id === value?.id}
                renderInput={(params) =>
                  state.isFetchingOriginalKam ? (
                    <Skeleton variant="rect" width="100%" height="36px" />
                  ) : (
                    <TextField
                      {...params}
                      label="Original KAM"
                      variant="outlined"
                    />
                  )
                }
                onChange={(evt, value) => {
                  setState((prevState) => ({
                    ...prevState,
                    selectOriginalKAM: value,
                  }));
                }}
              />
            </>
          )}
          {!!state.selectedRows.length && (
            <Box className="mr-4">
              {tabIndex === 1 && !state.scheduleSubmitted && (
                <Button
                  size="small"
                  startIcon={<DeleteForeverOutlinedIcon color={"error"} />}
                  classes={{
                    root: "border-error",
                    label: "color-error",
                  }}
                  variant="outlined"
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      deletingEntryId: state.selectedRows.join(","),
                    }))
                  }
                >
                  Delete
                </Button>
              )}
              {isTransferScheduleEnable &&
                !state.scheduleSubmitted &&
                tabIndex === 1 && (
                  <Button
                    color="primary"
                    size="small"
                    className="ml-4"
                    variant="outlined"
                    onClick={() => {
                      if (state.transferRecordStatus.length > 0) {
                        return toast.error(
                          "There are existing transfer records for the dates you've selected. To start a new transfer, you'll have to reject the current transfer records first."
                        );
                      }
                      if (
                        Object.values(state.rowEntries).some((entries) =>
                          entries.some(
                            (entry) =>
                              state.selectedRows.includes(entry.id) &&
                              entry.is_accepted &&
                              !entry.is_rejected
                          )
                        )
                      ) {
                        return toast.error(
                          "One or more selected records have already been transferred and accepted by the KAM."
                        );
                      }
                      setState((prevState) => ({
                        ...prevState,
                        isTransferSchedule: true,
                      }));
                    }}
                  >
                    Transfer Schedule
                  </Button>
                )}
              {isTransferScheduleEnable &&
                !state.transferScheduleSubmitted &&
                tabIndex === 2 && (
                  <div className="d-flex f-align-center mb-1">
                    <Button
                      className="mt-1 mr-4"
                      s
                      variant="outlined"
                      classes={{
                        root: "border-error",
                        label: "color-error",
                      }}
                      onClick={() => handleTransferStatusDialog(true, "reject")}
                    >
                      Reject
                    </Button>
                    <Button
                      className="mt-1 mr-2"
                      s
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        handleTransferStatusDialog(true, "approve")
                      }
                    >
                      Accept
                    </Button>
                  </div>
                )}
            </Box>
          )}
          {!isRoleView &&
            !(
              state.userRole === ROLE.KAM &&
              tabIndex === 1 &&
              state.transferEntries.length > 0 &&
              state.customerEntries.length === 0
            ) && (
              <>
                <Box className="mr-4">
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      const isICEmpty = (
                        tabIndex === 1
                          ? Object.values(state.rowEntries).flat()
                          : state.transferEntries
                      )
                        .filter((entry) =>
                          state.selectedRows.includes(entry.id)
                        )
                        .some((entry) => !entry.individual_contractor);
                      if (isICEmpty) {
                        return toast.error(
                          "ICID/IC Name Required for the submission."
                        );
                      }
                      let payload = {
                        // submission_date: state.scheduleDate,
                        fromSubmissionDate: state.scheduleDate,
                        toSubmissionDate: state.endDate || state.scheduleDate,
                        dailyScheduleIds: state.selectedRows,
                        isPartialSubmit: true,
                      };
                      if (state.userRole !== ROLE.KAM) {
                        payload["kam_id"] = state.selectedKAM?.id;
                      } else if (state.userRole === ROLE.KAM) {
                        payload["kam_id"] =
                          tabIndex === 1 ? kamId : state.selectOriginalKAM?.id;
                        // payload["is_sbst_sbmsn_cnf"] = true;
                      }
                      const exceededEntries = (
                        tabIndex === 1
                          ? Object.values(state.rowEntries).flat()
                          : state.transferEntries
                      ).filter(
                        (data) =>
                          payload.dailyScheduleIds?.includes(data.id) &&
                          data.pay_rate_type === "Daily" &&
                          +data.pay_quantity > 1.99
                      );

                      if (exceededEntries.length > 0) {
                        const locationDetails = exceededEntries.map(
                          (entry) => ({
                            customer: entry.customer_branch.customer.name,
                            route: entry.route,
                            schedule_date: entry.schedule_date,
                            location: entry.customer_branch.location,
                          })
                        );

                        setState((prevState) => ({
                          ...prevState,
                          payQuantityExceedDaily: locationDetails,
                        }));

                        return;
                      }
                      setState((prevState) => ({
                        ...prevState,
                        confirmScheduleSubmit: { payload },
                      }));
                    }}
                    disabled={
                      (tabIndex === 1
                        ? state.scheduleSubmitted
                        : state.transferScheduleSubmitted ||
                          (state.selectedRows.length > 0 &&
                            state.transferEntries.some(
                              (item) =>
                                state.selectedRows.includes(item?.id) &&
                                !item.is_accepted
                            ))) || !state.selectedRows.length
                    }
                  >
                    Partial Submit
                  </Button>
                </Box>
                <Box className="mr-4">
                  {tabIndex === 1 && (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        const isICEmpty = state.entriesToSubmit.some(
                          (entry) => !entry.individual_contractor
                        );
                        if (isICEmpty) {
                          return toast.error(
                            "ICID/IC Name Required for the submission."
                          );
                        }
                        let payload = {
                          // submission_date: state.scheduleDate,
                          fromSubmissionDate: state.scheduleDate,
                          toSubmissionDate: state.endDate || state.scheduleDate,
                          isPartialSubmit: false,
                        };
                        if (state.userRole !== ROLE.KAM) {
                          payload["kam_id"] = state.selectedKAM?.id;
                        } else if (state.userRole === ROLE.KAM) {
                          payload["kam_id"] =
                            tabIndex === 1
                              ? kamId
                              : state.selectOriginalKAM?.id;
                          // payload["is_sbst_sbmsn_cnf"] = true;
                        }
                        const exceededEntries = state.entriesToSubmit.filter(
                          (data) =>
                            data.pay_rate_type === "Daily" &&
                            +data.pay_quantity > 1.99
                        );

                        if (exceededEntries.length > 0) {
                          const locationDetails = exceededEntries.map(
                            (entry) => ({
                              customer: entry.customer_branch.customer.name,
                              route: entry.route,
                              schedule_date: entry.schedule_date,
                              location: entry.customer_branch.location,
                            })
                          );

                          setState((prevState) => ({
                            ...prevState,
                            payQuantityExceedDaily: locationDetails,
                          }));

                          return;
                        }
                        setState((prevState) => ({
                          ...prevState,
                          confirmScheduleSubmit: { payload },
                        }));
                      }}
                      disabled={
                        state.scheduleSubmitted ||
                        state.isFetchingSubmitEntries ||
                        (isTransferScheduleEnable &&
                          state.entriesToSubmit.some(
                            (item) => item.transfer_by && item.transfer_to
                          ))
                      }
                    >
                      Submit
                    </Button>
                  )}
                </Box>
              </>
            )}
        </Box>
        <Box
          className={
            tabIndex === 2 ? "d-flex f-justify-center f-align-center" : "d-flex"
          }
        >
          {tabIndex === 2 && (
            <Dropdown
              classes={{
                paper: clsx("mr-1", classes.tunePaper),
              }}
              disablePortal={false}
              labelEllipses
              hasEllipses
              placement={isBottomNav ? "top-end" : "bottom-end"}
              options={[
                { label: "All", value: null },
                { label: "Accepted", value: true },
                { label: "Not Accepted", value: false },
              ]}
              label={`Response Type: ${
                !!state.filters.acceptedType &&
                state.filters.acceptedType?.length
                  ? state.filters.acceptedType[0]?.value
                    ? "Accepted"
                    : "Not Accepted"
                  : "All"
              }`}
              onChange={(option) => {
                setState((prevState) => ({
                  ...prevState,
                  filters: {
                    ...prevState.filters,
                    acceptedType:
                      option.value === null
                        ? []
                        : [
                            {
                              field: "is_accepted",
                              type: "=",
                              value: option.value,
                            },
                          ],
                  },
                }));
              }}
            />
          )}
          <Dropdown
            classes={{
              paper: classes.tunePaper,
            }}
            disablePortal={false}
            remainOpen
            isMultiSelect
            placement={isBottomNav ? "top-end" : "bottom-end"}
            options={Object.keys(state.dynamicColumns).map(
              (key) => state.dynamicColumns[key]
            )}
            customToggle={({ anchorRef, onClick }) => (
              <Tooltip title="Show/Hide column(s)" placement="top-start">
                <IconButton color="primary" ref={anchorRef} onClick={onClick}>
                  <TuneIcon />
                </IconButton>
              </Tooltip>
            )}
            onChange={(options) => {
              const isSelectAll = options.some(
                (item) => item.value === "select_all"
              );
              const isCurrentSelectAll =
                state.dynamicColumns?.select_all?.isSelected;

              const isClickedOnSelectAll = isSelectAll !== isCurrentSelectAll;

              const isAllOptionSelected =
                Object.keys(state.dynamicColumns).length - 1 ===
                options.filter((item) => item.value !== "select_all").length;
              const updatedDynamicColumns = Object.keys(
                state.dynamicColumns
              ).reduce((acc, key) => {
                const isSelected = isClickedOnSelectAll
                  ? isSelectAll
                  : key === "select_all"
                  ? isAllOptionSelected
                  : !!options.some((item) => item.value === key);
                return {
                  ...acc,
                  [key]: {
                    ...state.dynamicColumns[key],
                    isSelected,
                  },
                };
              }, {});
              setPageConfig(PAGE_KEYS.DAILY_SCHEDULE, {
                dynamicColumns: updatedDynamicColumns,
              });
              setState((prevState) => ({
                ...prevState,
                dynamicColumns: {
                  ...prevState.dynamicColumns,
                  ...updatedDynamicColumns,
                },
              }));
            }}
          />
          <Tooltip placement="top-start" title="Filter">
            <IconButton
              color="primary"
              disabled={state.isFetching || state.isFetchingTransferEntries}
              className={clsx({
                "bg-primary": !!state.pageFilters?.length,
              })}
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  isFiltering: true,
                }));
              }}
            >
              <FilterListIcon
                className={clsx({
                  "color-white": !!state.pageFilters?.length,
                })}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  };

  const transferScheduleIsAccepted = state.transferEntries
    .filter((entry) => {
      const originalKAMId = state.selectOriginalKAM?.id;
      const entryKAMId = entry?.KAM?.id;

      return (
        state.filters.status.includes(entry.status) &&
        originalKAMId === entryKAMId
      );
    })
    .some((entry) => entry.is_accepted);

  const transferRecord = Object.values(state.rowEntries).some((entries) =>
    entries.some(
      (entry) =>
        state.filters.status.includes(entry.status) &&
        entry.transfer_by &&
        entry.transfer_to &&
        entry.status !== RECORD_STATUS.SUBMITTED &&
        !entry.is_accepted &&
        !entry.is_rejected
    )
  );

  const transferRejected = Object.values(state.rowEntries).some((entries) =>
    entries.some(
      (entry) =>
        state.filters.status.includes(entry.status) && entry.is_rejected
    )
  );

  const transferRecordApproved = Object.values(state.rowEntries).some(
    (entries) =>
      entries.some(
        (entry) =>
          state.filters.status.includes(entry.status) && entry.is_accepted
      )
  );

  return (
    <>
      <Grid container>
        {(state.tabIndex === 1 &&
          (state.isBsApproved || state.scheduleSubmitted)) ||
        (state.tabIndex === 2 &&
          !!state.selectOriginalKAM &&
          (state.transferScheduleSubmitted || state.isTransferBsApproved)) ? (
          <Grid
            item
            xs={12}
            className={clsx("mb-3 mt-2", classes.fixedHeader)}
            style={{
              top: "104px",
            }}
          >
            <Box className="mb-2">
              <Typography variant="h4" color="primary" className="ml-2">
                Daily Schedule
                <Button
                  startIcon={<ArrowBackIcon />}
                  style={{ float: "right" }}
                  className="mt-1 mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/home")}
                >
                  Back To Home
                </Button>
                {!isRoleKAM && (
                  <Button
                    style={{ float: "right" }}
                    className="mt-1 mr-2"
                    startIcon={<HistoryIcon />}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      history.push({
                        pathname: "/daily-schedule/audit-history",
                        state: {
                          startDate: state.scheduleDate,
                          endDate: state.endDate,
                          dateFilterType: state.isMultiSchedule,
                          kamFilter: state.selectedKAM,
                        },
                      })
                    }
                  >
                    View Audit History
                  </Button>
                )}
              </Typography>
              <Typography variant="body2" style={{ marginLeft: "10px" }}>
                {canViewAllSchedules
                  ? `${
                      !isRoleView
                        ? "Add/Edit Schedule for KAM's."
                        : "View Schedule for KAM's."
                    }`
                  : "Add/Edit your schedule."}
              </Typography>
            </Box>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            className={clsx("mb-3 mt-2", classes.fixedHeader)}
            style={{
              top: "64px",
            }}
          >
            <Box className="mb-2">
              <Typography variant="h4" color="primary" className="ml-2">
                Daily Schedule
                <Button
                  startIcon={<ArrowBackIcon />}
                  style={{ float: "right" }}
                  className="mt-1 mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/home")}
                >
                  Back To Home
                </Button>
                {!isRoleKAM && (
                  <Button
                    style={{ float: "right" }}
                    className="mt-1 mr-2"
                    startIcon={<HistoryIcon />}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      history.push({
                        pathname: "/daily-schedule/audit-history",
                        state: {
                          startDate: state.scheduleDate,
                          endDate: state.endDate,
                          dateFilterType: state.isMultiSchedule,
                          kamFilter: state.selectedKAM,
                        },
                      })
                    }
                  >
                    View Audit History
                  </Button>
                )}
              </Typography>
              <Typography variant="body2" style={{ marginLeft: "10px" }}>
                {canViewAllSchedules
                  ? `${
                      !isRoleView
                        ? "Add/Edit Schedule for KAM's."
                        : "View Schedule for KAM's."
                    }`
                  : "Add/Edit your schedule."}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={5} className="ml-1">
          <Box
            className={clsx("d-flex flex-column", {
              "mb-6": state.isNoSchedule,
            })}
          >
            <FormControl>
              <RadioGroup
                row
                value={state.isMultiSchedule ? "multiple" : "single"}
                onChange={(evt) => {
                  const isMultiSchedule = evt.target.value !== "single";
                  let date = getDateString(state.scheduleDate);
                  date = date.split("/");
                  date = `${date[0]}-${date[1]}-${date[2]}`;
                  if (isMultiSchedule) {
                    date += `/${date}`;
                  }
                  history.push(
                    `/daily-schedule/day/${date}${kamId ? `/kam/${kamId}` : ""}`
                  );
                  setState((prevState) => ({
                    ...prevState,
                    isMultiSchedule,
                    endDate: isMultiSchedule ? prevState.scheduleDate : null,
                  }));
                }}
              >
                <FormControlLabel
                  value="single"
                  control={<Radio size="small" color="primary" />}
                  label="Specific date"
                />
                <FormControlLabel
                  value="multiple"
                  control={<Radio size="small" color="primary" />}
                  label="Date range"
                />
              </RadioGroup>
            </FormControl>
            <Box className="d-flex">
              <Datepicker
                preventClear
                mask
                renderDayContents={renderDayContents}
                maxDate={new Date(state.endDate)}
                selected={state.scheduleDate}
                label={state.isMultiSchedule ? "From date" : "Schedule date"}
                onCalendarClose={handleCalendarClose}
                onCalendarOpen={() => handleCalendarOpen("scheduleDate")}
                dayClassName={getDayClassName}
                classes={{
                  input: {
                    root: classes.datepickerWrapper,
                  },
                }}
                className="mt-4 mr-4"
                onMonthChange={(previousScheduleActiveDate) =>
                  setState((prevState) => ({
                    ...prevState,
                    previousScheduleActiveDate,
                  }))
                }
                onChange={(previousDate) => {
                  if (!previousDate) return;
                  const reasonForcedSubmitDate =
                    state.submissionHistory.forcedlySubmitted.find(
                      (item) => item.date === getDateString(previousDate)
                    );
                  let date = getDateString(previousDate);
                  date = date.split("/");
                  date = `${date[0]}-${date[1]}-${date[2]}`;
                  if (state.isMultiSchedule) {
                    let endDateString = getDateString(state.endDate);
                    endDateString = endDateString.split("/");
                    date += `/${endDateString[0]}-${endDateString[1]}-${endDateString[2]}`;
                  }
                  getDatesInRange(previousDate, state.endDate);
                  history.push(
                    `/daily-schedule/day/${date}${kamId ? `/kam/${kamId}` : ""}`
                  );

                  setState((prevState) => ({
                    ...prevState,
                    scheduleDate: getDateString(previousDate),
                    openRevertForcedSubmitDialog:
                      isRoleADMIN && reasonForcedSubmitDate,
                    forcedSubmitDate: getDateString(previousDate),
                  }));
                }}
              />
              {state.isMultiSchedule && (
                <Datepicker
                  preventClear
                  mask
                  renderDayContents={renderDayContents}
                  minDate={new Date(state.scheduleDate)}
                  selected={state.endDate}
                  label="To date"
                  onCalendarClose={handleCalendarClose}
                  onCalendarOpen={() => handleCalendarOpen("endDate")}
                  dayClassName={getDayClassName}
                  className="mt-4"
                  classes={{
                    input: {
                      root: classes.datepickerWrapper,
                    },
                  }}
                  onMonthChange={(previousScheduleActiveDate) =>
                    setState((prevState) => ({
                      ...prevState,
                      previousScheduleActiveDate,
                    }))
                  }
                  onChange={(previousDate) => {
                    if (!previousDate) return;
                    const reasonForcedSubmitDate =
                      state.submissionHistory.forcedlySubmitted.find(
                        (item) => item.date === getDateString(previousDate)
                      );
                    let date = getDateString(state.scheduleDate);
                    let endDateString = getDateString(previousDate);
                    date = date.split("/");
                    endDateString = endDateString.split("/");
                    date = `${date[0]}-${date[1]}-${date[2]}`;
                    date += `/${endDateString[0]}-${endDateString[1]}-${endDateString[2]}`;
                    getDatesInRange(state.scheduleDate, previousDate);
                    history.push(
                      `/daily-schedule/day/${date}${
                        kamId ? `/kam/${kamId}` : ""
                      }`
                    );

                    setState((prevState) => ({
                      ...prevState,
                      endDate: getDateString(previousDate),
                      openRevertForcedSubmitDialog:
                        isRoleADMIN && reasonForcedSubmitDate,
                      forcedSubmitDate: getDateString(previousDate),
                    }));
                  }}
                />
              )}
            </Box>
            <Box className="d-flex">
              {canViewAllSchedules && (
                <Autocomplete
                  disableClearable
                  size="small"
                  value={state.selectedKAM}
                  fullWidth
                  className="mt-4"
                  classes={{ root: classes.kamDropdownWidth }}
                  options={appDataList.kamUsers}
                  getOptionLabel={(option) =>
                    option.name ||
                    `${option.first_name || ""} ${option.last_name || ""}-${
                      option.email || ""
                    }-(${option.username || ""})`
                  }
                  renderOption={({
                    first_name,
                    last_name,
                    email,
                    is_active,
                    username,
                  }) => (
                    <Typography
                      variant="body1"
                      className={clsx({
                        "color-text-disabled": !is_active,
                      })}
                    >{`${first_name || " "} ${
                      last_name || ""
                    }-${email}-${username}`}</Typography>
                  )}
                  renderInput={(params) =>
                    state.isFetchingList.users ? (
                      <Skeleton variant="rect" width="100%" height="100%" />
                    ) : (
                      <TextField {...params} label="KAM" variant="outlined" />
                    )
                  }
                  onChange={(evt, value) => {
                    setState((prevState) => ({
                      ...prevState,
                      selectedKAM: value,
                    }));
                  }}
                />
              )}
              <Autocomplete
                classes={{ root: classes.kamDropdownWidth }}
                style={{ width: "244px", marginLeft: 18, marginTop: 15 }}
                size="small"
                fullWidth
                value={selectedLocation || []}
                multiple
                options={locationOPtions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) =>
                  state.isFetchingList.users ? (
                    <Skeleton variant="rect" width="100%" height="36px" />
                  ) : (
                    <TextField
                      {...params}
                      label="Location"
                      variant="outlined"
                    />
                  )
                }
                getOptionSelected={(option, value) =>
                  option.label === value.label
                }
                onChange={(evt, value) => {
                  setState((prevState) => ({
                    ...prevState,
                    selectedLocation: value,
                  }));
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={7} className={classes.legendsPosition}>
          <Box
            className={clsx("d-flex f-wrap mt-4 ml-9", {
              "f-justify-end": !isRoleKAM,
            })}
          >
            {state.isCalenderOpen && (
              <>
                {[
                  [],
                  [],
                  [
                    { label: "Submitted", className: "completeSubmittedDay" },
                    {
                      label: "Partial Submitted",
                      className: "partialSubmittedDay",
                    },
                    { label: "Not Submitted", className: "notSubmittedDay" },
                    { label: "Not Created", className: "notCreatedDay" },
                    { label: "Forced Submit", className: "forcedSubmitDay" },
                    { label: "No Schedule", className: "weekoff" },
                    { label: "Under Review", className: "underReview" },
                    { label: "Approved", className: "bsApproved" },
                  ],
                ].map((legend) => {
                  const legends = !isRoleKAM ? (
                    <Box className="mr-2">
                      {legend.map((item) => (
                        <Box className="d-flex f-align-center pt-1">
                          <Box
                            className={clsx(
                              "mr-2 ml-3",
                              classes.legendHint,
                              classes[item.className]
                            )}
                          ></Box>
                          <Typography variant="body2">{item.label}</Typography>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <>
                      {legend.map((item) => (
                        <Box className="d-flex f-align-center pb-1 pt-1 p-1 ml-4">
                          <Box
                            className={clsx(
                              "mr-1 ml-1",
                              classes.legendHint,
                              classes[item.className]
                            )}
                          ></Box>
                          <Typography variant="body2">{item.label}</Typography>
                        </Box>
                      ))}
                    </>
                  );
                  return legends;
                })}
              </>
            )}
          </Box>
        </Grid>
        {(state.userRole === ROLE.KAM || state.selectedKAM) &&
          !state.isNoSchedule && (
            <Grid item xs={12}>
              <Box className="d-flex f-align-center f-justify-end mt-8 mb-8">
                <Box>
                  <FormControlLabel
                    label={<Typography variant="body2">Submitted</Typography>}
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={state.filters.status.some(
                          (item) =>
                            item === RECORD_STATUS.SUBMITTED ||
                            item === RECORD_STATUS.UNDER_REVIEW
                        )}
                        onChange={(evt) =>
                          setState((prevState) => ({
                            ...prevState,
                            filters: {
                              ...prevState.filters,
                              status: evt.target.checked
                                ? [
                                    ...prevState.filters.status,
                                    RECORD_STATUS.SUBMITTED,
                                    RECORD_STATUS.UNDER_REVIEW,
                                  ]
                                : prevState.filters.status.filter(
                                    (item) =>
                                      item != RECORD_STATUS.SUBMITTED &&
                                      item !== RECORD_STATUS.UNDER_REVIEW
                                  ),
                            },
                          }))
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label={
                      <Typography variant="body2">Not Submitted</Typography>
                    }
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={state.filters.status.includes(
                          RECORD_STATUS.PENDING
                        )}
                        onChange={(evt) =>
                          setState((prevState) => ({
                            ...prevState,
                            filters: {
                              ...prevState.filters,
                              status: evt.target.checked
                                ? [
                                    ...prevState.filters.status,
                                    RECORD_STATUS.PENDING,
                                  ]
                                : prevState.filters.status.filter(
                                    (item) => item !== RECORD_STATUS.PENDING
                                  ),
                            },
                          }))
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label={<Typography variant="body2">Customer</Typography>}
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        value="customer_name"
                        disabled={
                          state.tabIndex === 2 && !state.selectOriginalKAM
                        }
                        checked={state.searchColumns.includes("customer_name")}
                        onChange={(evt) =>
                          setState((prevState) => {
                            const { checked, value } = evt.target;
                            const searchColumns = checked
                              ? [...prevState.searchColumns, value]
                              : prevState.searchColumns.filter(
                                  (item) => item !== value
                                );
                            return {
                              ...prevState,
                              searchColumns,
                            };
                          })
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label={<Typography variant="body2">IC</Typography>}
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        value="contractor_first_name"
                        disabled={
                          state.tabIndex === 2 && !state.selectOriginalKAM
                        }
                        checked={state.searchColumns.includes(
                          "contractor_first_name"
                        )}
                        onChange={(evt) =>
                          setState((prevState) => {
                            const { checked, value } = evt.target;
                            const searchColumns = checked
                              ? [...prevState.searchColumns, value, "icid"]
                              : prevState.searchColumns.filter(
                                  (item) => item !== value && item !== "icid"
                                );
                            return {
                              ...prevState,
                              searchColumns,
                            };
                          })
                        }
                      />
                    }
                  />
                </Box>
                <Box>
                  <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    placeholder="Search"
                    inputRef={inputRef}
                    disabled={state.tabIndex === 2 && !state.selectOriginalKAM}
                    InputProps={{ endAdornment: <SearchIcon /> }}
                    onChange={(evt) =>
                      handleSearch((evt.target.value || "").trim())
                    }
                  />
                </Box>
              </Box>
            </Grid>
          )}
        {!isRoleView && tabIndex === 1 && (
          <Grid item xs={12}>
            <Box className="d-flex f-justify-end mt-3">
              <Button
                startIcon={<ListAltOutlinedIcon />}
                color="bg-primary"
                disabled={state.scheduleSubmitted || state.isPartialSubmitted}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    confirmDialog: {
                      ...prevState.confirmDialog,
                      contentText:
                        "Are you sure you want to copy from Base Roster?",
                      positiveActionLabel: "Confirm",
                      payload: {
                        fromScheduleDate: state.scheduleDate,
                        toScheduleDate: state.endDate || state.scheduleDate,
                        isOfPreviousDate: false,
                        deleteExisting: true,
                        kam_id: state.selectedKAM?.id,
                        is_weekday: rosterValue === "weekdays" ? true : false,
                      },
                    },
                  }))
                }
              >
                Copy from Base Roster....
              </Button>
              {/* <Datepicker
                preventClear
                mask
                // disabled={state.scheduleSubmitted || state.isPartialSubmitted}
                disabled
                selected={(() => {
                  const yesterdayDate = new Date();
                  return yesterdayDate.setDate(yesterdayDate.getDate() - 1);
                })()}
                label="Copy From Previous Date"
                classes={{
                  input: { root: clsx("mw-100", classes.datepickerWrapper) },
                }}
                dayClassName={getDayClassNamePrevious}
                onCalendarClose={handleCalendarClose}
                onCalendarOpen={() => handleCalendarOpen("copyCalender")}
                onMonthChange={(previousScheduleActiveDate) =>
                  setState((prevState) => ({
                    ...prevState,
                    previousScheduleActiveDate,
                  }))
                }
                onChange={(previousDate) => {
                  if (previousDate) {
                    setState((prevState) => ({
                      ...prevState,
                      confirmDialog: {
                        ...prevState.confirmDialog,
                        contentText: `Copying from ${getDateString(
                          previousDate
                        )}`,
                        positiveActionLabel: "Confirm",
                        payload: {
                          fromScheduleDate: state.scheduleDate,
                          toScheduleDate: state.endDate || state.scheduleDate,
                          previousDate: getDateString(previousDate),
                          isOfPreviousDate: true,
                          deleteExisting: true,
                          kam_id: state.selectedKAM?.id,
                        },
                      },
                    }));
                  }
                }}
              /> */}
            </Box>
          </Grid>
        )}
        {state.selectedKAM == null &&
          !state.isNoSchedule &&
          canViewAllSchedules && (
            <Grid
              item
              xs={12}
              component={Paper}
              elevation={2}
              className="p-10 mt-10 h-100"
            >
              <Typography variant="body1">
                Please select a KAM to view schedule for{" "}
                {`${getDateString(state.scheduleDate)}${
                  state.isMultiSchedule
                    ? ` - ${getDateString(state.endDate)}`
                    : ""
                }`}
              </Typography>
            </Grid>
          )}
        {state.isNoSchedule && canViewAllSchedules && (
          <Grid
            item
            xs={12}
            component={Paper}
            elevation={2}
            className={clsx(
              "d-flex f-align-center f-justify-center mt-10 p-2 pl-4 pr-4 p-relative",
              classes.paper
            )}
          >
            <div className="d-flex flex-column f-justify-center f-align-center">
              {state.isForceSubmitted ? (
                <Typography variant="h6">
                  Schedule marked as forced submitted for{" "}
                  {`${getDateString(state.scheduleDate)}${
                    state.isMultiSchedule
                      ? ` - ${getDateString(state.endDate)}`
                      : ""
                  }`}
                </Typography>
              ) : (
                <>
                  <Typography variant="h6">
                    No schedule exists for{" "}
                    {`"${state.selectedKAM?.first_name || ""} ${
                      state.selectedKAM?.last_name || ""
                    }"`}{" "}
                    for{" "}
                    {`${getDateString(state.scheduleDate)}${
                      state.isMultiSchedule
                        ? ` - ${getDateString(state.endDate)}`
                        : ""
                    }`}
                  </Typography>
                  <Button
                    className="mt-5 w-50 f-align-center"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      let date = getDateString(state.scheduleDate)?.split("/");
                      date = `${date[2]}-${date[0]}-${date[1]}`;
                      if (state.isMultiSchedule) {
                        let endDateString = getDateString(state.endDate)?.split(
                          "/"
                        );
                        date += `/${endDateString[2]}-${endDateString[0]}-${endDateString[1]}`;
                      }
                      history.push({
                        pathname: `/daily-schedule/create/day/${date}${
                          state.selectedKAM?.id
                            ? `/kam/${state.selectedKAM.id}`
                            : ""
                        }`,
                        state: { isMultiSchedule: state.isMultiSchedule },
                      });
                    }}
                  >
                    Create Schedule
                  </Button>
                </>
              )}
            </div>
          </Grid>
        )}
        <Box ref={actionRef}></Box>
        {isTransferScheduleEnable && state.userRole === ROLE.KAM && (
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor={"#eeeeee"}
            textColor={"primary"}
            className={classes.tabs}
          >
            {TAB_LIST.map((tab) => (
              <Tab
                value={tab.id}
                // disabled={isFetching || state.isLoading}
                key={tab.id}
                name={tab.id}
                className={classes.tab}
                style={{
                  borderTop:
                    tab.id === tabIndex ? "2px solid #80808045" : "none",
                  borderLeft:
                    tab.id === tabIndex ? "2px solid #80808045" : "none",
                  borderRight:
                    tab.id === tabIndex ? "2px solid #80808045" : "none",
                  background: tab.id === tabIndex ? "white" : "#ececec",
                }}
                label={
                  <Typography
                    variant="subtitle2"
                    color={tab.id === tabIndex ? "primary.dark" : "grey.300"}
                  >
                    {tab.label}
                  </Typography>
                }
              />
            ))}
          </Tabs>
        )}

        {(state.userRole === ROLE.KAM || state.selectedKAM) &&
          !state.isNoSchedule && (
            <>
              <Grid
                item
                xs={12}
                component={Paper}
                elevation={2}
                className={isRoleKAM ? "p-5 mb-10" : "p-5 mt-5 mb-10"}
              >
                <Box>{renderActionBar()}</Box>
                <Divider className="mt-4" />
                <Box>
                  {state.userRole === ROLE.KAM &&
                  tabIndex === 1 &&
                  isTransferredScheduledReceived === true &&
                  isScheduledTransferred === false &&
                  state.entries.length === 0 ? (
                    <div
                      className="d-flex flex-column f-justify-center f-align-center"
                      style={{ height: "22rem" }}
                    >
                      <Typography variant="h6">
                        No schedule exists for{" "}
                        {`"${userData?.first_name || ""} ${
                          userData?.last_name || ""
                        }"`}{" "}
                        for{" "}
                        {`${getDateString(state.scheduleDate)}${
                          state.isMultiSchedule
                            ? ` - ${getDateString(state.endDate)}`
                            : ""
                        }`}
                      </Typography>
                      <Button
                        className="mt-5 f-align-center"
                        style={{ width: "15rem" }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          let date = getDateString(state.scheduleDate)?.split(
                            "/"
                          );
                          date = `${date[2]}-${date[0]}-${date[1]}`;
                          if (state.isMultiSchedule) {
                            let endDateString = getDateString(
                              state.endDate
                            )?.split("/");
                            date += `/${endDateString[2]}-${endDateString[0]}-${endDateString[1]}`;
                          }
                          history.push({
                            pathname: `/daily-schedule/create/day/${date}${
                              kamId?.id ? `/kam/${kamId?.id}` : ""
                            }`,
                            state: { isMultiSchedule: state.isMultiSchedule },
                          });
                        }}
                      >
                        Create Schedule
                      </Button>
                    </div>
                  ) : tabIndex === 2 && !state.selectOriginalKAM ? (
                    <div
                      className="d-flex flex-column f-justify-center f-align-center"
                      style={{ height: "22rem" }}
                    >
                      <Typography variant="h6">
                        Please select the original KAM from the dropdown to view
                        transfer schedule records.
                      </Typography>
                    </div>
                  ) : (
                    <>
                      {tabIndex === 1 && (
                        <DSCollapsibleGrid
                          rows={state.customerEntries}
                          columns={columnConfig}
                          isTableLoading={state.isTableLoading}
                          expandLocation={expandLocation}
                          clearAllEntries={clearAllEntries}
                          entries={updatedRowEntries}
                          isLoading={state.isFetching}
                          search={state.search}
                          onReady={handleGridHelper}
                          hasSelection={!isRoleView}
                          onSelectionChange={handleSelectionChange}
                          wrapRef1={wrapRef1}
                          syncChildGridsScroll={syncChildGridsScroll}
                          wrapRef2={wrapRef2}
                          handleScroll={handleMultiCollapsibleGridScroll}
                          handleRowOpen={handleRowOpen}
                          gridRefs={gridRefs}
                          tableHeadRef={tableHeadRef}
                          rowEvents={[
                            {
                              type: "onDoubleClick",
                              handler: (row) => {
                                if (isRoleView) {
                                  return false;
                                }
                                setState((prevState) => {
                                  const isDisabled =
                                    prevState.scheduleSubmitted ||
                                    row.status === RECORD_STATUS.SUBMITTED ||
                                    row.status === RECORD_STATUS.UNDER_REVIEW ||
                                    (!!row.KAM && !row.KAM.is_active) ||
                                    (!!row.kamUser && !row.kamUser.is_active);

                                  return {
                                    ...prevState,
                                    rowBeingEdited: isDisabled
                                      ? prevState.rowBeingEdited
                                      : row,
                                  };
                                });

                                getPzriceMatrix();
                              },
                            },
                          ]}
                          gridClasses={{
                            gridActions: "f-justify-end mt-2",
                          }}
                        />
                      )}
                      {tabIndex === 2 && (
                        <CollapsibleGrid
                          wrapRef2={wrapRef2}
                          handleScroll={handleCollapsibleGridScroll}
                          tableHeadRef={tableHeadRef}
                          handleRowOpen={handleRowOpen}
                          columns={columnConfig}
                          rows={state.transferEntries
                            .filter((entry) => {
                              const originalKAMId = state.selectOriginalKAM?.id;
                              const entryKAMId = entry?.KAM?.id;

                              return (
                                state.filters.status.includes(entry.status) &&
                                originalKAMId === entryKAMId
                              );
                            })
                            .map((entry, rowIndex) => ({
                              ...entry,
                              rowIndex,
                              customer_name:
                                entry?.customer_branch?.customer?.name,
                              preventSelection:
                                entry.status === RECORD_STATUS.SUBMITTED ||
                                entry.status === RECORD_STATUS.UNDER_REVIEW ||
                                !entry.KAM.is_active,
                              className: clsx({
                                [classes.isAccepted]: !!entry.is_accepted,
                              }),
                            }))}
                          isLoading={state.isFetchingTransferEntries}
                          uniqueBy="customer_name"
                          search={state.search}
                          onReady={handleGridHelper}
                          hasSelection={!isRoleView}
                          onSelectionChange={handleSelectionChange}
                          rowEvents={[
                            {
                              type: "onDoubleClick",
                              handler: (row) => {
                                if (isRoleView) {
                                  return false;
                                }
                                setState((prevState) => {
                                  const isDisabled =
                                    row.status === RECORD_STATUS.SUBMITTED ||
                                    row.status === RECORD_STATUS.UNDER_REVIEW ||
                                    !row.KAM?.is_active ||
                                    (isRoleKAM && !row.is_accepted);
                                  return {
                                    ...prevState,
                                    rowBeingEdited: isDisabled
                                      ? prevState.rowBeingEdited
                                      : row,
                                  };
                                });

                                getPzriceMatrix();
                              },
                            },
                          ]}
                          classes={{
                            gridActions: "f-justify-end",
                          }}
                        />
                      )}
                    </>
                  )}
                </Box>
              </Grid>
              <Box className={"d-flex f-wrap"}>
                {tabIndex === 1 && (
                  <>
                    {isTransferScheduleEnable && transferRecord && (
                      <div
                        className="d-flex f-align-center ml-2"
                        key="Transfer Record"
                      >
                        <div
                          className={clsx(
                            "mr-2",
                            classes.legendHint,
                            classes["transferSchedule"]
                          )}
                        ></div>
                        <Typography variant="body2">Transfer Record</Typography>
                      </div>
                    )}
                    {transferRejected && (
                      <div
                        className="d-flex f-align-center ml-2"
                        key="Transfer Rejected"
                      >
                        <div
                          className={clsx(
                            "mr-2",
                            classes.legendHint,
                            classes["isTransferRejected"]
                          )}
                        ></div>
                        <Typography variant="body2">
                          Transfer Record Rejected
                        </Typography>
                      </div>
                    )}
                    {transferRecordApproved && (
                      <div
                        className="d-flex f-align-center ml-2"
                        key="Transfer Record Accepted"
                      >
                        <div
                          className={clsx(
                            "mr-2",
                            classes.legendHint,
                            classes["isAccepted"]
                          )}
                        ></div>
                        <Typography variant="body2">
                          Transfer Record Accepted
                        </Typography>
                      </div>
                    )}
                  </>
                )}
                {tabIndex === 2 && transferScheduleIsAccepted && (
                  <div
                    className="d-flex f-align-center ml-2"
                    key="Transfer Record Accepted"
                  >
                    <div
                      className={clsx(
                        "mr-2",
                        classes.legendHint,
                        classes["isAccepted"]
                      )}
                    ></div>
                    <Typography variant="body2">
                      Transfer Record Accepted
                    </Typography>
                  </div>
                )}
              </Box>
              <Grid xs={12} className={clsx({ "d-none": isActionBarOnScreen })}>
                <BottomNavigation
                  component={Paper}
                  elevation={4}
                  className={classes.bottomNavigation}
                >
                  <BottomNavigationAction
                    component={() => renderActionBar(true)}
                  />
                </BottomNavigation>
              </Grid>
            </>
          )}
      </Grid>
    </>
  );
};

export default ResponsiveView;
