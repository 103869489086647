import { useEffect } from "react";
import { noop } from "shared/constants";
import { DEDUCTION_REASONS, RATE_TYPES } from "modules/shared/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { preventInputKeyCodes } from "utils";
import InputAdornment from "@material-ui/core/InputAdornment";
import useStyles from "../style";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { EventAvailableTwoTone } from "@material-ui/icons";
import { Checkbox, Tooltip } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

let shouldShowAlert = {
  pay_rate: false,
  pay_quantity: false,
};
const ContractorsPanel = ({
  entry = {},
  isTabletView = false,
  vehicleTypeList = [],
  contractorList = [],
  settlementCompanyList = [],
  deductionReasonList = [],
  settlementOptions = noop,
  isPriceMatrixFetched = false,
  handlePayRateChange = noop,
  billPayPrice = noop,
  isCalculatePay = false,
  isCalculatePayToll = false,
  isCalculatePayMiles = false,
  // isPFSChecked = true,
  // handlePFSChecked = noop,
  isNew = false,
  defaultContractorState = {},
  selectedRoute = [],
  pay_rate_type = null,
  appData,
  getStandByRates = noop,
  calculateFuelSurcharge = noop,
  handleConfirmDontPay = noop,
  handleConfirmDontPayToll = noop,
  handleConfirmDontPayExtraMiles = noop,
  onFieldChange = noop,
  calculatePay = noop,
  calculatePayToll = noop,
  calculatePayMiles = noop,
  handleFieldBlur = noop,
  showFieldAlert = noop,
  calculateValues = noop,
  customerLocation = false,
  pdxCompany = "",
}) => {
  const classes = useStyles({ isTabletView });
  const handleFieldChange = (evt) => {
    const { name, value, type } = evt.currentTarget;
    onFieldChange(name, value, "contractors", type);
  };

  return (
    <div className="d-flex f-align-center f-wrap">
      <Autocomplete
        disableClearable
        className={classes.field}
        size="small"
        value={{
          label: `${`${entry.individual_contractor?.last_name || ""} ${
            entry.individual_contractor?.first_name || ""
          }${
            entry.individual_contractor?.contractorTypeValue ===
            "WCP Master Contractor"
              ? ` - (${entry.individual_contractor?.contractorTypeValue})`
              : ""
          }`}`.trim(),
        }}
        options={contractorList.map((item) => ({
          ...item,
          label: `${item.icid || ""} - ${item.last_name || ""} ${
            item.first_name || ""
          }${
            vehicleTypeList.some((ele) => ele?.id === item.default_vehicle_type)
              ? `-(${
                  vehicleTypeList.find(
                    (ele) => ele.id === item.default_vehicle_type
                  ).value
                })`
              : ""
          }${
            item.contractorTypeValue === "WCP Master Contractor"
              ? `- (${item?.contractorTypeValue})`
              : ""
          }`,
        }))}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label={
              entry?.individual_contractor?.contractorTypeValue ===
              "WCP Master Contractor"
                ? "WCP Master Contractor"
                : "Name"
            }
            variant="outlined"
            error={!!entry.errors.individual_contractor.trim()}
            helperText={entry.errors.individual_contractor}
          />
        )}
        onChange={(evt, value) => {
          const isStandBy =
            (value?.contractorTypeValue || "").toLowerCase() === "stand-by";
          onFieldChange("individual_contractor", value, "contractors");
          onFieldChange(
            "settlement_company",
            settlementCompanyList.find(
              (item) => item?.id === value.default_settlement_company_id
            ),
            "contractors"
          );
          onFieldChange("representativeDriver", null, "contractors");
          const defaultValue =
            pay_rate_type === "Hourly"
              ? "8"
              : pay_rate_type === "Daily"
              ? "1"
              : "";

          if (isStandBy) {
            onFieldChange(
              "pay_rate",
              !!selectedRoute.length &&
                selectedRoute[0]?.price_type === "Matrix"
                ? entry?.pay_rate || ""
                : getStandByRates(appData, {
                    individual_contractor: value,
                  })?.find((rate) => rate?.is_default)?.rate_value,
              "contractors"
            );
            onFieldChange(
              "pay_rate_type",
              !!selectedRoute.length &&
                selectedRoute[0]?.price_type === "Matrix"
                ? RATE_TYPES[0]
                : getStandByRates(appData, {
                    individual_contractor: value,
                  })?.find((rate) => rate?.is_default)?.rateType?.value,
              "contractors"
            );

            onFieldChange("pay_quantity", "1", "contractors");
          } else if (!!selectedRoute.length) {
            onFieldChange(
              "pay_quantity",
              !!selectedRoute.length
                ? selectedRoute[0]?.price_type === "Matrix"
                : "1"
                ? selectedRoute[0]?.default_pay_quantity
                : defaultValue || entry.pay_quantity,
              "contractors"
            );
            onFieldChange(
              "pay_rate",
              !!selectedRoute.length &&
                selectedRoute[0]?.price_type === "Matrix"
                ? entry.pay_rate || ""
                : selectedRoute[0]?.default_pay_rate || entry.pay_rate,
              "contractors"
            );
            onFieldChange(
              "pay_rate_type",
              !!selectedRoute.length &&
                selectedRoute[0]?.price_type === "Matrix"
                ? RATE_TYPES[0]
                : selectedRoute[0]?.default_pay_rate_type ||
                    entry.pay_rate_type,
              "contractors"
            );
          } else {
            onFieldChange("pay_quantity", defaultValue, "contractors");
            onFieldChange("pay_rate", 0, "contractors");
            onFieldChange("pay_rate_type", RATE_TYPES[0], "contractors");
          }

          // if (isNew) {
          //   onFieldChange(
          //     "pay_rate",
          //     value?.default_rate || defaultContractorState.pay_rate,
          //     "contractors"
          //   );
          //   onFieldChange(
          //     "pay_rate_type",
          //     value?.default_rate_type || defaultContractorState.pay_rate_type,
          //     "contractors"
          //   );
          // }
        }}
      />
      <TextField
        disabled={true}
        fullwidth
        className={classes.field}
        name="icid"
        label="ICID"
        variant="outlined"
        size="small"
        helperText=" "
        value={entry.individual_contractor?.icid || ""}
      />
      {entry?.individual_contractor?.contractorTypeValue ===
        "WCP Master Contractor" && (
        <Autocomplete
          size="small"
          className={classes.driverField}
          fullWidth
          value={entry.representativeDriver}
          options={entry.representativeDriverList}
          getOptionLabel={(option) =>
            `${option.first_name} ${option.last_name || ""}`
          }
          renderInput={(params) =>
            entry.isFetchingDriverList ? (
              <Skeleton variant="rect" width="100%" height="40px" />
            ) : (
              <TextField
                {...params}
                label="WCP-Driver"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                      <InputAdornment
                        position="end"
                        className={classes.weekInputProps}
                      >
                        <Tooltip
                          title="This is the list of representative drivers for WCP."
                          placement="top"
                        >
                          <InfoOutlinedIcon
                            fontSize="small"
                            className={classes.infoIcon}
                          />
                        </Tooltip>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )
          }
          onChange={(evt, value) => {
            onFieldChange("representativeDriver", value, "contractors");
          }}
        />
      )}
      <Autocomplete
        size="small"
        className={classes.field}
        value={entry.settlement_company}
        options={settlementCompanyList}
        getOptionLabel={(option) => option.value}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Settlement Company"
            variant="outlined"
            error={!!entry.errors.settlement_company.trim()}
            helperText={entry.errors.settlement_company}
            required
          />
        )}
        onChange={(evt, value) => {
          onFieldChange("settlement_company", value, "contractors");
        }}
      />
      <Autocomplete
        disabled={
          !!selectedRoute.length && selectedRoute[0]?.price_type === "Matrix"
        }
        disableClearable
        className={classes.field}
        size="small"
        value={entry.pay_rate_type}
        options={
          entry?.stanbyRateTypeArray?.length > 0
            ? entry.stanbyRateTypeArray
                .map((type) => type?.rateType?.value || type)
                .sort((a, b) => a.localeCompare(b))
            : getStandByRates(appData, entry)?.length > 0
            ? getStandByRates(appData, entry)
                .map((type) => type?.rateType?.value)
                .sort((a, b) => a.localeCompare(b))
            : RATE_TYPES
        }
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Pay Rate Type"
            variant="outlined"
            error={!!entry.errors.pay_rate_type.trim()}
            helperText={entry.errors.pay_rate_type}
          />
        )}
        onChange={(evt, value) => {
          const selectedRate =
            (entry?.stanbyRateTypeArray?.length > 0
              ? entry.stanbyRateTypeArray
              : getStandByRates(appData, entry)
            )?.find((rate) => rate?.rateType?.value === value) || null;
          if (entry.pay_rate_type === value) {
            return false;
          } else {
            onFieldChange(
              "pay_rate_type",
              selectedRate?.rateType?.value || value,
              "contractors",
              "",
              true
            );
            onFieldChange(
              "pay_rate",
              selectedRate?.rate_value || entry.pay_rate,
              "contractors"
            );
            !!selectedRate && onFieldChange("pay_quantity", "1", "contractors");
            !isNew &&
              showFieldAlert("pay_rate_type", {
                ...entry,
                pay_quantity: !!selectedRate
                  ? "1"
                  : value === "Hourly"
                  ? "8"
                  : value === "Daily"
                  ? "1"
                  : "",
                pay_rate_type: selectedRate?.rateType?.value || value,
                pay_rate: selectedRate?.rate_value || entry.pay_rate,
                pdxCompany,
              });
          }
        }}
      />
      <TextField
        required
        className={classes.field}
        size="small"
        type="number"
        name="pay_quantity"
        label="Pay Quantity"
        variant="outlined"
        value={entry.pay_quantity}
        disabled={
          !!selectedRoute.length && selectedRoute[0]?.price_type === "Matrix"
        }
        onWheel={(event) => {
          event.target.blur();
        }}
        //onChange={handleFieldChange}
        onChange={(evt) => {
          const { name, value, type } = evt.currentTarget;
          shouldShowAlert[name] = true;
          onFieldChange(name, value, "contractors", type);

          const payFuelAmount = (
            +calculateValues({
              ...entry,
              pay_quantity: value,
            }).pay_fuel_amount || 0
          ).toFixed(2);
          onFieldChange(
            "pay_fuel_amount",
            payFuelAmount,
            "contractors",
            "number"
          );
        }}
        error={!!entry.errors.pay_quantity.trim()}
        helperText={entry.errors.pay_quantity}
        onKeyDown={preventInputKeyCodes}
        onBlur={(evt) => {
          const { name } = evt.target;
          if (shouldShowAlert[name]) {
            shouldShowAlert[name] = false;
            handleFieldBlur(evt);
          }
        }}
        InputProps={{
          endAdornment: entry.pay_rate_type === "Hourly" &&
            customerLocation && (
              <InputAdornment position="end">
                <Tooltip title="Max Limit 10000" placement="top">
                  <InfoOutlinedIcon
                    style={{ cursor: "pointer" }}
                    color="disabled"
                    fontSize="small"
                  />
                </Tooltip>
              </InputAdornment>
            ),
        }}
      />
      {!!selectedRoute.length && selectedRoute[0]?.price_type === "Matrix" && (
        <TextField
          className={classes.field}
          disabled={true}
          label="Price Matrix Name"
          variant="outlined"
          size="small"
          value={
            !!selectedRoute.length ? selectedRoute[0]?.matrix_name || "" : ""
          }
          helperText=" "
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      {selectedRoute?.length === 0 ||
      selectedRoute[0]?.price_type === null ||
      selectedRoute[0]?.price_type === "Standard" ? (
        <TextField
          disabled={
            (
              entry.individual_contractor?.contractorTypeValue || ""
            ).toLowerCase() === "stand-by" || isCalculatePay
          }
          required
          fullWidth
          className={classes.fieldRate}
          size="small"
          type="number"
          name="pay_rate"
          label="Pay Rate"
          variant="outlined"
          onWheel={(event) => {
            event.target.blur();
          }}
          value={entry.pay_rate}
          //onChange={handleFieldChange}
          onChange={(evt) => {
            const { name, value, type } = evt.currentTarget;
            shouldShowAlert[name] = true;
            onFieldChange(name, value, "contractors", type);
            const payFuelAmount = (
              +calculateValues({
                ...entry,
                pay_rate: value,
              }).pay_fuel_amount || 0
            ).toFixed(2);
            onFieldChange(
              "pay_fuel_amount",
              payFuelAmount,
              "contractors",
              "number"
            );
          }}
          error={!!entry.errors?.pay_rate?.trim()}
          helperText={entry.errors?.pay_rate}
          onKeyDown={(evt) => preventInputKeyCodes(evt, { HYPHEN: true })}
          InputProps={{
            startAdornment: <InputAdornment>$</InputAdornment>,
          }}
          onBlur={(evt) => {
            const { name } = evt.target;
            if (shouldShowAlert[name]) {
              shouldShowAlert[name] = false;
              handleFieldBlur(evt);
            }
          }}
        />
      ) : (
        <Autocomplete
          disableClearable
          disabled={isCalculatePay}
          size="small"
          className={classes.field}
          value={entry.pay_rate || ""}
          style={{ marginBottom: "31px" }}
          defaultValue={entry.pay_rate || ""}
          options={settlementOptions}
          getOptionLabel={(option) => {
            const matchingBillPayPrice = billPayPrice?.find(
              (billOption) => billOption?.pay_price == option
            );
            if (matchingBillPayPrice) {
              return `$ ${option} (${matchingBillPayPrice.quantity})`;
            } else {
              return `$ ${option}`;
            }
          }}
          renderInput={(params) =>
            isPriceMatrixFetched ? (
              <Skeleton variant="rect" width="100%" height="36px" />
            ) : (
              <TextField
                {...params}
                required
                label="Pay Rate"
                variant="outlined"
              />
            )
          }
          getOptionSelected={(option, value) => option === value}
          onChange={(event, value) => {
            handlePayRateChange(event, value);
            const payFuelAmount = (
              +calculateValues({
                ...entry,
                pay_rate: value,
              }).pay_fuel_amount || 0
            ).toFixed(2);
            onFieldChange(
              "pay_fuel_amount",
              payFuelAmount,
              "contractors",
              "number"
            );
          }}
        />
      )}
      {/* <Tooltip title="Do not Pay, if this is checked">
        <Checkbox 
        className="mb-6"
        checked={isCalculatePay} 
        onChange={(evt)=>

          {
            if(evt.target.checked === true){
              handleConfirmDontPay(evt.target.checked)
            }
            else{
              calculatePay(evt.target.checked)
            }
          }
          
        
        
        } 
        name="Pay" />
      </Tooltip> */}
      <FormControlLabel
        value="top"
        // disabled={
        // !!selectedRoute.length
        //  && selectedRoute[0]?.price_type === "Matrix"
        // }
        control={
          <Checkbox
            className="mb-1 mr-8"
            checked={
              // !!selectedRoute.length &&
              // selectedRoute[0]?.price_type === "Matrix"
              //   ? false
              //   : isCalculatePay
              isCalculatePay
            }
            onChange={(evt) => {
              if (evt.target.checked === true) {
                handleConfirmDontPay(evt.target.checked);
              } else {
                calculatePay(evt.target.checked);
              }
            }}
            name="Pay"
          />
        }
        className="mb-8 mt-0 ml-6"
        label="Do not pay, if checked:"
        labelPlacement="start"
      />
      <TextField
        fullWidth
        size="small"
        type="number"
        disabled={true}
        className={classes.field}
        name="pay_amount"
        label="Pay Amount"
        variant="outlined"
        value={(+calculateValues(entry).pay_amount || 0).toFixed(2)}
        helperText=" "
        InputProps={{
          startAdornment: <InputAdornment>$</InputAdornment>,
        }}
      />
      <TextField
        fullWidth
        className={classes.field}
        size="small"
        type="number"
        name="pay_fuel_surcharge"
        label="Pay Fuel Surcharge%"
        variant="outlined"
        value={entry.pay_fuel_surcharge}
        onWheel={(event) => event.target.blur()}
        onChange={(evt) => {
          const { name, value, type } = evt.currentTarget;
          onFieldChange(name, value, "contractors", type);
          const payFuelAmount = (
            +calculateValues({
              ...entry,
              pay_fuel_surcharge: value,
            }).pay_fuel_amount || 0
          ).toFixed(2);
          onFieldChange(
            "pay_fuel_amount",
            payFuelAmount,
            "contractors",
            "number"
          );
        }}
        onKeyDown={preventInputKeyCodes}
        error={!!entry.errors.pay_fuel_surcharge.trim()}
        helperText={entry.errors.pay_fuel_surcharge}
        InputProps={{
          endAdornment: <InputAdornment>%</InputAdornment>,
        }}
      />
      <TextField
        fullWidth
        className={classes.field}
        size="small"
        type="number"
        name="pay_fuel_amount"
        label="Pay Fuel Amount"
        variant="outlined"
        onKeyDown={preventInputKeyCodes}
        value={(+entry.pay_fuel_amount).toFixed(2)}
        disabled
        // onChange={(evt) => {
        //   const { name, value, type } = evt.currentTarget;
        //   onFieldChange(name, value, "contractors", type);
        //   const payFuelSurcharge = (
        //     +calculateValues({
        //       ...entry,
        //       pay_fuel_amount: value,
        //     }).pay_fuel_surcharge || 0
        //   ).toFixed(2);
        //   console.log(payFuelSurcharge, "ds");
        //   onFieldChange(
        //     "pay_fuel_surcharge",
        //     payFuelSurcharge,
        //     "contractors",
        //     "number"
        //   );
        // }}
        error={!!entry.errors.pay_fuel_amount.trim()}
        helperText={entry.errors.pay_fuel_amount}
        InputProps={{
          startAdornment: <InputAdornment>$</InputAdornment>,
        }}
      />
      <FormControlLabel
        value="top"
        disabled={isCalculatePay}
        control={
          <Checkbox
            className="mb-1 mr-8"
            checked={isCalculatePayMiles}
            onChange={(evt) => {
              if (evt.target.checked === true) {
                handleConfirmDontPayExtraMiles(evt.target.checked);
              } else {
                calculatePayMiles(evt.target.checked);
              }
            }}
            name="do_not_pay_extra_miles"
          />
        }
        className="mb-8 mt-0 ml-0"
        label="Do not pay extra miles, if checked:"
        labelPlacement="start"
      />
      <TextField
        className={classes.field}
        size="small"
        type="number"
        name="pay_extra_miles"
        disabled={isCalculatePay || isCalculatePayMiles}
        onKeyDown={preventInputKeyCodes}
        label="Pay Extra Miles"
        onWheel={(event) => event.target.blur()}
        variant="outlined"
        value={entry.pay_extra_miles}
        onChange={handleFieldChange}
        error={!!entry?.errors?.pay_extra_miles.trim()}
        helperText={entry?.errors?.pay_extra_miles}
      />
      <TextField
        className={classes.field}
        size="small"
        type="number"
        name="pay_rate_per_extra_mile"
        onWheel={(event) => event.target.blur()}
        onKeyDown={(evt) => preventInputKeyCodes(evt, { HYPHEN: true })}
        label="Pay Rate Per Extra Mile"
        variant="outlined"
        value={entry.pay_rate_per_extra_mile}
        // value={
        //   Number.isInteger(+entry.pay_rate_per_extra_mile || 0)
        //     ? +entry.pay_rate_per_extra_mile
        //     : (
        //         Math.round(
        //           (+entry.pay_rate_per_extra_mile + Number.EPSILON) * 100
        //         ) / 100
        //       ).toFixed(2)
        // }
        onChange={handleFieldChange}
        InputProps={{
          startAdornment: <InputAdornment>$</InputAdornment>,
        }}
        error={!!entry.errors.pay_rate_per_extra_mile.trim()}
        helperText={entry.errors.pay_rate_per_extra_mile}
      />
      <TextField
        disabled
        className={classes.field}
        size="small"
        type="number"
        name="pay_extra_mile_amount"
        label="Pay Extra Miles Amount"
        variant="outlined"
        value={entry.pay_extra_mile_amount}
        InputProps={{
          startAdornment: <InputAdornment>$</InputAdornment>,
        }}
        helperText=" "
      />{" "}
      <FormControlLabel
        value="top"
        disabled={isCalculatePay}
        control={
          <Checkbox
            className="mb-1 mr-8"
            checked={isCalculatePayToll}
            onChange={(evt) => {
              if (evt.target.checked === true) {
                handleConfirmDontPayToll(evt.target.checked);
              } else {
                calculatePayToll(evt.target.checked);
              }
            }}
            name="do_not_pay_toll_amount"
          />
        }
        className="mb-8 mt-0 ml-0"
        label="Do not pay toll, if checked:"
        labelPlacement="start"
      />
      <TextField
        className={classes.field}
        disabled={isCalculatePay || isCalculatePayToll}
        size="small"
        type="number"
        name="pay_toll_amount"
        label="Pay Toll Amount"
        variant="outlined"
        onWheel={(event) => event.target.blur()}
        onKeyDown={(evt) => preventInputKeyCodes(evt, { HYPHEN: true })}
        InputProps={{
          startAdornment: <InputAdornment>$</InputAdornment>,
        }}
        value={entry.pay_toll_amount}
        onChange={handleFieldChange}
        error={!!entry.errors.pay_toll_amount.trim()}
        helperText={entry.errors.pay_toll_amount}
      />
      <TextField
        fullWidth
        className={classes.field}
        size="small"
        type="number"
        name="reimbursement"
        label="Incentive"
        onKeyDown={preventInputKeyCodes}
        variant="outlined"
        value={entry.reimbursement || 0}
        onWheel={(event) => event.target.blur()}
        onChange={handleFieldChange}
        error={!!entry.errors.reimbursement.trim()}
        helperText={entry.errors.reimbursement}
        InputProps={{
          startAdornment: <InputAdornment>$</InputAdornment>,
        }}
      />
      <TextField
        fullWidth
        size="small"
        className={classes.field}
        type="number"
        name="deduction"
        label="Deduction"
        variant="outlined"
        value={entry.deduction}
        onWheel={(event) => event.target.blur()}
        onChange={handleFieldChange}
        onKeyDown={(evt) => preventInputKeyCodes(evt, { HYPHEN: true })}
        error={!!entry.errors.deduction.trim()}
        helperText={entry.errors.deduction}
        InputProps={{
          startAdornment: <InputAdornment>$</InputAdornment>,
        }}
      />
      <Autocomplete
        className={classes.field}
        size="small"
        disabled={entry.deduction == 0 || !entry.deduction}
        value={entry.deduction_reason}
        getOptionLabel={(option) => option?.concatName}
        options={deductionReasonList}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Deduction Reason"
            name="deduction_reason"
            variant="outlined"
            helperText=" "
            required={
              entry.deduction !== 0 &&
              entry.deduction !== "" &&
              entry.deduction !== "0"
            }
          />
        )}
        getOptionSelected={(option, value) => option?.id === value?.id}
        onChange={(evt, value) =>
          onFieldChange("deduction_reason", value, "contractors")
        }
        classes={{
          root: classes.autocompletePaper,
          listbox: classes.autocompletePaper,
        }}
      />
    </div>
  );
};

export default ContractorsPanel;
