import { useMemo } from "react";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { Dialog } from "../";

const noop = () => {};
const ActionDialog = ({
  classes = {},
  content = null,
  contentText = "Are you sure you want to perform this operation?",
  contentVariant = "body1",
  hasClose = true,
  hasPreferenceCheck = false,
  heading = "",
  isConfirmation = false,
  disableBackdropClick = false,
  negativeActionLabel = "Cancel",
  onCancel = noop,
  onClose = null,
  onConfirm = noop,
  open = false,
  onPreferenceChange = noop,
  positiveActionLabel,
  preferenceChecked = false,
  showPositiveActionLabel = true,
  preferenceLabel = "Do not show this message again.",
  isConfirmDisabled = false,
}) => {
  if (!positiveActionLabel) {
    if (isConfirmation) {
      positiveActionLabel = "Confirm";
    } else {
      positiveActionLabel = "Ok";
    }
  }
  const dialogClasses = useMemo(() => {
    const dialogClasses = { ...classes };
    delete dialogClasses.confirm;
    delete dialogClasses.cancel;
    delete dialogClasses.content;
    delete dialogClasses.preferenceWrapper;
    delete dialogClasses.preferenceLabel;
    delete dialogClasses.checkbox;
    return dialogClasses;
  }, [classes]);

  return (
    <Dialog
      classes={{
        ...dialogClasses,
        paper: `${dialogClasses.paper} ${
          isConfirmation ? "confirmation-dialog" : "info-dialog"
        }`,
      }}
      open={open}
      onClose={onClose || onCancel}
      centered
      data-testid="action-dialog-wrapper"
      disableBackdropClick={disableBackdropClick}
    >
      <Dialog.Title hasClose={hasClose}>{heading}</Dialog.Title>
      <Dialog.Content>
        {content ? (
          <>{content}</>
        ) : (
          <div
            className={clsx(
              classes.content,
              "d-flex flex-column f-justify-between h-100 color-text-secondary"
            )}
          >
            <Typography variant={contentVariant}>{contentText}</Typography>
            {hasPreferenceCheck && (
              <div
                className={clsx(
                  classes.preferenceWrapper,
                  "d-flex f-align-center mt-5"
                )}
              >
                <Checkbox
                  className={clsx(classes.checkbox, "mr-3")}
                  checked={preferenceChecked}
                  onChange={onPreferenceChange}
                />
                <Typography className={classes.preferenceLabel} variant="body1">
                  {preferenceLabel}
                </Typography>
              </div>
            )}
          </div>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        {((isConfirmation && onCancel && onCancel !== noop) ||
          (onCancel && onCancel !== noop)) && (
          <Button
            className={clsx(
              isConfirmDisabled ? "not-allowed" : classes.cancel,
              "m-4"
            )}
            onClick={onCancel}
            disabled={isConfirmDisabled}
          >
            {/* <Typography variant='body2'>{negativeActionLabel}</Typography> */}
            {negativeActionLabel}
          </Button>
        )}
        {showPositiveActionLabel && (
          <Button
            className={clsx(
              isConfirmDisabled ? "not-allowed" : classes.confirm,
              "m-4"
            )}
            color="secondary"
            disableElevation
            onClick={onConfirm}
            variant="contained"
            disabled={isConfirmDisabled}
          >
            {/* <Typography variant='body2'>{positiveActionLabel}</Typography> */}
            {positiveActionLabel}
          </Button>
        )}
      </Dialog.Actions>
    </Dialog>
  );
};

export default ActionDialog;
