/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Tooltip, Typography } from "@material-ui/core";
import useStyles from "./style";
import clsx from "clsx";
import { Grid, noop } from "shared";

import { getUTCDateString, LoadingOverlay, updateLayout } from "utils";
import { Fragment, useEffect, useState } from "react";
import { modeOfPaymentList } from "modules/shared/constants";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { toast } from "react-toastify";
import Service from "modules/invoice/service";
import CreditMemo from "modules/invoice/credit-memo/credit-memo-form";

const defaultState = {
  showCreditMemo: false,
  isFetchingCreditMemoData: false,
  creditMemoDetails: null,
};

const PaymentSummaryGrid = ({
  isFetchingData = false,
  entries = [],
  handleUpdatePayment = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  const columnConfig = [
    {
      id: "payment_number",
      label: "Payment No.",
      field: "payment_number",
      render: (row) => {
        const title = row.payment_number;
        return (
          <div className="d-flex f-align-center">
            <Tooltip title={title} placement="top-start">
              <Typography
                onClick={() => handleUpdatePayment(row)}
                variant="body2"
                style={{
                  color: "#775edc",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                {title}
              </Typography>
            </Tooltip>
            {row?.credit_memo_id && (
              <Tooltip
                title={
                  <Fragment>
                    <p>Credit Memo No. - {row.creditMemoRecord?.credit_no}</p>
                    <p>
                      Credit Memo Date -{" "}
                      {getUTCDateString(row.creditMemoRecord?.credit_memo_date)}
                    </p>
                  </Fragment>
                }
                placement="top-start"
              >
                <span
                  onClick={() => getCreditMemo(row.credit_memo_id)}
                  style={{ cursor: "pointer" }}
                >
                  <ReceiptIcon color="primary" className="ml-2 mt-1" />
                </span>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      id: "date",
      label: "Payment Date",
      field: "date",
      render: (row) => {
        const title = getUTCDateString(row.date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "invoice_number",
      label: "Invoice No.",
      field: "invoice_number",
      render: (row) => {
        const title = row.invoice_number;
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2" noWrap>
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "invoice_date",
      label: "Invoice Date",
      field: "invoice_date",
      render: (row) => {
        const title = getUTCDateString(row.invoice.invoice_date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      field: "status",
      render: (row) => {
        const title =
          row.status && row.status === "reverted"
            ? "Cancelled"
            : row.status.charAt(0).toUpperCase() + row.status.slice(1);
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2">{title}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "reference_number",
      label: "Reference Number",
      field: "reference_number",
      render: (row) => {
        const title = row.reference_number;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "amount",
      label: "Amount",
      field: "amount",
      render: (row) => {
        const title = `$${row?.amount || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "credit_amt",
      label: "Credit Amount",
      field: "credit_amt",
      render: (row) => {
        const title = `$ ${row?.credit_amt || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "mode",
      label: "Mode of Payment",
      field: "mode",
      render: (row) => {
        const title = modeOfPaymentList.find((ele) => ele.value === row?.mode);
        return (
          <Tooltip title={`${title.label || "-"}`} placement="top-start">
            <Typography variant="body2" noWrap>{`${
              title.label || "-"
            }`}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "payment_notes",
      label: "Payment Notes",
      field: "payment_notes",
      render: (row) => {
        const tooltip = row?.payment_notes || "-";
        const title = row?.payment_notes
          ? row?.payment_notes.length > 10
            ? `${row.payment_notes.slice(0, 10)}...`
            : `${row?.payment_notes}`
          : "-";
        return (
          <Tooltip title={tooltip} placement="top-start">
            <Typography variant="body2" noWrap>
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    setTimeout(updateLayout, 1000);
  }, [entries]);

  const getCreditMemo = async (id) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingCreditMemoData: true,
    }));

    const query = `?filter[where][id]=${id}`;

    const { data, error } = await Service.getCreditMemo(query);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingCreditMemoData: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingCreditMemoData: false,
      showCreditMemo: true,
      creditMemoDetails: data.rows[0] || defaultState.creditMemoDetails,
    }));
  };

  const handleCloseCreditMemo = (close) => {
    setState((prevState) => ({
      ...prevState,
      showCreditMemo: close,
    }));
  };

  return (
    <>
      {state.isFetchingCreditMemoData && (
        <LoadingOverlay message="Processing, please wait..." />
      )}
      <Paper
        element={4}
        className={clsx(
          "mb-4 mt-8",
          classes.invoice,
          state.isFetchingCreditMemoData && classes.blur
        )}
        style={{ position: "relative" }}
      >
        <Typography variant="h6" className="m-2" color="primary">
          Other payments related to this invoice
        </Typography>
        <Grid
          columns={columnConfig}
          rows={entries}
          actionBarConfig={null}
          hasSelection={false}
          isLoading={isFetchingData}
          hasPagination={false}
        />
        {state.showCreditMemo && (
          <CreditMemo
            open={state.showCreditMemo}
            onClose={handleCloseCreditMemo}
            viewOnlyCreditMemo={state.showCreditMemo}
            creditDetails={state.creditMemoDetails}
            heading="View"
          />
        )}
      </Paper>
    </>
  );
};

export default PaymentSummaryGrid;
