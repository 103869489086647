/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useMemo,
  useCallback,
  createRef,
  memo,
  useState,
  useRef,
} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { noop } from "shared";
import { TableBody } from "@material-ui/core";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

const RecordTable = ({
  classes,
  passedClasses,
  hasSelection = false,
  selectableRows = [],
  state,
  columns = [],
  rows = [],
  handleSelectVisibleChange = noop,
  stableSort = noop,
  createSortHandler = noop,
  getComparator = noop,
  createSelectionHandler = noop,
  rowEvents = noop,
  gridRefs = null,
  handleScroll = noop,
  tableHeadRef = null,
  location,
  index,
}) => {
  const listRef = useRef(null);
  const externalScrollRef = useRef(null);
  let rafId = null;
  let resizeData = null;

  const columnRefs = useMemo(() => columns.map(() => createRef()), [columns]);

  const [columnWidths, setColumnWidths] = useState(() =>
    columns.map((col) => {
      const className = col.headerClassName;
      if (className) {
        const element = document.createElement("div");
        element.className = className;
        document.body.appendChild(element);
        const width = window.getComputedStyle(element).width;
        document.body.removeChild(element);
        return { minWidth: width, maxWidth: width };
      }
      return { minWidth: "auto", maxWidth: "auto" };
    })
  );

  const handleExternalScroll = useCallback((event) => {
    const scrollTop = event.target.scrollTop;
    if (listRef.current) {
      listRef.current.scrollTo(scrollTop);
    }
  }, []);

  const handleListScroll = useCallback(({ scrollOffset }) => {
    if (externalScrollRef.current) {
      externalScrollRef.current.scrollTop = scrollOffset;
    }
  }, []);

  const handleMouseMove = (event) => {
    if (!resizeData) return;

    const { initialCursorX, initialWidth, columnIndex, element } = resizeData;
    const diffX = event.pageX - initialCursorX;
    const newWidth = initialWidth + diffX;

    const isTargetColumn = element.textContent === "Review Notes ";
    const updatedWidth = isTargetColumn
      ? Math.max(newWidth, 367)
      : Math.max(newWidth, 100);

    if (rafId) {
      cancelAnimationFrame(rafId);
    }

    rafId = requestAnimationFrame(() => {
      element.style.minWidth = `${updatedWidth}px`;
      element.style.maxWidth = `${updatedWidth}px`;

      setColumnWidths((prevWidths) =>
        prevWidths.map((width, index) =>
          index === columnIndex
            ? {
                minWidth: `${updatedWidth}px`,
                maxWidth: `${updatedWidth}px`,
              }
            : width
        )
      );
    });
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    resizeData = null;
  };

  const initializeResize = (columnIndex, element) => {
    return (event) => {
      const initialCursorX = event.pageX;
      const initialWidth = element.offsetWidth;

      resizeData = {
        initialCursorX,
        initialWidth,
        columnIndex,
        element,
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };
  };

  const renderTableHeader = useMemo(() => {
    return (
      <TableHead ref={tableHeadRef} style={{ display: "grid" }}>
        <TableRow className={clsx(classes.row, passedClasses.header)}>
          {hasSelection && (
            <TableCell padding="checkbox" className="no-border">
              <Checkbox
                disabled={!selectableRows.length}
                indeterminate={
                  state.selectedRows.length > 0 &&
                  state.selectedRows.length < selectableRows.length
                }
                checked={
                  selectableRows.length > 0 &&
                  state.selectedRows.length === selectableRows.length
                }
                onChange={handleSelectVisibleChange}
                inputProps={{ "aria-label": "select visible" }}
              />
            </TableCell>
          )}
          {columns.map((column, columnIndex) => {
            if (column.isHidden) {
              return null;
            }
            columnRefs[columnIndex] = createRef();
            let content = <span></span>;
            if (column.renderHeader) {
              content = column.renderHeader();
            } else {
              content = column.canSort ? (
                <TableSortLabel
                  key={column.id}
                  active={state.orderBy === column.id}
                  direction={state.orderBy === column.id ? state.order : "asc"}
                  onClick={createSortHandler(column.id)}
                >
                  {column.label}
                  {state.orderBy === column.id ? (
                    <span className={classes.visuallyHidden}>
                      {state.order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                <span>{column.label}</span>
              );
            }

            return (
              <TableCell
                key={column.id}
                align={column.numeric ? "right" : "left"}
                padding={column.disablePadding ? "none" : "default"}
                sortDirection={
                  state.orderBy === column.id ? state.order : false
                }
                className={clsx(
                  "no-border p-relative",
                  classes.tableCell,
                  classes.tableHeadCell,
                  classes.tableHeadPadding,
                  column.headerClassName
                )}
                style={{
                  minWidth: columnWidths[columnIndex].minWidth,
                  maxWidth: columnWidths[columnIndex].maxWidth,
                }}
                ref={columnRefs[columnIndex]}
              >
                <Tooltip
                  title={column.tooltip || column.label}
                  placement={column.tooltipPlacement || "top-start"}
                >
                  <div className="d-flex f-justify-between">
                    {content}
                    {!column.noResize && (
                      <div
                        className={clsx(
                          "d-flex f-justify-between f-align-center p-absolute",
                          classes.resizeIconWrapper
                        )}
                        data-role="resize"
                        onMouseDown={(event) => {
                          if (
                            columnRefs[columnIndex] &&
                            columnRefs[columnIndex].current
                          ) {
                            initializeResize(
                              columnIndex,
                              columnRefs[columnIndex].current
                            )(event);
                          }
                        }}
                      >
                        <div className={classes.resizeIcon} />
                      </div>
                    )}
                  </div>
                </Tooltip>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }, [
    columns,
    state,
    handleSelectVisibleChange,
    createSortHandler,
    columnRefs,
    classes,
    passedClasses,
    hasSelection,
    selectableRows,
    columnWidths,
  ]);

  const RowRenderer = useCallback(
    ({ index, style }) => {
      const sortedRows = stableSort(
        rows,
        getComparator(state.order, state.orderBy)
      );
      const row = sortedRows[index];
      const selectionHandler = createSelectionHandler(row.id);

      let content = <></>;
      if (rows.render) {
        content = rows.render();
      } else {
        const labelId = `table-checkbox-${index}`;
        content = (
          <>
            {hasSelection && (
              <TableCell padding="checkbox" className="no-border">
                <Checkbox
                  disabled={row.preventSelection}
                  checked={state.selectedRows.indexOf(row.id) !== -1}
                  inputProps={{ "aria-labelledby": labelId }}
                  onChange={selectionHandler}
                />
              </TableCell>
            )}
            {columns.map((column, columnIndex) => {
              if (column.isHidden) {
                return null;
              }
              let content = <span>{row[column.field] || ""}</span>;
              if (column.render) {
                content = column.render(row, row.rowIndex);
              } else {
                const displayValue = row[column.field];
                content = (
                  <Typography
                    className="d-inline-block w-100"
                    variant="body2"
                    component="span"
                    noWrap={column.hasEllipses}
                  >
                    {`${
                      displayValue === null || displayValue === undefined
                        ? ""
                        : column.startAdornment ?? ""
                    }${displayValue || column.placeholder || "-"}${
                      displayValue === null || displayValue === undefined
                        ? ""
                        : column.endAdornment ?? ""
                    }`}
                  </Typography>
                );
              }

              return (
                <TableCell
                  className={clsx(
                    "no-border",
                    classes.tableCell,
                    classes.tableHeadPadding,
                    row.classes && row.classes[column.field]
                  )}
                  key={`${column.label || "grid-column"}-${columnIndex}${
                    row.id
                  }`}
                  style={{
                    minWidth: columnWidths[columnIndex].minWidth,
                    maxWidth: columnWidths[columnIndex].maxWidth,
                  }}
                >
                  {content}
                </TableCell>
              );
            })}
          </>
        );
      }

      const eventHandlers = rowEvents.reduce((acc, { type, handler }) => {
        acc[type] = (e) => handler(row, index, e);
        return acc;
      }, {});

      const handleTouchEnd = (e) => {
        if (eventHandlers.onDoubleClick) {
          eventHandlers.onDoubleClick(e);
        }
      };

      return (
        <TableRow
          hover
          key={row.id}
          style={style}
          className={clsx(classes.row, passedClasses.row, row.className)}
          {...eventHandlers}
          onDoubleClick={handleTouchEnd}
        >
          {content}
        </TableRow>
      );
    },
    [
      rows,
      state.order,
      state.orderBy,
      columns,
      hasSelection,
      classes,
      passedClasses,
      rowEvents,
      createSelectionHandler,
      stableSort,
      getComparator,
      columnWidths,
      state.selectedRows,
    ]
  );

  const setGridRef = (key, node) => {
    if (node) {
      gridRefs.current[key] = node;
    } else {
      delete gridRefs.current[key];
    }
  };

  return (
    <TableContainer
      ref={(node) => setGridRef(`${location.id}-${index}`, node)}
      onScroll={handleScroll}
      style={{
        display: "grid",
        overflow: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "&-ms-overflow-style:": {
          display: "none",
        },
        overflowY: "hidden",
      }}
    >
      <div className="d-flex">
        <Table>
          {renderTableHeader}
          <TableBody>
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  height={Math.min(400, 50 * (rows.length + 1))}
                  onScroll={handleListScroll}
                  itemCount={rows.length}
                  itemSize={50}
                  width={width}
                  style={{ overflowX: "hidden", scrollbarWidth: "none" }}
                  ref={listRef}
                >
                  {RowRenderer}
                </List>
              )}
            </AutoSizer>
          </TableBody>
        </Table>
        <div
          className={classes.stickyScrollbar}
          ref={externalScrollRef}
          onScroll={handleExternalScroll}
          style={{
            height: Math.min(400, 50 * (rows.length + 1)),
            overflowY: "auto",
            top: 65,
          }}
        >
          <div style={{ height: `${rows.length * 50}px` }}>&nbsp;</div>
        </div>
      </div>
    </TableContainer>
  );
};

export default memo(RecordTable);
