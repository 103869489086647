import makeStyles from "@material-ui/core/styles/makeStyles";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles((theme) => ({
  fixedHeader: {
    width: "100%",
    top: "64px",
    marginBottom: "12px",
    height: "64px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
  datepickerWrapper: {
    width: 160,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  outlinedInputError: {
    "& .MuiOutlinedInput-root.Mui-error": {
      "& fieldset": {
        borderColor: theme.palette.error.main,
      },
    },
  },
  filterFields: {
    marginLeft: 12,

    minWidth: 200,
    maxWidth: 200,
    [theme.breakpoints.down("sm")]: {
      minWidth: 244,
      maxWidth: 244,
      marginLeft: 0,
      marginBottom: 12,
      marginRight: 10,
    },
  },
  footerContainer: {
    padding: "10px",
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    zIndex: 1000,
  },
  content: {
    flexWrap: "wrap",
  },
  dialogPaper: (props) => ({
    maxWidth: props.isTabletView ? "98%" : "97%",
    minWidth: props.isTabletView ? "98%" : "97%",

    maxHeight: props.isTabletView ? "95%" : "90%",
  }),
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
    width: "100%",
    height: "100%",
  },
  paymentMargin: {
    marginTop: 37,
  },
  paper: (props) => ({
    minWidth: props.isTabletView ? 330 : 412,
  }),
  tabletView: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
  },
  creditNo: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  customerDropdown: {
    width: 180,
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  city: {
    marginLeft: "0px !important",
    width: "100% !important",
    marginRight: "8px !important",
  },
  paperIframe: {
    minWidth: "600px !important",
    maxWidth: "1200px !important",
    minHeight: "600px !important",
    maxHeight: "1200px !important",
  },
  state: { marginLeft: "8px !important", width: "100% !important" },
  zip: {
    marginLeft: "0px !important",
    width: "100% !important",
    marginRight: "8px !important",
  },
  country: { marginLeft: "8px !important", width: "100% !important" },
  phoneNumber: {
    marginLeft: "0px !important",
    width: "100% !important",
    marginRight: "8px !important",
  },
  contact: {
    width: "100% !important",
    marginLeft: "0px !important",
  },
  StreetAddress: {
    marginLeft: "0px !important",
    width: "100% !important",
    marginRight: "4px !important",
  },
  wrapper: {
    height: "calc(100vh - 550px)",
  },
  griddddd: {
    [theme.breakpoints.down("sm")]: {
      height: (props) => (props.isRoleKAM ? 232 : 146),
    },
    height: 240,
  },
  iconButton: {
    borderColor: "#58585a",
    borderRadius: 8,
    width: 128,
    [theme.breakpoints.down("sm")]: {
      width: (props) => props.isRoleKAM && 198,
    },
  },
  icon: {
    [theme.breakpoints.down("sm")]: {
      height: (props) => props.isRoleKAM && "148px",
    },
    height: "75px",
  },
  dahsboardItemTitle: {
    color: grey[900],
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: (props) => props.isRoleKAM && 22,
    },
  },
  blur: {
    filter: "blur(5px)",
  },
  input: {
    height: 160,
  },
  customerNotes: {
    marginTop: 32,
    marginBottom: 8,
  },
  paperSize: {
    width: 416,
  },
  actionsTableHeaderwidth: {
    minWidth: "34px !important",
    maxWidth: "34px !important",
  },
  tableHeaderwidth: {
    minWidth: "64px !important",
    maxWidth: "64px !important",
  },
  sentIcon: {
    border: "1px solid #004700",
    borderRadius: "4px",
    padding: " 4.2px",
    marginLeft: "12px",
    float: "right",
  },
  backArrow: {
    color: "#004700",
  },
  deletPaperSize: {
    width: "400px",
  },
}));

export default useStyles;
