import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { noop } from "shared/constants";
import useStyles from "./style";
import RecordTable from "./recordTable";

const defaultState = {
  order: "asc",
  orderBy: "",
  selectedRows: [],
};

const Grid = ({
  columns = [],
  rows = [],
  hasSelection = true,
  classes: passedClasses = {},
  rowEvents = noop,
  onReady = noop,
  onSelectionChange = noop,
  location,
  index,
  gridRefs = null,
  handleScroll = noop,
  tableHeadRef = null,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleSelection = useCallback(
    (rowId) => {
      if (!isMounted.current) return;
      setState((prevState) => {
        const selectedRows = prevState.selectedRows.includes(rowId)
          ? prevState.selectedRows.filter((id) => id !== rowId)
          : [...prevState.selectedRows, rowId];

        onSelectionChange(selectedRows);

        return {
          ...prevState,
          selectedRows,
        };
      });
    },
    [onSelectionChange]
  );

  const handleSelectVisibleChange = useCallback(
    (event) => {
      if (!isMounted.current) return;

      const isChecked = event.currentTarget.checked;
      let selectedRows = [];
      if (isChecked) {
        selectedRows = rows
          ?.filter((row) => !row.preventSelection)
          .map((row) => row.id);
      }

      onSelectionChange(selectedRows);
      setState((prevState) => ({
        ...prevState,
        selectedRows,
      }));
    },
    [rows, onSelectionChange]
  );

  const createSelectionHandler = useCallback(
    (rowId) => {
      return (event) => handleSelection(rowId, event);
    },
    [handleSelection]
  );

  const createSortHandler = useCallback(
    (property) => () => {
      if (!isMounted.current) return;

      const isAsc = state.orderBy === property && state.order === "asc";
      setState((prevState) => ({
        ...prevState,
        order: isAsc ? "desc" : "asc",
        orderBy: property,
      }));
    },
    [state.orderBy, state.order]
  );

  const descendingComparator = useCallback((a, b, orderBy) => {
    const splittedOrderBy = orderBy?.split(".") || [];
    let valA = a;
    let valB = b;

    for (const key of splittedOrderBy) {
      if (!key) break;
      valA = valA?.[key] ?? null;
      valB = valB?.[key] ?? null;
    }

    if (valA === null && valB !== null) return 1;
    if (valA !== null && valB === null) return -1;
    if (valA === null && valB === null) return 0;

    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: "base",
    });
    return collator.compare(valB, valA);
  }, []);

  const getComparator = useCallback(
    (order, orderBy) => {
      return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    },
    [descendingComparator]
  );

  const stableSort = useCallback((rows, comparator) => {
    const stabilized = rows.map((el, index) => [el, index]);
    stabilized.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilized.map((el) => el[0]);
  }, []);

  const selectableRows = useMemo(() => {
    return rows?.filter((row) => !row.preventSelection);
  }, [rows]);

  useEffect(() => {
    if (!isMounted.current) return;

    onReady({
      resetSelection: () => {
        if (isMounted.current) {
          setState((prevState) => ({
            ...prevState,
            selectedRows: [],
          }));
        }
      },
      setSelection: (selectedRows) => {
        if (isMounted.current) {
          setState((prevState) => ({
            ...prevState,
            selectedRows,
          }));
        }
      },
      getSelection: () => state.selectedRows,
      toggle: (open = false) => {
        if (isMounted.current) {
          setState((prevState) => ({
            ...prevState,
            open,
          }));
        }
      },
    });
  }, [onReady, state.selectedRows]);

  return (
    <RecordTable
      classes={classes}
      hasSelection={hasSelection}
      passedClasses={passedClasses}
      selectableRows={selectableRows}
      state={state}
      columns={columns}
      rows={rows}
      handleSelectVisibleChange={handleSelectVisibleChange}
      stableSort={stableSort}
      createSortHandler={createSortHandler}
      getComparator={getComparator}
      createSelectionHandler={createSelectionHandler}
      rowEvents={rowEvents}
      location={location}
      index={index}
      gridRefs={gridRefs}
      handleScroll={handleScroll}
      tableHeadRef={tableHeadRef}
    />
  );
};

export default Grid;
