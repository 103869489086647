/* eslint-disable react-hooks/exhaustive-deps */
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import useStyles from "./style";
import clsx from "clsx";
import {
  ActionDialog,
  AppContext,
  Dropdown,
  Grid,
  VALIDATIONS,
  noop,
} from "shared";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import PrintIcon from "@material-ui/icons/Print";
import DraftsIcon from "@material-ui/icons/Drafts";
import {
  getReadableFileSizeString,
  getTokenData,
  getUTCDateString,
  validator,
} from "utils";
import GridLoader from "shared/components/grid/loader";
import NoRecords from "assets/images/norecord.svg";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PictureAsPdf as PdfIcon } from "@material-ui/icons";
import DescriptionIcon from "@material-ui/icons/Description";
import Service from "modules/invoice/service";
import { toast } from "react-toastify";
import { Skeleton } from "@material-ui/lab";
import MailIcon from "@material-ui/icons/Mail";
import AddIcon from "@material-ui/icons/Add";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CancelIcon from "@material-ui/icons/Cancel";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import VisibilityIcon from "@material-ui/icons/Visibility";
import UploadFiles from "modules/invoice/file-upload";
import GenerateCreditMemoPDF from "../generate-credit-pdf";
import CreditMemo from "../credit-memo-form";
import { CREDIT_MEMO_STATUS, ROLE } from "modules/shared/constants";

let isConfirmDisabled = false;

const defaultState = {
  selectedOption: null,
  selectedCreditMemo: [],
  resendEmailsArray: [],
  uploadedAttachment: [],
  emailHistory: [],
  sentEmailHistory: false,
  isCreditEmailHistoryLoading: false,
  anchorEl: null,
  anchorE2: null,
  isEmailSent: false,
  open: false,
  email: "",
  from: "",
  emailSubject: "",
  emailBody: "",
  temporaryEmail: [],
  permanentEmail: [],
  errors: {
    email: " ",
    from: " ",
    emailSubject: " ",
    emailBody: " ",
  },
  isCreditmemoSending: false,
  showSendCreditePopup: false,
  isPreviousViewCreditMemo: null,
  isCreditMemoDeleteDialog: false,
  isCreditMemoDeleteLoading: false,
  isDeletingCreditMemo: false,
  thePdfUrl: "",
  pdfViewerDialog: false,
  showAddPermanentEmailDialog: false,
  isPermEmailLoading: false,
  isFetchingNewData: false,
  attachmentFiles: [],
};

const CreditMemoSummary = ({
  rows = [],
  selectedCreditMemo = null,
  usersList,
  isLoading = false,
  totalEntries = 0,
  pageSize = 100,
  isCreditMemoLoading = false,
  pageNumber = 1,
  isMarkAsSentLoading = false,
  pdfUrl = "",
  entry = {},
  creditMemoLoading = false,
  isPdfPreviewLoading = false,
  isPdfDownloadLoading = false,
  emailLoading = false,
  creditMemoFilterStatus = [],
  onPageNumberChange = noop,
  getCreditMemo = noop,
  handleMarkCreditMemoSent = noop,
  onPageSizeChange = noop,
  handleClose = noop,
  handleItemClick = noop,
  handleApprovalDialog = noop,
  handleCreditMemoFilter = noop,
  handleFetch = noop,
  handleMultiplePdf = noop,
  fetchCreditMemo = noop,
}) => {
  const classes = useStyles();
  const { appData } = useContext(AppContext);
  const [state, setState] = useState(defaultState);
  const selectedRef = useRef(null);
  const currentUser = getTokenData() || {};
  const isRoleAA = (currentUser?.role || "").toLowerCase() === ROLE.AA;

  const fetchNewdata = useCallback((isFetching, isDeleting) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingNewData: isFetching,
      isDeletingCreditMemo: isDeleting,
    }));
  }, []);

  useEffect(() => {
    if (state.isFetchingNewData) {
      fetchCreditMemo({ id: entry?.id });
      getCreditMemo({ id: entry?.id }, true);

      setState((prevState) => ({
        ...prevState,
        isFetchingNewData: false,
        isDeletingCreditMemo: false,
      }));
    }
  }, [state.isFetchingNewData, fetchCreditMemo, entry]);

  useEffect(() => {
    if (selectedRef.current && state.selectedCreditMemo.length === 0) {
      selectedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    const selectedCompanies =
      state.selectedCreditMemo.length > 0
        ? [
            ...new Set(
              rows
                .filter((obj) => state.selectedCreditMemo.includes(obj.id))
                .map((ele) => ele.pdx_company)
            ),
          ].join(", ")
        : null;

    const displayCompanyName = () => {
      if (selectedCompanies && selectedCompanies.split(",").length > 1) {
        return "Parts Distribution Xpress";
      } else if (state.selectedCreditMemo.length === 0) {
        return selectedCreditMemo?.pdx_company;
      } else {
        return selectedCompanies;
      }
    };
    setState((prevState) => ({
      ...prevState,
      emailSubject: `${
        !state.selectedCreditMemo.length
          ? `Credit Memo ${selectedCreditMemo?.credit_no || ""}`
          : state.selectedCreditMemo.length === 1
          ? `Credit Memo ${
              rows.filter((obj) => state.selectedCreditMemo.includes(obj.id))[0]
                ?.credit_no
            }`
          : "Credit Memos"
      } from ${selectedCompanies || selectedCreditMemo?.pdx_company || "PDX"}`,
      emailBody: `Dear ${
        !state.selectedCreditMemo.length
          ? selectedCreditMemo?.customer_branch?.customer?.name || ""
          : state.selectedCreditMemo.length === 1
          ? rows.filter((obj) => state.selectedCreditMemo.includes(obj.id))[0]
              ?.customer_branch?.customer?.name
          : "Customers"
      },\n\nYour ${
        state.selectedCreditMemo.length > 1
          ? "credit memo are"
          : "credit memo is"
      } attached. Please review the details of the credit applied to your account. If you have any questions or need further clarification, feel free to reach out to us. Thank you for your business - we appreciate it very much.\n\nSincerely,\n${displayCompanyName()}`,
    }));
  }, [selectedCreditMemo, state.selectedCreditMemo, rows]);

  const emailAddresses =
    state.selectedCreditMemo.length === 0
      ? entry?.customer_branch?.email_addresses?.emailAddresses || []
      : state.selectedCreditMemo.length === 1
      ? rows.find((obj) => state.selectedCreditMemo.includes(obj.id))
          ?.customer_branch?.email_addresses?.emailAddresses || []
      : [];

  useEffect(() => {
    if (
      !state.sentEmailHistory &&
      state.showSendCreditePopup &&
      emailAddresses.length
    ) {
      setState((prevState) => ({
        ...prevState,
        permanentEmail: emailAddresses,
      }));
    }
  }, [state.showSendCreditePopup, emailAddresses]);

  const getFieldValidatorMap = (state, field) => {
    const fieldValidatorMap = {
      from: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      email: [{ type: VALIDATIONS.EMAIL, value: true }],
      emailSubject: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      emailBody: [{ type: VALIDATIONS.MAX_LENGTH, value: 2000 }],
    };
    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = " ";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (validate() === " " && state.errors?.email === " ") {
        event.preventDefault();
        handleAddEmail("temporaryEmail");
      }
    }
  };

  const handleBlur = () => {
    if (validate() === " " && state.errors?.email === " ") {
      handleAddEmail("temporaryEmail");
    }
  };

  const handleGeneratePDF = (credit, preview, print) => {
    const updatedData = credit.CreditMemoItemRecords.map((item) => {
      if (item.route_name && item.route_id) {
        return {
          ...item,
          credit_description: `${item.route_name} - ${
            item.credit_description || ""
          }`,
        };
      }
      return item;
    });
    const creditMemoData = {
      creditMemoDetails: updatedData,
      creditNo: credit.credit_no,
      creditDate: getUTCDateString(credit.credit_memo_date),
      customerNotes: credit.customer_notes,
      status: credit.status,
      pdxCompany: credit.pdx_company,
      address: {
        customerName: credit.customer_branch.customer?.name,
        branch: credit.customer_branch.location,
        primary_contact: credit.bill_to.primary_contact,
        streetAddress: credit.bill_to.street_address,
        city: credit.bill_to.city,
        state: credit.bill_to.state,
        zipCode: credit.bill_to.zip_code,
        country: credit.bill_to.country,
      },
      invoices: credit.invoice_amount,
      fromDate: getUTCDateString(credit.from_date),
      toDate: getUTCDateString(credit.to_date),
      isPreview: preview,
      isPrinting: print,
    };

    GenerateCreditMemoPDF(creditMemoData);
    handleMenuClose();
  };

  const handleFieldChange = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    if (state.temporaryEmail.some((email) => email === value.trim())) {
      errorMessage = "This email has already been entered.";
    }
    if (state.permanentEmail.some((email) => email === value.trim())) {
      errorMessage = "This email has already been entered.";
    }

    isConfirmDisabled = !!errorMessage && errorMessage !== " ";
    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleAddEmail = (type) => {
    if (state.email?.trim()) {
      setState((prevState) => ({
        ...prevState,
        [type]: [...(prevState[type] || []), state?.email?.trim()],

        ...(type === "temporaryEmail" && {
          showAddPermanentEmailDialog: false,
          email: defaultState.email,
        }),
      }));
    }
  };

  const uploadedAttachment = (attachment) => {
    setState((prevState) => ({
      ...prevState,
      uploadedAttachment: attachment,
    }));
  };

  const handleSubmit = async (
    id,
    tempEmailArray,
    permEmailArray,
    subject,
    body,
    attachment,
    isSingleCreditSelected = false
  ) => {
    setState((prevState) => ({ ...prevState, isCreditmemoSending: true }));

    const emailArray =
      !state.selectedCreditMemo.length || state.selectedCreditMemo.length === 1
        ? [...tempEmailArray, ...permEmailArray]
        : [...tempEmailArray];

    const idArray = [];
    if (Array.isArray(id)) {
      idArray.push(...id);
    } else {
      idArray.push(id);
    }

    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("email_body", body);

    idArray.forEach((creditIds, index) =>
      formData.append(`creditMemoIds[${index}]`, creditIds)
    );
    emailArray.forEach((email, index) =>
      formData.append(`emailIds[${index}]`, email)
    );

    attachment.forEach((file) => {
      formData.append("uploadedAttachment", file);
    });

    const { error } = await Service.sendCreditMemoEmail(formData);

    if (error) {
      setState((prevState) => ({ ...prevState, isCreditmemoSending: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Email sent successfully.");
    const selectedCompanies =
      state.selectedCreditMemo.length > 0
        ? [
            ...new Set(
              rows
                .filter((obj) => state.selectedCreditMemo.includes(obj.id))
                .map((ele) => ele.pdx_company)
            ),
          ].join(", ")
        : null;

    const displayCompanyName = () => {
      if (selectedCompanies && selectedCompanies.split(",").length > 1) {
        return "Parts Distribution Xpress";
      } else if (state.selectedCreditMemo.length === 0) {
        return selectedCreditMemo?.pdx_company;
      } else {
        return selectedCompanies;
      }
    };
    setState((prevState) => ({
      ...prevState,
      isCreditmemoSending: false,
      selectedCreditMemo: defaultState.selectedCreditMemo,
      showSendCreditePopup: false,
      temporaryEmail: defaultState.temporaryEmail,
      permanentEmail: defaultState.permanentEmail,
      from: defaultState.from,
      emailSubject: `${
        !state.selectedCreditMemo.length
          ? `Credit Memo ${selectedCreditMemo?.credit_no || ""}`
          : state.selectedCreditMemo.length === 1
          ? `${
              rows.filter((obj) => state.selectedCreditMemo.includes(obj.id))[0]
                ?.credit_no
            }`
          : "Credit Memos"
      } from ${selectedCompanies || selectedCreditMemo?.pdx_company || "PDX"}`,
      emailBody: `Dear ${
        !state.selectedCreditMemo.length
          ? selectedCreditMemo?.customer_branch?.customer?.name || ""
          : state.selectedCreditMemo.length === 1
          ? rows.filter((obj) => state.selectedCreditMemo.includes(obj.id))[0]
              ?.customer_branch?.customer?.name
          : "Customers"
      },\n\nYour credit memo ${
        state.selectedCreditMemo.length > 1 ? "are" : "is"
      } attached. Please review the details of the credit applied to your account. If you have any questions or need further clarification, feel free to reach out to us. Thank you for your business - we appreciate it very much.\n\nSincerely,\n${displayCompanyName()}`,
    }));
    if (isSingleCreditSelected) {
      getCreditMemo({ id: entry?.id }, true);
    }
    if (state.sentEmailHistory) {
      getCreditEmailHistory(selectedCreditMemo?.id);
    }
    handleFetch("creditMemoLoading");
  };

  const handleDeleteEmail = (id, type) => {
    setState((prevState) => {
      const filteredEmails = prevState[type].filter((email) => email !== id);
      const emailExists = filteredEmails.some(
        (item) => item === prevState.email
      );
      isConfirmDisabled = emailExists;
      return {
        ...prevState,
        [type]: filteredEmails,
        errors: {
          ...prevState.errors,
          email: emailExists ? prevState.errors.email : " ",
          from: prevState.errors.from,
          emailSubject: prevState.errors.emailSubject,
          emailBody: prevState.errors.emailBody,
        },
      };
    });
  };

  const handleChange = (option) => {
    handleCreditMemoFilter(option);
    setState((prevState) => ({
      ...prevState,
      selectedOption: option,
    }));
  };

  const handlePageChange = (event, pageNumber) => {
    event.stopPropagation();
    onPageNumberChange(pageNumber + 1);
  };

  const handlePageSizeChange = (event) => {
    onPageSizeChange(event.target.value);
  };

  const getStatusInfo = (status) => {
    switch (status) {
      case "draft":
        return { text: "Draft", className: classes.draft };
      case "approved":
        return { text: "Approved", className: classes.approved };
      case "pending-approval":
        return { text: "Pending Approval", className: classes.open };
      default:
        return { text: "", className: "" };
    }
  };

  const handleCheckboxChange = (creditId) => {
    setState((prevState) => ({
      ...prevState,
      selectedCreditMemo: prevState.selectedCreditMemo.includes(creditId)
        ? prevState.selectedCreditMemo.filter((id) => id !== creditId)
        : [...prevState.selectedCreditMemo, creditId],
    }));
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setState((prevState) => ({
        ...prevState,
        selectedCreditMemo: rows.map((ele) => ele.id),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        selectedCreditMemo: defaultState.selectedCreditMemo,
      }));
    }
  };

  const handleMenuOpen = (event) => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: event.currentTarget,
    }));
  };

  const handleClick = (event) => {
    setState((prevState) => ({
      ...prevState,
      anchorE2: event.currentTarget,
    }));
  };

  const handleMenuClose = () => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: defaultState.anchorEl,
      anchorE2: defaultState.anchorEl,
    }));
  };

  const handleClearSelection = () => {
    setState((prevState) => ({
      ...prevState,
      selectedCreditMemo: defaultState.selectedCreditMemo,
    }));
    handleMenuClose();
  };

  const handleDeleteCreditDialog = (value) => {
    setState((prevState) => ({
      ...prevState,
      isCreditMemoDeleteDialog: value,
    }));
    handleMenuClose();
  };

  const handleDeleteCreditMemo = async (ids) => {
    setState((prevState) => ({
      ...prevState,
      isCreditMemoDeleteLoading: true,
    }));

    const creditIdsString = ids.join(",");

    const queryString = `?creditMemoIds=${creditIdsString}`;

    const { error } = await Service.deleteCreditMemo(queryString);

    setState((prevState) => ({
      ...prevState,
      isCreditMemoDeleteLoading: false,
      selectedCreditMemo: defaultState.selectedCreditMemo,
    }));

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Deleted successfully.");
    }
    handleDeleteCreditDialog(false);
    if (!error) {
      handleFetch("creditMemoLoading");
    }
  };

  const handleAddPermEmailDialog = () => {
    if (validate() === " " && state.errors?.email === " ") {
      handleAddEmail("permanentEmail");
    }

    setState((prevState) => ({
      ...prevState,
      showAddPermanentEmailDialog: true,
    }));
  };

  const addPermananentEmaiil = async (data, email) => {
    setState((prevState) => ({ ...prevState, isPermEmailLoading: true }));

    const payload = {
      id: data.id || null,
      invoice_type: data.invoiceType?.id || null,
      pdx_company_id: data.pdx_company_id || null,
      customer_id: data.customer_id,
      state: data.state || null,
      location: data.location || null,
      aa_id: data.aa_id || null,
      kam_id: data.kam_id || null,
      mgr_id: data.mgr_id || null,
      email_addresses: {
        emailAddresses: email,
      },
    };

    const { error } = await Service.addEmailInBranch(payload);

    if (error) {
      handleAddEmail("temporaryEmail");
      setState((prevState) => ({ ...prevState, isPermEmailLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Customer Email has been added successfully.`);
    setState((prevState) => ({
      ...prevState,
      isPermEmailLoading: false,
      showAddPermanentEmailDialog: false,
      email: defaultState.email,
    }));
    fetchCreditMemo({ id: entry.id }, "emailLoading");
  };

  const handleViewerClose = () => {
    setState((prevState) => ({ ...prevState, pdfViewerDialog: false }));
  };

  const getCreditEmailHistory = async (id) => {
    setState((prevState) => ({
      ...prevState,
      isCreditEmailHistoryLoading: true,
    }));
    const creditId = `?filter[where][and][0][or][0][credit_memo_ids][contains]=[${id}]`;

    const { data, error } = await Service.getCreditEmailHistory(creditId);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isCreditEmailHistoryLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isCreditEmailHistoryLoading: false,
      emailHistory: data.rows || defaultState.emailHistory,
    }));
  };

  const handleDialogClose = (close) => {
    setState((prevState) => ({
      ...prevState,
      open: close,
    }));
  };

  const moreActions = (row) => {
    return (
      <div className="d-flex f-align-center">
        <Tooltip title="View Detailed Sent Credit Memo" placement="top-start">
          <VisibilityIcon
            className={"ml-2 mr-2 c-pointer"}
            color="primary"
            onClick={() => {
              const resendEmail = state.emailHistory.find(
                (ele) => ele.id === row.id
              );

              setState((prevState) => {
                const resendEmailsArray =
                  resendEmail?.to?.customer_emails ||
                  defaultState.resendEmailsArray;
                const permanentEmail = resendEmailsArray.filter((email) =>
                  emailAddresses.includes(email)
                );
                const temporaryEmail = resendEmailsArray.filter(
                  (email) => !emailAddresses.includes(email)
                );

                return {
                  ...prevState,
                  showSendCreditePopup: true,
                  isPreviousViewCreditMemo: row.credit_pdf_url,
                  temporaryEmail: temporaryEmail || defaultState.temporaryEmail,
                  permanentEmail: permanentEmail || defaultState.permanentEmail,
                  emailSubject:
                    resendEmail?.subject || defaultState.emailSubject,
                  emailBody: resendEmail?.email_body || defaultState.emailBody,
                  attachmentFiles:
                    resendEmail?.attachments?.attachmentFiles ||
                    defaultState.attachmentFiles,
                };
              });
            }}
          />
        </Tooltip>
        <Tooltip title="Resend Email" placement="top-start">
          <SendIcon
            className={"ml-6 mr-2 c-pointer"}
            color="primary"
            onClick={() => {
              const resendEmail = state.emailHistory.find(
                (ele) => ele.id === row.id
              );

              setState((prevState) => {
                const resendEmailsArray =
                  resendEmail?.to?.customer_emails ||
                  defaultState.resendEmailsArray;
                const permanentEmail = resendEmailsArray.filter((email) =>
                  emailAddresses.includes(email)
                );
                const temporaryEmail = resendEmailsArray.filter(
                  (email) => !emailAddresses.includes(email)
                );
                return {
                  ...prevState,
                  showSendCreditePopup: true,
                  temporaryEmail: temporaryEmail || defaultState.temporaryEmail,
                  permanentEmail: permanentEmail || defaultState.permanentEmail,
                  emailSubject:
                    resendEmail?.subject || defaultState.emailSubject,
                  emailBody: resendEmail?.email_body || defaultState.emailBody,
                };
              });
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const columnConfig = [
    {
      id: "date",
      label: "Sent Date",
      render: (row) => {
        return (
          <Tooltip
            title={getUTCDateString(row.date) || ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {getUTCDateString(row.date)}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "sentBy",
      label: "Sent By",
      render: (row) => {
        const sentBy = usersList.find((ele) => ele?.id === row.created_by);
        let user = "";
        if (!!sentBy) {
          user = `${sentBy?.first_name}, ${sentBy?.last_name || ""} (${
            sentBy?.email
          } - ${sentBy?.username})`;
        }
        return (
          <Tooltip title={user ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {user || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "sentTo",
      label: "Sent To",
      render: (row) => {
        let emails;
        if (!!row?.to?.customer_emails) {
          emails = row.to.customer_emails?.join(", ");
        }
        return (
          <Tooltip title={emails ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {emails || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "col-edit",
      noResize: true,
      render: (row) => moreActions(row),
    },
  ];

  const LoadingOverlay = ({ message }) => (
    <div className={classes.overlayStyles}>
      <div className={classes.messageStyles}>
        <CircularProgress color="inherit" />
        <Typography variant="h6" style={{ marginTop: "16px" }}>
          {message}
        </Typography>
      </div>
    </div>
  );

  return (
    <div className={clsx("d-flex", appData.isTabletView && classes.tabletView)}>
      <Paper
        elevation={2}
        className={clsx(
          "p-4 mt-10",
          classes.paperSpacing,
          appData.isTabletView && classes.tableWidth
        )}
      >
        <div
          className="d-flex f-align-center f-justify-between"
          style={{
            padding:
              state.selectedCreditMemo.length > 0 ? "12.25px 0px" : "18px 0px",
          }}
        >
          {state.selectedCreditMemo.length > 0 ? (
            <>
              <div className="d-flex f-align-center f-justify-between">
                <Checkbox
                  indeterminate={
                    state.selectedCreditMemo.length > 0 &&
                    state.selectedCreditMemo.length < rows.length
                  }
                  checked={
                    rows.length > 0 &&
                    state.selectedCreditMemo.length === rows.length
                  }
                  onChange={handleSelectAll}
                />
                <Tooltip title="Send Credit Memo" placement="top-start">
                  <IconButton
                    onClick={() => {
                      if (
                        rows.some(
                          (ele) =>
                            state.selectedCreditMemo.includes(ele.id) &&
                            (ele.status === "draft" ||
                              ele.status === "pending-approval")
                        )
                      ) {
                        return toast.error(
                          "Credit memo(s) can only be marked as sent for customers whose status is approved."
                        );
                      }
                      setState((prevState) => ({
                        ...prevState,
                        showSendCreditePopup: true,
                      }));
                    }}
                    className={classes.sentIcon}
                  >
                    <SendIcon />
                  </IconButton>
                </Tooltip>
                <IconButton
                  onClick={handleMenuOpen}
                  className={classes.sentIcon}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={state.anchorEl}
                  keepMounted
                  open={Boolean(state.anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    disabled={isPdfPreviewLoading}
                    onClick={() =>
                      handleMultiplePdf(
                        state.selectedCreditMemo,
                        "isPdfPreviewLoading",
                        true
                      )
                    }
                  >
                    Print
                    {isPdfPreviewLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleMultiplePdf(
                        state.selectedCreditMemo,
                        "isPdfDownloadLoading",
                        false
                      )
                    }
                    disabled={isPdfDownloadLoading}
                  >
                    Export as PDF
                    {isPdfDownloadLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      const selectedCreditsWithPayments = rows
                        .filter(
                          (ele) =>
                            state.selectedCreditMemo.includes(ele.id) &&
                            !!ele.payment_id
                        )
                        .map((ele) => ele.credit_no);
                      if (
                        rows.some(
                          (ele) =>
                            state.selectedCreditMemo.includes(ele.id) &&
                            ele.status !== "draft"
                        )
                      ) {
                        return toast.error(
                          "Deletion is not allowed for credit memo unless they are in draft status."
                        );
                      }
                      if (selectedCreditsWithPayments.length > 0) {
                        return toast.error(
                          `Deletion is not allowed for credit memo(s) ${selectedCreditsWithPayments.join(
                            ", "
                          )} because a payment is associated with it.`
                        );
                      }
                      handleDeleteCreditDialog(true);
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
                <Button
                  variant="outlined"
                  color="primary"
                  className="ml-2"
                  disabled={isMarkAsSentLoading}
                  onClick={() => {
                    if (
                      rows.some(
                        (ele) =>
                          state.selectedCreditMemo.includes(ele.id) &&
                          (ele.status === "draft" ||
                            ele.status === "pending-approval")
                      )
                    ) {
                      return toast.error(
                        "Credit memo(s) can only be marked as sent for customers whose status is approved."
                      );
                    }
                    setState((prevState) => ({
                      ...prevState,
                      isEmailSent: true,
                    }));
                  }}
                >
                  Mark as Sent
                  {isMarkAsSentLoading && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn"
                    />
                  )}
                </Button>
              </div>
              <IconButton onClick={handleClearSelection}>
                <ClearIcon />
              </IconButton>
            </>
          ) : (
            <>
              {!!creditMemoFilterStatus.length ? (
                <Typography variant="h6" color="primary">
                  Credit Memo List
                </Typography>
              ) : (
                <Dropdown
                  classes={{
                    paper: clsx(classes.tunePaper),
                    dropdownIcon: classes.dropdownIcon,
                    labelContent: classes.labelContent,
                  }}
                  disablePortal={false}
                  labelEllipses
                  hasEllipses
                  placement="bottom-start"
                  options={CREDIT_MEMO_STATUS}
                  labelClassName={classes.label}
                  label={`${
                    state.selectedOption ? state.selectedOption.label : "All"
                  } Credit Memo`}
                  onChange={handleChange}
                  renderOption={(option) => (
                    <div className={classes.dropdownItem}>{option.label}</div>
                  )}
                />
              )}
              <div></div>
            </>
          )}
        </div>
        <Divider />
        {isLoading || state.isDeletingCreditMemo ? (
          <GridLoader pageSize={8} columns={2} />
        ) : !!rows && rows.length > 0 ? (
          <>
            <List className={classes.list}>
              {rows.map((ele) => (
                <Fragment key={ele.id}>
                  <div
                    className={clsx("d-flex f-align-center", {
                      [classes.listItemSelected]:
                        selectedCreditMemo?.id === ele?.id,
                    })}
                    ref={
                      selectedCreditMemo?.id === ele?.id ? selectedRef : null
                    }
                  >
                    <Checkbox
                      checked={state.selectedCreditMemo.includes(ele.id)}
                      onChange={() => handleCheckboxChange(ele.id)}
                    />
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={() => {
                        handleItemClick(ele);
                        getCreditMemo(ele, true);
                      }}
                    >
                      <div className={classes.listItemText}>
                        <div className="d-flex f-align-center">
                          <Typography variant="body1">
                            {ele.customer_branch?.customer?.name}
                          </Typography>
                          {!!ele?.is_sent_to_customer && (
                            <MailIcon color="primary" className="ml-2" />
                          )}
                        </div>
                        <div className="d-flex f-align-center">
                          <Typography
                            variant="body1"
                            style={{ color: "#775edc" }}
                          >
                            {ele.credit_no}
                          </Typography>
                          <div style={{ height: "20px", padding: "0px 5px" }}>
                            <Divider orientation="vertical" />
                          </div>
                          <Typography variant="body2" color="textSecondary">
                            {getUTCDateString(ele.credit_memo_date)}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.statusListItemText}>
                        <Typography variant="body1" className={classes.amount}>
                          {`$ ${ele.balance_credit_amount || "0.00"}`}
                        </Typography>
                        <div className="d-flex f-align-center">
                          <Typography
                            variant="body2"
                            className={`${classes.status} ${
                              getStatusInfo(ele.status).className
                            }`}
                          >
                            {getStatusInfo(ele.status).text}
                          </Typography>
                        </div>
                      </div>
                    </ListItem>
                  </div>
                  <Divider />
                </Fragment>
              ))}
            </List>
            <Divider />
            <div
              className="d-flex f-align-center f-justify-center"
              style={{ margin: "2px 0px" }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={totalEntries}
                rowsPerPage={pageSize}
                colSpan={3}
                page={pageNumber - 1}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePageSizeChange}
                classes={{
                  select: "d-flex f-align-center f-justify-end",
                }}
              />
            </div>
          </>
        ) : (
          <div
            className="d-flex f-justify-center"
            style={{ paddingTop: "30%" }}
          >
            <img
              className={classes.noRecordsImg}
              src={NoRecords}
              alt="noRecord"
            />
          </div>
        )}
      </Paper>

      <Paper
        elevation={2}
        className={clsx("p-4 mt-10 ml-2", classes.summaryPaperSpacing)}
      >
        {creditMemoLoading ? (
          <div
            className="d-flex f-align-center f-justify-center pl-2 pr-4 pt-8 pb-8"
            style={{ height: "100%" }}
          >
            <CircularProgress />
          </div>
        ) : rows.some((item) => item.id === selectedCreditMemo?.id) ? (
          <>
            <div className="d-flex f-align-center f-justify-between">
              <Typography
                style={{
                  padding: "18px 0px",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {`Credit Memo ${selectedCreditMemo.credit_no}`}
              </Typography>
              <IconButton onClick={() => handleClose(false)}>
                <ClearIcon />
              </IconButton>
            </div>
            <div>
              {isCreditMemoLoading ? (
                <Skeleton
                  variant="rect"
                  width="50%"
                  height="36px"
                  style={{ marginBottom: "10px" }}
                />
              ) : (
                <>
                  <Button
                    startIcon={
                      entry?.status !== "draft" ? (
                        <VisibilityIcon />
                      ) : (
                        <EditIcon />
                      )
                    }
                    variant="outlined"
                    color="primary"
                    disabled={isLoading || state.isDeletingCreditMemo}
                    className={classes.iconButton}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        open: true,
                      }));
                    }}
                  >
                    {entry.status !== "draft" ? "View" : "Edit"}
                  </Button>
                  <Button
                    startIcon={<SendIcon />}
                    variant="outlined"
                    color="primary"
                    className={classes.iconButton}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        showSendCreditePopup: true,
                      }));
                    }}
                    disabled={
                      entry.status === "pending-approval" ||
                      entry.status === "draft" ||
                      state.selectedCreditMemo.length
                    }
                  >
                    Send
                  </Button>
                  <Button
                    startIcon={<DescriptionIcon />}
                    variant="outlined"
                    color="primary"
                    className={classes.iconButton}
                    onClick={handleClick}
                    disabled={state.selectedCreditMemo.length > 0}
                  >
                    PDF/Print
                  </Button>
                  <Menu
                    anchorEl={state.anchorE2}
                    open={Boolean(state.anchorE2)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem
                      onClick={() => handleGeneratePDF(entry, false, true)}
                    >
                      <ListItemIcon>
                        <PrintIcon />
                      </ListItemIcon>
                      Print
                    </MenuItem>
                    <MenuItem onClick={() => handleGeneratePDF(entry, false)}>
                      <ListItemIcon>
                        <PdfIcon />
                      </ListItemIcon>
                      PDF
                    </MenuItem>
                  </Menu>
                </>
              )}
            </div>
            {isCreditMemoLoading ? (
              <Skeleton variant="rect" width="100%" height="50px" />
            ) : entry?.status !== "pending-approval" &&
              entry?.status !== "draft" ? (
              <div className={classes.root}>
                <div className="d-flex f-align-center">
                  <DraftsIcon className="m-3" color="primary" />
                  <div>
                    <Typography variant="h6">Send the credit memo</Typography>
                    <Typography variant="body1">
                      {`Credit Memo has been approved. You can email it to your customer or ${
                        entry.is_sent_to_customer
                          ? "view email history."
                          : "mark it as sent."
                      }`}
                    </Typography>
                  </div>
                </div>
                <div className="d-flex f-align-center">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.iconButton}
                    disabled={state.selectedCreditMemo.length}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        showSendCreditePopup: true,
                      }));
                    }}
                  >
                    Send Credit Memo
                  </Button>
                  {!!entry.is_sent_to_customer ? (
                    <Button
                      startIcon={<MailIcon />}
                      variant="outlined"
                      color="primary"
                      disabled={state.selectedCreditMemo.length > 0}
                      className={classes.iconButton}
                      onClick={() => {
                        getCreditEmailHistory(selectedCreditMemo?.id);
                        setState((prevState) => ({
                          ...prevState,
                          sentEmailHistory: true,
                        }));
                      }}
                    >
                      Email History
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.iconButton}
                      disabled={
                        isMarkAsSentLoading ||
                        state.selectedCreditMemo.length > 0
                      }
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          isEmailSent: true,
                        }))
                      }
                    >
                      Mark as Sent
                      {isMarkAsSentLoading && (
                        <CircularProgress
                          size={24}
                          className="p-absolute progress-btn"
                        />
                      )}
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <div className={classes.root}>
                {isRoleAA ? (
                  <Typography variant="h6" color="error" className="m-2">
                    {entry?.status === "draft"
                      ? "Please finalize the credit memo before sending it for approval by clicking the edit button."
                      : " Approval for this credit memo is pending."}
                  </Typography>
                ) : (
                  <>
                    <div className="d-flex f-align-center">
                      <ThumbsUpDownIcon
                        className="m-3"
                        color="primary"
                        style={{ fontSize: 28 }}
                      />
                      <div>
                        <Typography variant="h6">Approve/Reject</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: entry?.status === "draft" ? "#f44336" : null,
                          }}
                        >
                          {entry?.status === "draft"
                            ? "Please finalize the credit memo before approving or rejecting it by clicking the edit button."
                            : "Kindly Please proceed with either approving or rejecting the credit memo."}
                        </Typography>
                      </div>
                    </div>
                    <div className="d-flex f-align-center">
                      <Button
                        variant="outlined"
                        style={{
                          color:
                            entry?.status !== "pending-approval" ||
                            state.selectedCreditMemo.length > 0
                              ? null
                              : "#f44336",
                          borderColor:
                            entry?.status !== "pending-approval" ||
                            state.selectedCreditMemo.length > 0
                              ? null
                              : "#f44336",
                        }}
                        className={classes.iconButton}
                        onClick={() =>
                          handleApprovalDialog(
                            selectedCreditMemo?.id,
                            "reject",
                            true
                          )
                        }
                        disabled={
                          entry?.status !== "pending-approval" ||
                          state.selectedCreditMemo.length > 0
                        }
                      >
                        Reject
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.iconButton}
                        onClick={() =>
                          handleApprovalDialog(
                            selectedCreditMemo?.id,
                            "approve",
                            true
                          )
                        }
                        disabled={
                          entry?.status !== "pending-approval" ||
                          state.selectedCreditMemo.length > 0
                        }
                      >
                        Approve
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
            <div
              className={clsx("m-4 d-flex f-justify-center", classes.invoice)}
              style={{ position: "relative", zIndex: 1 }}
            >
              {isCreditMemoLoading ? (
                <LoadingOverlay message="Processing, please wait..." />
              ) : (
                <iframe
                  // src={`${pdfUrl}#toolbar=0`}
                  src={pdfUrl}
                  title="Credit Memo PDF"
                  className={classes.iframe}
                ></iframe>
              )}
            </div>
          </>
        ) : (
          <div
            className="d-flex f-align-center f-justify-center pl-2 pr-4 pt-8 pb-8"
            style={{ height: "100%" }}
          >
            <Typography variant="h6">
              Please Select the Credit Memo to view.
            </Typography>
          </div>
        )}
      </Paper>

      {state.showSendCreditePopup && (
        <ActionDialog
          classes={{
            confirm:
              state.isCreditmemoSending || isConfirmDisabled
                ? "black"
                : "bg-primary",
            paper: classes.paperSize,
          }}
          open={!!state.showSendCreditePopup}
          contentText={
            <div style={{ overflow: "hidden" }} className="mr-4">
              <Typography
                variant="h5"
                className="text-bold mb-4"
                color="primary"
              >
                {`${
                  !!state.isPreviousViewCreditMemo
                    ? "Sent"
                    : state.sentEmailHistory
                    ? "Resend"
                    : "Send"
                } ${
                  !state.selectedCreditMemo.length
                    ? `Credit Memo ${selectedCreditMemo.credit_no}`
                    : state.selectedCreditMemo.length === 1
                    ? `Credit Memo ${
                        rows.filter((obj) =>
                          state.selectedCreditMemo.includes(obj.id)
                        )[0]?.credit_no
                      }`
                    : "Credit Memos"
                } `}
              </Typography>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  From
                </Typography>
                <TextField
                  label="From"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="from"
                  disabled
                  value="noreply@pdxdelivers.com"
                  className="mt-3"
                  helperText=" "
                />
              </div>
              {!state.selectedCreditMemo.length ||
              state.selectedCreditMemo.length === 1 ? (
                <>
                  {!state.isPreviousViewCreditMemo && (
                    <div className="d-flex">
                      <Typography
                        variant="h6"
                        className="text-bold mr-8 mt-4"
                        style={{ width: "8rem" }}
                      >
                        To
                      </Typography>
                      <div
                        className="d-flex"
                        style={{ gap: "6px", width: "100%" }}
                      >
                        <TextField
                          label="Add Email ID(s)"
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="email"
                          disabled={
                            !!state.isPreviousViewCreditMemo ||
                            state.isCreditmemoSending
                          }
                          value={state.email}
                          required
                          className="mt-3"
                          onChange={handleFieldChange}
                          error={!!state.errors.email?.trim()}
                          helperText={state.errors.email}
                        />

                        <div>
                          <Tooltip
                            title="Add Email Temporarily"
                            placement="top-end"
                          >
                            <Button
                              type="submit"
                              onClick={() => {
                                if (
                                  validate() === " " &&
                                  state.errors?.email === " "
                                ) {
                                  handleAddEmail("temporaryEmail");
                                }
                              }}
                              variant="contained"
                              color="primary"
                              disabled={isConfirmDisabled || !state.email}
                              className={classes.buttonStyle1}
                            >
                              <AddIcon />
                            </Button>
                          </Tooltip>
                        </div>
                        <div>
                          <Tooltip
                            title="Add Email To Customer"
                            placement="top-end"
                          >
                            <Button
                              type="submit"
                              onClick={handleAddPermEmailDialog}
                              variant="contained"
                              color="primary"
                              disabled={isConfirmDisabled || !state.email}
                              className={classes.buttonStyle1}
                            >
                              <PostAddIcon />
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  )}
                  {(state.temporaryEmail.length > 0 ||
                    state.permanentEmail.length > 0) && (
                    <div className="d-flex mb-4">
                      {!state.isPreviousViewCreditMemo && (
                        <div
                          variant="h6"
                          className="mr-8 mt-4"
                          style={{ width: "8rem" }}
                        ></div>
                      )}
                      {!!state.isPreviousViewCreditMemo && (
                        <Typography
                          variant="h6"
                          className="text-bold mr-8 mt-4"
                          style={{ width: "8rem" }}
                        >
                          To
                        </Typography>
                      )}
                      <div
                        className="d-flex f-justify-between flex-wrap"
                        style={{ width: "100%", gap: 20 }}
                      >
                        {state.permanentEmail.length > 0 && (
                          <div
                            className="d-flex flex-column"
                            style={{ width: "100%" }}
                          >
                            <Accordion style={{ width: "100%" }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                              >
                                <div>
                                  <Typography className={classes.heading}>
                                    Customer Email(s)
                                  </Typography>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div style={{ width: "100%" }}>
                                  <List dense disablePadding>
                                    {emailLoading ? (
                                      <ListItem>
                                        <Skeleton
                                          className="mr-2"
                                          variant="circle"
                                          width={15}
                                          height={15}
                                        />
                                        <ListItemText
                                          primary={
                                            <Skeleton
                                              variant="rect"
                                              width="50%"
                                              height="20px"
                                            />
                                          }
                                        />
                                      </ListItem>
                                    ) : (
                                      state.permanentEmail.map((item) => (
                                        <ListItem key={item}>
                                          <div className="mr-2">
                                            <FiberManualRecordIcon
                                              style={{ fontSize: 10 }}
                                            />
                                          </div>
                                          <ListItemText primary={item} />
                                          {!state.isPreviousViewCreditMemo && (
                                            <ListItemSecondaryAction>
                                              <IconButton
                                                disabled={
                                                  state.isCreditmemoSending
                                                }
                                                onClick={() =>
                                                  handleDeleteEmail(
                                                    item,
                                                    "permanentEmail"
                                                  )
                                                }
                                                edge="end"
                                                aria-label="delete"
                                              >
                                                <CancelIcon fontSize="small" />
                                              </IconButton>
                                            </ListItemSecondaryAction>
                                          )}
                                        </ListItem>
                                      ))
                                    )}
                                  </List>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        )}
                        {state.temporaryEmail.length > 0 && (
                          <div
                            className="d-flex flex-column"
                            style={{ width: "100%" }}
                          >
                            <Accordion style={{ width: "100%" }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                              >
                                <div>
                                  <Typography className={classes.heading}>
                                    Temporary Email(s)
                                  </Typography>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div style={{ width: "100%" }}>
                                  <List dense disablePadding>
                                    {state.temporaryEmail.map((item) => (
                                      <ListItem key={item}>
                                        <ListItemText primary={item} />
                                        {!state.isPreviousViewCreditMemo && (
                                          <ListItemSecondaryAction>
                                            <IconButton
                                              disabled={
                                                state.isCreditmemoSending
                                              }
                                              onClick={() =>
                                                handleDeleteEmail(
                                                  item,
                                                  "temporaryEmail"
                                                )
                                              }
                                              edge="end"
                                              aria-label="delete"
                                            >
                                              <CancelIcon fontSize="small" />
                                            </IconButton>
                                          </ListItemSecondaryAction>
                                        )}
                                      </ListItem>
                                    ))}
                                  </List>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="d-flex">
                  <Typography
                    variant="h6"
                    className="text-bold mr-8 mt-4"
                    style={{ width: "8rem" }}
                  >
                    To
                  </Typography>
                  <TextField
                    label="Add Email ID(s)"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="email"
                    value={state.email}
                    disabled={state.isCreditmemoSending}
                    required
                    className="mt-3"
                    onChange={handleFieldChange}
                    error={!!state.errors.email?.trim()}
                    helperText={state.errors.email}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      startAdornment: (
                        <div className={classes.chipsContainer}>
                          {state.temporaryEmail.map((email) => (
                            <Chip
                              key={email}
                              label={email}
                              disabled={state.isCreditmemoSending}
                              onDelete={() =>
                                handleDeleteEmail(email, "temporaryEmail")
                              }
                              className={classes.emailChip}
                            />
                          ))}
                        </div>
                      ),
                    }}
                  />
                </div>
              )}
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  Subject
                </Typography>
                <TextField
                  label="Add Subject"
                  fullWidth
                  disabled={
                    !!state.isPreviousViewCreditMemo ||
                    state.isCreditmemoSending
                  }
                  variant="outlined"
                  size="small"
                  name="emailSubject"
                  value={state.emailSubject}
                  className="mt-3"
                  onChange={handleFieldChange}
                  error={!!state.errors.emailSubject?.trim()}
                  helperText={state.errors.emailSubject}
                />
              </div>
              <div
                className="d-flex f-align-center mb-4"
                style={{ marginLeft: 130 }}
              >
                {!!state.isPreviousViewCreditMemo && (
                  <>
                    <AttachFileIcon fontSize="small" className="mr-1" />
                    <Typography
                      variant="body2"
                      component="span"
                      className="text-bold"
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "black",
                      }}
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          pdfViewerDialog: true,
                        }))
                      }
                    >
                      Credit Memo Preview
                    </Typography>
                  </>
                )}
              </div>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  Email Body
                </Typography>
                <TextField
                  label="Email Body"
                  fullWidth
                  variant="outlined"
                  disabled={
                    !!state.isPreviousViewCreditMemo ||
                    state.isCreditmemoSending
                  }
                  minRows={10}
                  maxRows={10}
                  multiline
                  size="small"
                  name="emailBody"
                  value={state.emailBody}
                  className="mt-3"
                  onChange={handleFieldChange}
                  error={!!state.errors.emailBody?.trim()}
                  helperText={state.errors.emailBody}
                />
              </div>
              {state.isPreviousViewCreditMemo &&
                state.attachmentFiles &&
                state.attachmentFiles.length !== 0 && (
                  <div className="d-flex">
                    <Typography
                      variant="h6"
                      className="text-bold mr-8 mt-3"
                      style={{ width: "8rem" }}
                    >
                      Attachments
                    </Typography>
                    <List style={{ padding: 0, width: "90%" }} className="mt-3">
                      {state.attachmentFiles.map((attachment, index) => (
                        <ListItem
                          key={index}
                          className="mb-2"
                          style={{
                            padding: 3,
                            backgroundColor: "#f5f5f5",
                            borderRadius: "4px",
                            border: "1px solid transparent",
                          }}
                        >
                          <Link
                            href={attachment?.pdf_url}
                            download={attachment?.fileName}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mr-2"
                          >
                            <Typography
                              style={{
                                color: "#15c",
                                fontWeight: 400,
                                fontSize: 14,
                                fontStretch: "expanded",
                                textDecoration: "underline",
                              }}
                            >
                              {attachment?.fileName}
                            </Typography>
                          </Link>

                          <Typography
                            style={{
                              color: "black",
                              fontSize: 14,
                            }}
                          >
                            {`(${getReadableFileSizeString(attachment?.size)})`}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                )}
              {!state.isPreviousViewCreditMemo && (
                <UploadFiles
                  uploadedAttachment={uploadedAttachment}
                  isLoading={state.isCreditmemoSending}
                  sentHistory={state.sentEmailHistory}
                  attach="credit-memo"
                />
              )}
            </div>
          }
          onConfirm={() => {
            if (!!state.isPreviousViewCreditMemo) {
              return setState((prevState) => ({
                ...prevState,
                showSendCreditePopup: false,
                isPreviousViewCreditMemo: null,
              }));
            }
            handleSubmit(
              !state.selectedCreditMemo.length
                ? entry.id
                : state.selectedCreditMemo,
              state.temporaryEmail,
              state.permanentEmail,
              state.emailSubject,
              state.emailBody,
              state.uploadedAttachment,
              state.selectedCreditMemo.length > 0 &&
                state.selectedCreditMemo.includes(selectedCreditMemo?.id)
                ? selectedCreditMemo?.id
                : !state.selectedCreditMemo.length
            );
          }}
          hasClose={!!state.isPreviousViewCreditMemo ? false : true}
          onCancel={() => {
            isConfirmDisabled = false;
            const selectedCompanies =
              state.selectedCreditMemo.length > 0
                ? [
                    ...new Set(
                      rows
                        .filter((obj) =>
                          state.selectedCreditMemo.includes(obj.id)
                        )
                        .map((ele) => ele.pdx_company)
                    ),
                  ].join(", ")
                : null;

            const displayCompanyName = () => {
              if (
                selectedCompanies &&
                selectedCompanies.split(",").length > 1
              ) {
                return "Parts Distribution Xpress";
              } else if (state.selectedCreditMemo.length === 0) {
                return selectedCreditMemo?.pdx_company;
              } else {
                return selectedCompanies;
              }
            };
            setState((prevState) => ({
              ...prevState,
              showSendCreditePopup: false,
              isPreviousViewCreditMemo: null,
              email: defaultState.email,
              temporaryEmail: defaultState.temporaryEmail,
              permanentEmail: defaultState.permanentEmail,
              emailSubject: `${
                !state.selectedCreditMemo.length
                  ? `Credit Memo ${selectedCreditMemo?.credit_no || ""}`
                  : state.selectedCreditMemo.length === 1
                  ? `Credit Memo ${
                      rows.filter((obj) =>
                        state.selectedCreditMemo.includes(obj.id)
                      )[0]?.credit_no
                    }`
                  : "Credit Memos"
              } from ${
                selectedCompanies || selectedCreditMemo?.pdx_company || "PDX"
              }`,
              emailBody: `Dear ${
                !state.selectedCreditMemo.length
                  ? selectedCreditMemo?.customer_branch?.customer?.name || ""
                  : state.selectedCreditMemo.length === 1
                  ? rows.filter((obj) =>
                      state.selectedCreditMemo.includes(obj.id)
                    )[0]?.customer_branch?.customer?.name
                  : "Customers"
              },\n\nYour ${
                state.selectedCreditMemo.length > 1
                  ? "credit memos are"
                  : "credit memo is"
              } attached. Please review the details of the credit applied to your account. If you have any questions or need further clarification, feel free to reach out to us. Thank you for your business - we appreciate it very much.\n\nSincerely,\n${displayCompanyName()}`,
              errors: {
                email: " ",
                from: " ",
                emailSubject: " ",
                emailBody: " ",
              },
            }));
          }}
          isConfirmDisabled={
            state.isCreditmemoSending ||
            emailLoading ||
            isConfirmDisabled ||
            !!state.email ||
            (state.temporaryEmail.length === 0 &&
              state.permanentEmail.length === 0) ||
            (validate() && validate() !== " ") ||
            (state.selectedCreditMemo.length &&
              state.selectedCreditMemo.length !== 1 &&
              state.temporaryEmail.length === 0)
          }
          showPositiveActionLabel={
            !!state.isPreviousViewCreditMemo ? false : true
          }
          positiveActionLabel={
            <>
              {`${state.sentEmailHistory ? "RESEND" : "SEND"} CREDIT MEMO`}
              {state.isCreditmemoSending && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn color-white"
                />
              )}
            </>
          }
          negativeActionLabel={
            !!state.isPreviousViewCreditMemo ? "Close" : "Cancel"
          }
        />
      )}
      {state.isCreditMemoDeleteDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-danger",
            paper: classes.deletePaperSizeDialog,
          }}
          open={state.isCreditMemoDeleteDialog}
          contentText={`Are you sure you want to delete credit memo(s)?`}
          onConfirm={() => handleDeleteCreditMemo(state.selectedCreditMemo)}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              isCreditMemoDeleteDialog: false,
            }))
          }
          isConfirmDisabled={state.isCreditMemoDeleteLoading}
          positiveActionLabel={
            <>
              Delete
              {state.isCreditMemoDeleteLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
      {state.isEmailSent && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSizeDialog,
          }}
          open={state.isEmailSent}
          contentText={`Are you sure you want to mark this credit memo as sent?`}
          onConfirm={async () => {
            setState((prevState) => ({
              ...prevState,
              isEmailSent: false,
            }));

            await handleMarkCreditMemoSent(
              !state.selectedCreditMemo.length
                ? selectedCreditMemo?.id
                : state.selectedCreditMemo,
              state.selectedCreditMemo.length > 0 &&
                state.selectedCreditMemo.includes(selectedCreditMemo?.id)
                ? selectedCreditMemo?.id
                : !state.selectedCreditMemo.length
            );

            setState((prevState) => ({
              ...prevState,
              selectedCreditMemo: defaultState.selectedCreditMemo,
            }));
          }}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              isEmailSent: false,
            }))
          }
          isConfirmDisabled={isMarkAsSentLoading}
          positiveActionLabel="Send"
          negativeActionLabel="Cancel"
        />
      )}
      {state.showAddPermanentEmailDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSizeDialog,
          }}
          open={!!state.showAddPermanentEmailDialog}
          contentText={
            <>
              <Typography variant="body2" className="mt-2 text-bold">
                {`Would you like to add this email address `}
                <span style={{ color: "Black" }}>{state.email}</span>
                {` to customer emails?`}
              </Typography>
            </>
          }
          onConfirm={() =>
            addPermananentEmaiil(entry?.customer_branch, state.permanentEmail)
          }
          onCancel={() => {
            setState((prevState) => {
              return {
                ...prevState,
                showAddPermanentEmailDialog: false,
                permanentEmail: emailAddresses,
              };
            });
          }}
          isConfirmDisabled={state.isPermEmailLoading}
          positiveActionLabel={
            <>
              Add To Customer Email
              {state.isPermEmailLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={<>CANCEL</>}
        />
      )}
      {state.sentEmailHistory && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSize,
          }}
          open={state.sentEmailHistory}
          contentText={
            <>
              <Typography
                variant="h6"
                color="primary"
                className="mb-4 text-bold"
              >
                Credit Memo Email History
              </Typography>
              <Grid
                columns={columnConfig}
                rows={state.emailHistory}
                actionBarConfig={null}
                isLoading={state.isCreditEmailHistoryLoading}
                hasSelection={false}
                hasPagination={false}
              />
            </>
          }
          hasClose={false}
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              sentEmailHistory: false,
              temporaryEmail: defaultState.temporaryEmail,
            }));
          }}
          isConfirmDisabled={isMarkAsSentLoading}
          positiveActionLabel="Close"
        />
      )}
      {state.pdfViewerDialog && (
        <Dialog
          onClose={handleViewerClose}
          aria-labelledby="customized-dialog-title"
          open={state.pdfViewerDialog}
          classes={{ paper: classes.paperIframe }}
        >
          <DialogContent dividers>
            <iframe
              type="application/pdf"
              src={state.isPreviousViewCreditMemo}
              title="Credit Memo PDF"
              width="1150px"
              height="700px"
              style={{ border: "none" }}
            ></iframe>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleViewerClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {state.open && (
        <CreditMemo
          open={state.open}
          heading={entry.status !== "draft" ? "View" : "Update"}
          onClose={handleDialogClose}
          isRowBeingEditing={true}
          creditMemoId={entry.id}
          fetchNewdata={fetchNewdata}
        />
      )}
    </div>
  );
};

export default CreditMemoSummary;
